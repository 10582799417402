define("ember-models-table/templates/components/models-table/grouped-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Pd40Da2k",
    "block": "{\"symbols\":[\"cell\",\"&default\"],\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,2,[[30,[36,3],null,[[\"groupedHeader\",\"shouldAddExtraColumn\"],[[32,0,[\"groupedHeader\"]],[32,0,[\"shouldAddExtraColumn\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"shouldAddExtraColumn\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"groupedHeader\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"th\"],[15,\"colspan\",[32,1,[\"colspan\"]]],[15,\"rowspan\",[32,1,[\"rowspan\"]]],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\",\"hash\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/grouped-header.hbs"
  });
});