define("ember-ref-bucket/helpers/ref-to", ["exports", "ember-ref-bucket/utils/ref"], function (_exports, _ref) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class RefToHelper extends Ember.Helper {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_watcher", null);
    }
    compute([name], {
      bucket,
      tracked
    }) {
      const bucketRef = bucket || Ember.getOwner(this);
      if (this._name !== name) {
        if (this._watcher) {
          Ember._unregisterDestructor(this, this._watcher);
        }
        this._watcher = (0, _ref.watchFor)(name, bucketRef, () => {
          this.recompute();
        });
        Ember._registerDestructor(this, this._watcher);
        this._name = name;
      }
      if (tracked) {
        return (0, _ref.bucketFor)(bucketRef).getTracked(name);
      } else {
        return (0, _ref.bucketFor)(bucketRef).get(name);
      }
    }
  }
  _exports.default = RefToHelper;
});