define("sgb-object-graphs/components/sgb-ajax-singlestat-line-chart", ["exports", "sgb-object-graphs/components/sgb-ajax-base-chart"], function (_exports, _sgbAjaxBaseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SgbAjaxSinglestatLineChart = (_dec = Ember.computed('top_group_id', 'metric_alias', 'start_hours_ago', 'end_hours_ago'), _dec2 = Ember.computed('metric'), _dec3 = Ember.computed('metric_alias'), _dec4 = Ember.computed('metric'), _dec5 = Ember.computed('openTsdbData'), classic(_class = (_class2 = class SgbAjaxSinglestatLineChart extends _sgbAjaxBaseChart.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "shouldUpdateGraphData", true);

      _defineProperty(this, "onlyShowDataLines", true);
    }

    get chartInfo() {
      let top_group_id = this.top_group_id;
      let metric_alias = this.metric_alias;
      let start_hours_ago = this.start_hours_ago;
      let end_hours_ago = this.end_hours_ago; // TODO: define this info also once & convert for both ember & node express?

      return {
        componentName: 'sgb-singlestat-line-chart',
        url: `groups/${top_group_id}/${start_hours_ago}-${end_hours_ago}/single-stat-chart/${metric_alias}`,
        // only set refreshUrl when end_hours_ago not defined
        refreshUrl: !end_hours_ago ? `groups/${top_group_id}/1-0/single-stat-chart/${metric_alias}` : null
      };
    }

    get title() {
      //  TODO: consolidate duplication between singlestats
      let metric = this.metric;

      if (!metric) {
        return 'Unknown title';
      }

      return `${metric.label}`;
    }

    get metric() {
      let metric_alias = this.metric_alias;
      return SingleStatMetrics[metric_alias];
    }

    get metrics() {
      return [this.metric];
    }

    get overlayText() {
      return SingleStatMetrics.getLastValueFromTsdbQueryResult(this.openTsdbData);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "chartInfo", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "chartInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "title", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "title"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "metric", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "metric"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "metrics", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "metrics"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "overlayText", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "overlayText"), _class2.prototype)), _class2)) || _class);
  _exports.default = SgbAjaxSinglestatLineChart;
});