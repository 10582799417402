define("sgb-object-graphs/templates/components/sgb-group-watthours-charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.emberenv.featureFlags.showObjectChartsGroupWatthoursBarChart}}
    {{!-- TODO: this is a helper from outside the addon :( --}}
    <SgbGroupWatthoursBarChart
      @aggregateLevel={{this.aggregateLevel}}
      @config={{this.chartjsOptions}}
      @fontColor={{this.fontColor}}
      @heightVh={{this.heightVh}}
      @metrics={{this.metrics}}
      @nrOfXAxisLabels={{this.nrOfXAxisLabels}}
      @onlyShowDataLines={{this.onlyShowDataLines}}
      @openTsdbData={{this.openTsdbData}}
      @overlayText={{this.overlayText}}
      @statusMessage={{this.statusMessage}}
      @temperatureUnit={{this.temperatureUni}}
      @timeFormat={{this.timeFormat}}
      @title={{this.title}}
      @useMinimalSpaceForChart={{this.useMinimalSpaceForChart}}
    />
  {{/if}}
  
  <SgbGroupWatthoursPieChart
    @aggregateLevel={{this.aggregateLevel}}
    @config={{this.chartjsOptions}}
    @fontColor={{this.fontColor}}
    @heightVh={{this.heightVh}}
    @metrics={{this.metrics}}
    @nrOfXAxisLabels={{this.nrOfXAxisLabels}}
    @onlyShowDataLines={{this.onlyShowDataLines}}
    @openTsdbData={{this.openTsdbData}}
    @overlayText={{this.overlayText}}
    @statusMessage={{this.statusMessage}}
    @temperatureUnit={{this.temperatureUni}}
    @timeFormat={{this.timeFormat}}
    @title={{this.title}}
    @useMinimalSpaceForChart={{this.useMinimalSpaceForChart}}
  />
  
  */
  {
    "id": "dP2rRMRK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"emberenv\",\"featureFlags\",\"showObjectChartsGroupWatthoursBarChart\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"sgb-group-watthours-bar-chart\",[],[[\"@aggregateLevel\",\"@config\",\"@fontColor\",\"@heightVh\",\"@metrics\",\"@nrOfXAxisLabels\",\"@onlyShowDataLines\",\"@openTsdbData\",\"@overlayText\",\"@statusMessage\",\"@temperatureUnit\",\"@timeFormat\",\"@title\",\"@useMinimalSpaceForChart\"],[[32,0,[\"aggregateLevel\"]],[32,0,[\"chartjsOptions\"]],[32,0,[\"fontColor\"]],[32,0,[\"heightVh\"]],[32,0,[\"metrics\"]],[32,0,[\"nrOfXAxisLabels\"]],[32,0,[\"onlyShowDataLines\"]],[32,0,[\"openTsdbData\"]],[32,0,[\"overlayText\"]],[32,0,[\"statusMessage\"]],[32,0,[\"temperatureUni\"]],[32,0,[\"timeFormat\"]],[32,0,[\"title\"]],[32,0,[\"useMinimalSpaceForChart\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"sgb-group-watthours-pie-chart\",[],[[\"@aggregateLevel\",\"@config\",\"@fontColor\",\"@heightVh\",\"@metrics\",\"@nrOfXAxisLabels\",\"@onlyShowDataLines\",\"@openTsdbData\",\"@overlayText\",\"@statusMessage\",\"@temperatureUnit\",\"@timeFormat\",\"@title\",\"@useMinimalSpaceForChart\"],[[32,0,[\"aggregateLevel\"]],[32,0,[\"chartjsOptions\"]],[32,0,[\"fontColor\"]],[32,0,[\"heightVh\"]],[32,0,[\"metrics\"]],[32,0,[\"nrOfXAxisLabels\"]],[32,0,[\"onlyShowDataLines\"]],[32,0,[\"openTsdbData\"]],[32,0,[\"overlayText\"]],[32,0,[\"statusMessage\"]],[32,0,[\"temperatureUni\"]],[32,0,[\"timeFormat\"]],[32,0,[\"title\"]],[32,0,[\"useMinimalSpaceForChart\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "sgb-object-graphs/templates/components/sgb-group-watthours-charts.hbs"
  });

  _exports.default = _default;
});