define("ember-models-table/components/models-table/themes/ember-paper/page-size-select", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/page-size-select", "ember-models-table/templates/components/models-table/themes/ember-paper/page-size-select"], function (_exports, _component, _pageSizeSelect, _pageSizeSelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   * @class EpModelsTablePageSizeSelect
   * @namespace Components
   * @extends Components.ModelsTablePageSizeSelect
   */
  let EpModelsTablePageSizeSelectComponent = _exports.default = (_dec = (0, _component.layout)(_pageSizeSelect2.default), _dec(_class = class EpModelsTablePageSizeSelectComponent extends _pageSizeSelect.default {}) || _class);
});