define("sgb-object-graphs/components/sgb-ember-chart", ["exports", "@ember-decorators/component", "@ember-decorators/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /* global Chart */
  const classic = __EMBER_CLASSIC_DECORATOR;
  let SgbEmberChart = (_dec = (0, _component.tagName)('canvas'), _dec2 = (0, _component.attributeBindings)('width', 'height'), _dec3 = Ember.computed.or('config', 'chartjsOptions'), _dec4 = Ember.computed('config2.options', 'onlyShowDataLines', 'title', 'useMinimalSpaceForChart'), _dec5 = Ember.computed.alias('config2.data'), classic(_class = _dec(_class = _dec2(_class = (_class2 = class SgbEmberChart extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "config2", _descriptor, this);

      _initializerDefineProperty(this, "data", _descriptor2, this);
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      this.fixContainerHeight(this.element);
      this.createChart();
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      let chart = this.chart;

      if (chart) {
        chart.destroy();
      }
    }

    didUpdateAttrs() {
      super.didUpdateAttrs(...arguments);
      this.updateChart();
    }
    /*
      @observes('data.datasets.0.data.length')
      refresh_updateChart() {
      }
    */


    inlined_updateChart() {
      // console.log('inlined_updateChart', this.data);
      let chart = this.chart;
      let data = this.data;
      let options = this.options;
      let animate = this.animate;

      if (chart) {
        let countDatasets = chart.data.datasets.length;
        let countDatasetsMatch = 0;

        for (const od of chart.data.datasets) {
          for (const nd of this.data.datasets) {
            if (od.label == nd.label) {
              countDatasetsMatch++;
            }
          }
        }

        if (!countDatasetsMatch && chart.data.labels.length) {
          for (const od of chart.data.labels) {
            for (const nd of this.data.labels) {
              if (od == nd) {
                countDatasetsMatch++;
              }
            }
          }
        } // console.log('Dataset count...', countDatasets, countDatasetsMatch, this.data, this.chart.data);


        if (countDatasets == countDatasetsMatch) {
          for (const od of chart.data.datasets) {
            for (const nd of this.data.datasets) {
              if (od.label == nd.label) {
                od.data = nd.data;
              }
            }
          }

          chart.config.data.labels = data.labels;
        } else {
          chart.config.data = data;
        }

        chart.config.options = options;

        if (animate) {
          chart.update();
        } else {
          chart.update(0);
        }
      }
    }

    inlined_updateChart2() {
      const chart = this.chart; // chart.data.labels.push(label);

      chart.data.datasets.forEach(dataset => {
        const data = dataset.data;
        const last = data[data.length - 1];
        const x = new Date(last.x.getTime() + 1000 * 3600 * 2);
        const y = (parseFloat(last.y) + (Math.random() > 0.5 ? 1 : -1) * parseFloat(Math.random().toFixed(2))).toFixed(2);
        dataset.data.push({
          x,
          y
        });
      });
      chart.update();
    }
    /* inline end */


    didRender() {
      super.didRender(...arguments);
      let heightVh = this.heightVh;

      if (heightVh) {
        if (!this.element.parentNode.style.height) {
          this.element.parentNode.setAttribute('style', `height: ${heightVh}`);
        }
      }
    }

    fixContainerHeight(element) {
      // height fix
      if (!this.onlyShowDataLines) {
        let {
          clientWidth
        } = element.parentNode;

        if (clientWidth < 480) {
          let approximateHeight = this.calculateApproximateHeight(clientWidth);

          if (approximateHeight) {
            element.parentNode.setAttribute('style', `height: ${approximateHeight}px`);
            this.set('useMinimalSpaceForChart', true);
          }
        }
      }
    }

    calculateApproximateHeight(width) {
      // at 400px width, two 16 char labels can be rendered in same line
      let longThreshold = Math.ceil(width * 16 / 400);
      let datasets = this.get('data.datasets');

      if (datasets) {
        let totalLabels = datasets.length;
        let longLines = datasets.filter(ds => {
          return ds.label && ds.label.length > longThreshold;
        }).length;
        let lines = Math.ceil((totalLabels - longLines) / 2) + longLines;
        let contentHeight = this.type == 'pie' ? parseInt(width / 2) : 100; // top legend + chart + bottom axis

        return lines * 22 + contentHeight + 60;
      }
    }

    setNestedProperty(target, propNames, prop, val) {
      propNames.forEach(propName => {
        if (target[propName] == null) {
          target[propName] = {};
        }

        target = target[propName];
      });
      target[prop] = val;
    }

    get options() {
      let options = this.get('config2.options');

      if (!options) {
        return options;
      } // TODO: options = {}; would cause issues further down, but should be like that
      // fontColor is set from the optionsprocessor now


      let useMinimalSpaceForChart = this.useMinimalSpaceForChart;

      if (useMinimalSpaceForChart) {
        options.maintainAspectRatio = false;
      }

      let onlyShowDataLines = this.onlyShowDataLines;

      if (onlyShowDataLines != null) {
        this.setNestedProperty(options, ['legend'], 'display', false); // TODO: this only applies to line charts?

        [options.scales.x, options.scales.y].forEach(axis => {
          if (axis) {
            axis.display = !onlyShowDataLines;
          }

          ;
        });
      }
      /*    
          if (!options.plugins) {
            options.plugins = {};
          }
          options.plugins.zoom = {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: 'xy',
            },
            pan: {
              enabled: true,
              mode: 'xy',
            }
          };
      */


      return options;
    }

    updateChart() {
      // overwrite from super
      if (this.hasData() && !this.fontColorChanged() && this.willChartJSUpdateFromOptions()) {
        console.log('updateChart: inline');
        this.inlined_updateChart(...arguments);
      } else {
        console.log('updateChart: recreate');
        this.recreateChart();
      }
    }

    willChartJSUpdateFromOptions() {
      // hook for child implementations
      return true;
    }

    hasData() {
      return this.data != null;
    }

    fontColorChanged() {
      let origColor = this.get('chart.legend.options.labels.fontColor');
      let newColor = this.get('options.legend.labels.fontColor');
      return origColor != newColor;
    }

    createChart() {
      Chart.defaults.elements.line.fill = true;
      let chart = new Chart(this.element, {
        type: this.type,
        data: JSON.parse(JSON.stringify(this.data)),
        options: this.options,
        defaults: {
          color: '#00ff00'
        }
      });
      console.log('createChart', {
        type: this.type,
        data: this.data,
        options: this.options
      }, chart, this);
      this.set('chart', chart);
    }

    destroyChart() {
      if (this.chart) {
        this.chart.destroy();
      }
    }

    recreateChart() {
      this.destroyChart();
      this.createChart();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "config2", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "options", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "options"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "data", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class) || _class);
  _exports.default = SgbEmberChart;
});