define("ember-cli-head/components/head-layout", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#in-element this.headElement insertBefore=null}}
    {{! template-lint-disable no-forbidden-elements }}
    <meta name="ember-cli-head-start" content=""><HeadContent /><meta name="ember-cli-head-end" content="">
  {{/in-element}}
  
  */
  {
    "id": "KWzSJwnI",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"headElement\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"meta\"],[14,3,\"ember-cli-head-start\"],[14,\"content\",\"\"],[12],[13],[8,\"head-content\",[],[[],[]],null],[10,\"meta\"],[14,3,\"ember-cli-head-end\"],[14,\"content\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-in-el-null\",\"in-element\"]}",
    "moduleName": "ember-cli-head/components/head-layout.hbs"
  });
  let HeadLayout = _exports.default = (_dec = Ember.inject.service('-document'), (_class = class HeadLayout extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "document", _descriptor, this);
      /**
       * If true, this will tear down any existing head on init of this component.
       * This is useful if there is a head built with fastboot - it will then be torn down when this is initialized in the browser.
       * If you do not want this behavior, you can set this to false.
       * @public
       */
      _defineProperty(this, "shouldTearDownOnInit", true);
      /**
       * The element to render into. Defaults to <head> in `init`, overridable for our own tests only.
       * @private
       */
      _defineProperty(this, "headElement", this.args.headElement || this.document.head);
      if (this.shouldTearDownOnInit) {
        this._tearDownHead();
      }
    }

    /**
     * Tear down any previous head, if there was one.
     * @private
     */
    _tearDownHead() {
      if (this._isFastboot()) {
        return;
      }

      // clear fast booted head (if any)
      let document = this.document;
      let startMeta = document.querySelector('meta[name="ember-cli-head-start"]');
      let endMeta = document.querySelector('meta[name="ember-cli-head-end"]');
      if (startMeta && endMeta) {
        let el = startMeta.nextSibling;
        while (el && el !== endMeta) {
          document.head.removeChild(el);
          el = startMeta.nextSibling;
        }
        document.head.removeChild(startMeta);
        document.head.removeChild(endMeta);
      }
    }
    _isFastboot() {
      return typeof FastBoot !== 'undefined';
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "document", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HeadLayout);
});