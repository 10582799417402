define("ember-modal-dialog/components/tether-dialog", ["exports", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/tether-dialog"], function (_exports, _basicDialog, _tetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _basicDialog.default.extend({
    layout: _tetherDialog.default,
    init() {
      this._super(...arguments);
      this._ensureAttachments();
    },
    targetAttachmentClass: Ember.computed('targetAttachment', function () {
      let targetAttachment = this.get('targetAttachment') || '';
      return `ember-modal-dialog-target-attachment-${Ember.String.dasherize(targetAttachment)}`;
    }),
    targetAttachment: null,
    attachment: null,
    didReceiveAttrs() {
      this._super(...arguments);
      this._ensureAttachments();
    },
    tetherTarget: null,
    // element, css selector, view instance, 'viewport', or 'scroll-handle'
    tetherClassPrefix: Ember.computed({
      get() {
        return 'ember-tether';
      },
      set(key, val) {
        if (val) {
          return val;
        }
        return 'ember-tether';
      }
    }),
    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in
    _ensureAttachments() {
      if (!this.get('attachment')) {
        this.set('attachment', 'middle center');
      }
      if (!this.get('targetAttachment')) {
        this.set('targetAttachment', 'middle center');
      }
    }
  });
});