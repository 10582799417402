define("ember-models-table/templates/components/models-table/themes/ember-paper/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mxts+hRT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"globalSearch\"],[12],[2,\"\\n\"],[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"paper-input\",[[16,0,[30,[36,0],[[32,0,[\"themeInstance\",\"input\"]],\" filterString\"],null]]],[[\"@label\",\"@placeholder\",\"@value\",\"@onChange\"],[[32,0,[\"themeInstance\",\"searchLabelMsg\"]],[32,0,[\"themeInstance\",\"searchPlaceholderMsg\"]],[32,0,[\"value\"]],[30,[36,1],[[30,[36,3],[[32,0,[\"value\"]]],null]],null]]],null],[2,\"\\n\"],[6,[37,4],[[32,0,[\"globalFilterUsed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"paper-button\",[[16,0,[30,[36,0],[\"clearFilterIcon \",[32,0,[\"themeInstance\",\"clearFilterIcon\"]]],null]]],[[\"@onClick\",\"@iconButton\"],[[30,[36,1],[[32,0,[\"clearGlobalFilter\"]]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[\"close\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\",\"paper-icon\",\"mut\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/global-filter.hbs"
  });
});