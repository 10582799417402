define("sgb-object-graphs/components/sgb-ajax-object-charts", ["exports", "sgb-object-graphs/components/sgb-ajax-base-chart"], function (_exports, _sgbAjaxBaseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SgbAjaxObjectCharts = (_dec = Ember.computed('end_hours_ago', 'granularity', 'group_type', 'objectPayload.hierarchy.type', 'start_hours_ago'), _dec2 = Ember.computed('objectPayload', 'objectPayload.id'), _dec3 = Ember.computed('objectPayload.device_type.id'), classic(_class = (_class2 = class SgbAjaxObjectCharts extends _sgbAjaxBaseChart.default {
    get chartInfo() {
      // TODO: eventually optimize this through helper/sgb-charts-common, good for now though
      let tp = this.get('objectPayload.hierarchy.type');
      let op = this.objectPayload;
      let start_hours_ago = this.start_hours_ago;
      let end_hours_ago = this.end_hours_ago;

      if (tp == 'device') {
        const chartInfo = {
          componentName: 'sgb-device-line-chart',
          url: `/devices/${op.id}/${start_hours_ago}-${end_hours_ago}/device-chart-data`,
          // only set refreshUrl when end_hours_ago not defined
          refreshUrl: !end_hours_ago ? `/devices/${op.id}/1-0/device-chart-data` : null
        };
        return chartInfo;
      } else if (tp == 'group') {
        const granularity = this.granularity ?? 'device';
        const group_type = this.group_type ?? 'group';
        const chartInfo = {
          componentName: 'sgb-group-watthours-charts',
          url: `/groups/${op.id}/${start_hours_ago}-${end_hours_ago}/watthours-chart-data/${granularity}/${group_type}`,
          // only set refreshUrl when end_hours_ago not defined
          refreshUrl: !end_hours_ago ? `/groups/${op.id}/1-0/watthours-chart-data/${granularity}/${group_type}` : null
        };
        return chartInfo;
      }

      return null; // TODO: report error
    }

    get shouldUpdateGraphData() {
      return this.get('objectPayload.id') != null;
    }

    get metrics() {
      let device_type_id = this.get('objectPayload.device_type.id');

      if (device_type_id != undefined) {
        return DeviceTypesToMetrics.metrics_for_device_type(device_type_id);
      } else {
        return [];
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "chartInfo", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "chartInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldUpdateGraphData", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldUpdateGraphData"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "metrics", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "metrics"), _class2.prototype)), _class2)) || _class);
  _exports.default = SgbAjaxObjectCharts;
});