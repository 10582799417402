define("sgb-object-graphs/components/sgb-ember-pie-chart", ["exports", "sgb-object-graphs/components/sgb-ember-chart"], function (_exports, _sgbEmberChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let SgbEmberPieChart = classic(_class = class SgbEmberPieChart extends _sgbEmberChart.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "type", 'pie');
    }

  }) || _class;

  _exports.default = SgbEmberPieChart;
});