define("ember-models-table/components/models-table/row-expand", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-expand"], function (_exports, _component, _rowExpand) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
   * Extra row with additional information for original row. Component used within [models-table/table-body](Components.ModelsTableTableBody.html).
   *
   * Row expand is a table-row with a single cell. This cell has a `colspan` equal to the shown columns count. You should provide an [ModelsTable.expandedRowComponent](Components.ModelsTable.html#property_expandedRowComponent). It will be rendered inside ModelsTableRowExpand.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each MT.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} />
   *         {{#if (exists-in MT.expandedItems record)}}
   *           <Body.RowExpand @record={{record}} @index={{index}} />
   *         {{/if}}
   *       {{/each}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @class ModelsTableRowExpand
   * @namespace Components
   * @extends Ember.Component
   */
  let RowExpandComponent = _exports.default = (_dec = (0, _component.layout)(_rowExpand.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = (0, _component.classNames)('expand-row'), _dec4 = Ember.computed('index'), _dec5 = (0, _component.className)('selected-expand'), _dec6 = Ember.computed('selectedItems.[]', 'record'), _dec7 = Ember.computed('additionalColspan', 'visibleProcessedColumns.length'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class RowExpandComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * Extra colspan used in the internal `td`. Useful in cases with block scope usage when some extra columns are in the table (not only `columns`)
       *
       * @property additionalColspan
       * @type number
       * @default 0
       */
      _defineProperty(this, "additionalColspan", 0);
      /**
       * Row's index
       *
       * @property index
       * @type number
       * @default null
       */
      _defineProperty(this, "index", null);
      /**
       * One of the [data](Components.ModelsTable.html#property_data)
       *
       * @property record
       * @type object
       * @default null
       */
      _defineProperty(this, "record", null);
      /**
       * Bound from [ModelsTable.expandedRowComponent](Components.ModelsTable.html#property_expandedRowComponent)
       *
       * @property expandedRowComponent
       * @default null
       */
      _defineProperty(this, "expandedRowComponent", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Closure action [ModelsTable.clickOnRow](Components.ModelsTable.html#event_clickOnRow)
       *
       * @event clickOnRow
       */
      _defineProperty(this, "clickOnRow", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
    /**
     * @property tagName
     * @type string
     * @default 'tr'
     */

    /**
     * @property indexedClass
     * @type string
     * @protected
     */
    get indexedClass() {
      return `expand-${this.index}`;
    }

    /**
     * @property isSelected
     * @type boolean
     * @protected
     */
    get isSelected() {
      return Ember.isArray(this.selectedItems) && this.selectedItems.includes(this.record);
    }

    /**
     * @protected
     * @property cellColspan
     * @default 0
     * @type number
     */
    get cellColspan() {
      return Number(this.additionalColspan) + Number(this.visibleProcessedColumns.length);
    }
    click() {
      this.clickOnRow(this.index, this.record);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "indexedClass", [_component.className, _dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "indexedClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isSelected", [_dec5, _dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isSelected"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cellColspan", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "cellColspan"), _class2.prototype)), _class2)) || _class) || _class) || _class);
});