define("ember-models-table/components/models-table/themes/plain-html/columns-dropdown", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/columns-dropdown", "ember-models-table/templates/components/models-table/themes/plain-html/columns-dropdown"], function (_exports, _component, _columnsDropdown, _columnsDropdown2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   * @class PlainHtmlModelsTableColumnsDropdown
   * @namespace Components
   * @extends Components.ModelsTableColumnsDropdown
   */
  let PlainHtmlModelsTableColumnsDropdownComponent = _exports.default = (_dec = (0, _component.layout)(_columnsDropdown2.default), _dec(_class = class PlainHtmlModelsTableColumnsDropdownComponent extends _columnsDropdown.default {}) || _class);
});