define("ember-models-table/templates/components/models-table/themes/ember-paper/row-select-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h4RZLmud",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"paper-checkbox\",[],[[\"@value\",\"@bubbles\",\"@onChange\"],[[32,0,[\"isSelected\"]],false,[30,[36,0],[[32,0,[\"doClickOnRow\"]],[32,0,[\"index\"]],[32,0,[\"record\"]]],null]]],null],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-checkbox.hbs"
  });
});