define("sgb-object-graphs/components/sgb-ember-line-chart", ["exports", "sgb-object-graphs/components/sgb-ember-chart"], function (_exports, _sgbEmberChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;

  let SgbEmberLineChart = classic(_class = class SgbEmberLineChart extends _sgbEmberChart.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "type", 'line');
    }

    willChartJSUpdateFromOptions() {
      // problem: options is only used during initial rendering
      // see after theme change (without this methodD)
      // [Chart.instances[0].config.options.legend.labels.fontColor, Chart.instances[0].legend.options.labels.fontColor]
      //  ["blue", "green"]
      // TEST: return !this.scalesChanged();
      return true;
    }

    scalesChanged() {
      const chart = this.chart;
      const scales = this.get('options.scales');
      let newScales = 0;

      if (scales) {
        for (const [key, value] of Object.entries(scales)) {
          if (chart[value.id] == undefined) {
            newScales++;
          }
        }
      }

      return newScales > 0;
    }

  }) || _class;

  _exports.default = SgbEmberLineChart;
});