define("ember-models-table/themes/bootstrap4", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
   * @class Bootstrap4Theme
   * @namespace Themes
   * @extends Themes.Bootstrap3Theme
   */
  let Bootstrap4Theme = _exports.default = (_dec = Ember.computed('buttonDefault'), (_class = class Bootstrap4Theme extends _bootstrap.default {
    constructor(...args) {
      super(...args);
      /**
       * @property columnsDropdownComponent
       * @type string
       * @default 'models-table/themes/bootstrap4/columns-dropdown'
       */
      /**
       * @property dataGroupBySelectComponent
       * @type string
       * @default 'models-table/themes/bootstrap4/data-group-by-select'
       */
      /**
       * @property globalFilterComponent
       * @type string
       * @default 'models-table/themes/bootstrap4/global-filter'
       */
      /**
       * @property rowFilteringCellComponent
       * @type string
       * @default 'models-table/themes/bootstrap4/row-filtering-cell'
       */
      /**
       * @property componentsPath
       * @type string
       * @default 'models-table/themes/bootstrap4/'
       */
      _defineProperty(this, "componentsPath", 'models-table/themes/bootstrap4/');
      /**
       * @property table
       * @type string
       * @default 'table table-striped table-bordered table-condensed table-sm'
       */
      _defineProperty(this, "table", 'table table-striped table-bordered table-condensed table-sm');
      /**
       * @property globalFilterWrapper
       * @type string
       * @default 'float-left'
       */
      _defineProperty(this, "globalFilterWrapper", 'float-left');
      /**
       * @property columnsDropdown
       * @type string
       * @default 'dropdown-menu dropdown-menu-right'
       */
      _defineProperty(this, "columnsDropdown", 'dropdown-menu dropdown-menu-right');
      /**
       * @property columnsDropdownWrapper
       * @type string
       * @default 'float-right columns-dropdown dropdown'
       */
      _defineProperty(this, "columnsDropdownWrapper", 'float-right columns-dropdown dropdown');
      /**
       * @property columnsDropdownDivider
       * @type string
       * @default 'dropdown-divider'
       */
      _defineProperty(this, "columnsDropdownDivider", 'dropdown-divider');
      /**
       * @property buttonDefault
       * @type string
       * @default 'btn btn-secondary'
       */
      _defineProperty(this, "buttonDefault", 'btn btn-secondary');
      /**
       * @property footerSummaryNumericPagination
       * @type string
       * @default 'col-4'
       */
      _defineProperty(this, "footerSummaryNumericPagination", 'col-4');
      /**
       * @property footerSummaryDefaultPagination
       * @type string
       * @default  'col-5'
       */
      _defineProperty(this, "footerSummaryDefaultPagination", 'col-5');
      /**
       * @property pageSizeWrapper
       * @type string
       * @default 'col-2'
       */
      _defineProperty(this, "pageSizeWrapper", 'col-2');
      /**
       * @property pageSizeSelectWrapper
       * @type string
       * @default 'float-right'
       */
      _defineProperty(this, "pageSizeSelectWrapper", 'float-right');
      /**
       * @property paginationInternalWrapper
       * @type string
       * @default 'btn-toolbar float-right'
       */
      _defineProperty(this, "paginationInternalWrapper", 'btn-toolbar float-right');
      /**
       * @property paginationWrapperNumeric
       * @type string
       * @default 'col-6'
       */
      _defineProperty(this, "paginationWrapperNumeric", 'col-6');
      /**
       * @property paginationWrapperDefault
       * @type string
       * @default 'col-5'
       */
      _defineProperty(this, "paginationWrapperDefault", 'col-5');
      /**
       * @property clearFilterIcon
       * @type string
       * @default 'fa fa-times form-control-feedback'
       */
      _defineProperty(this, "clearFilterIcon", 'fa fa-times form-control-feedback');
      /**
       * @property clearAllFiltersIcon
       * @type string
       * @default 'fa fa-times'
       */
      _defineProperty(this, "clearAllFiltersIcon", 'fa fa-times');
      /**
       * @property sortGroupedPropertyBtn
       * @type string
       * @default 'btn'
       */
      _defineProperty(this, "sortGroupedPropertyBtn", 'btn');
      /**
       * @property input
       * @type string
       * @default 'form-control'
       */
      _defineProperty(this, "input", 'form-control');
      /**
       * @property inputGroup
       * @type string
       * @default 'input-group'
       */
      _defineProperty(this, "inputGroup", 'input-group');
      /**
       * @property sortAscIcon
       * @type string
       * @default 'fa fa-sort-asc'
       */
      _defineProperty(this, "sortAscIcon", 'fa fa-sort-asc');
      /**
       * @property sortDescIcon
       * @type string
       * @default 'fa fa-sort-desc'
       */
      _defineProperty(this, "sortDescIcon", 'fa fa-sort-desc');
      /**
       * @property columnVisibleIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "columnVisibleIcon", 'fa fa-check-square-o');
      /**
       * @property columnHiddenIcon
       * @type string
       * @default 'fa fa-square-o'
       */
      _defineProperty(this, "columnHiddenIcon", 'fa fa-square-o');
      /**
       * @property navFirstIcon
       * @type string
       * @default 'fa fa-angle-double-left'
       */
      _defineProperty(this, "navFirstIcon", 'fa fa-angle-double-left');
      /**
       * @property navPrevIcon
       * @type string
       * @default 'fa fa-angle-left'
       */
      _defineProperty(this, "navPrevIcon", 'fa fa-angle-left');
      /**
       * @property navNextIcon
       * @type string
       * @default 'fa fa-angle-right'
       */
      _defineProperty(this, "navNextIcon", 'fa fa-angle-right');
      /**
       * @property navLastIcon
       * @type string
       * @default 'fa fa-angle-double-right'
       */
      _defineProperty(this, "navLastIcon", 'fa fa-angle-double-right');
      /**
       * @property caretIcon
       * @type string
       * @default 'caret'
       */
      _defineProperty(this, "caretIcon", 'caret');
      /**
       * @property expandRowIcon
       * @type string
       * @default 'fa fa-plus'
       */
      _defineProperty(this, "expandRowIcon", 'fa fa-plus');
      /**
       * @property expandAllRowsIcon
       * @type string
       * @default 'fa fa-plus'
       */
      _defineProperty(this, "expandAllRowsIcon", 'fa fa-plus');
      /**
       * @property collapseRowIcon
       * @type string
       * @default 'fa fa-minus'
       */
      _defineProperty(this, "collapseRowIcon", 'fa fa-minus');
      /**
       * @property collapseAllRowsIcon
       * @type string
       * @default 'fa fa-minus'
       */
      _defineProperty(this, "collapseAllRowsIcon", 'fa fa-minus');
      /**
       * @property selectAllRowsIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "selectAllRowsIcon", 'fa fa-check-square-o');
      /**
       * @property deselectAllRowsIcon
       * @type string
       * @default 'fa fa-square-o'
       */
      _defineProperty(this, "deselectAllRowsIcon", 'fa fa-square-o');
      /**
       * @property selectSomeRowsIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "selectSomeRowsIcon", 'fa fa-minus-square-o');
      /**
       * @property selectRowIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "selectRowIcon", 'fa fa-check-square-o');
      /**
       * @property deselectRowIcon
       * @type string
       * @default 'fa fa-square-o'
       */
      _defineProperty(this, "deselectRowIcon", 'fa fa-square-o');
    }
    /**
     * @property buttonDefaultSmall
     * @type string
     * @default 'btn btn-secondary btn-sm'
     */
    get buttonDefaultSmall() {
      return `${this.buttonDefault} btn-sm`;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "buttonDefaultSmall", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "buttonDefaultSmall"), _class.prototype)), _class));
});