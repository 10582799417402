define("ember-cp-validations/validators/dependent", ["exports", "ember-cp-validations/validators/base", "ember-cp-validations/utils/get-with-default"], function (_exports, _base, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Defines an attribute as valid only if its dependents are valid.
   *
   *  ## Example
   *
   *  ```javascript
   *  // Full name will only be valid if firstName and lastName are filled in
   *  validator('dependent', {
   *    on: ['firstName', 'lastName']
   *  })
   *  ```
   *
   *  @class Dependent
   *  @module Validators
   *  @extends Base
   */
  const Dependent = _base.default.extend({
    /**
     * @method validate
     * @param {Any} value
     * @param {Object} options
     * @param {Array} options.on Attributes this field is dependent on
     * @param {Object} model
     * @param {String} attribute
     */
    validate(value, options, model, attribute) {
      let {
        on,
        allowBlank
      } = Ember.getProperties(options, ['on', 'allowBlank']);
      (true && !(Ember.isPresent(on)) && Ember.assert(`[validator:dependent] [${attribute}] option 'on' is required`, Ember.isPresent(on)));
      if (Ember.isNone(model)) {
        return true;
      }
      if (allowBlank && Ember.isEmpty(value)) {
        return true;
      }
      let dependentValidations = (0, _getWithDefault.default)(options, 'on', Ember.A()).map(dependent => Ember.get(model, `validations.attrs.${dependent}`));
      if (!Ember.isEmpty(dependentValidations.filter(v => Ember.get(v, 'isTruelyInvalid')))) {
        return this.createErrorMessage('invalid', value, options);
      }
      return true;
    }
  });
  Dependent.reopenClass({
    getDependentsFor(attribute, options) {
      let dependents = Ember.get(options, 'on');
      (true && !(Ember.isArray(dependents)) && Ember.assert(`[validator:dependent] [${attribute}] 'on' must be an array`, Ember.isArray(dependents)));
      if (!Ember.isEmpty(dependents)) {
        return dependents.map(dependent => `${dependent}.isTruelyValid`);
      }
      return [];
    }
  });
  var _default = _exports.default = Dependent;
});