define("ember-arg-types/-private/throw-console-error", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let throwConsoleError;
  if ((0, _runtime.macroCondition)(true)) {
    throwConsoleError = (fn, enabled = true) => {
      const original = console.error;
      console.error = enabled ? msg => {
        const errorMsg = msg.replace(/^Warning: /, '');
        throw new Error(errorMsg);
      } : original;
      try {
        fn();
      } catch (e) {
        throw e;
      } finally {
        console.error = original;
      }
    };
  } else {
    throwConsoleError = () => {
      throw new Error('throwConsoleError() is not available in production');
    };
  }
  var _default = _exports.default = throwConsoleError;
});