define("ember-models-table/components/models-table/themes/ember-paper/row-select-all-checkbox", ["exports", "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-all-checkbox", "@ember-decorators/component", "ember-models-table/components/models-table/row-select-all-checkbox"], function (_exports, _rowSelectAllCheckbox, _component, _rowSelectAllCheckbox2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   * @class EpModelsTableRowSelectAllCheckbox
   * @namespace Components
   * @extends Components.ModelsTableRowSelectAllCheckbox
   */
  let EpModelsTableRowSelectAllCheckboxComponent = _exports.default = (_dec = (0, _component.layout)(_rowSelectAllCheckbox.default), _dec(_class = class EpModelsTableRowSelectAllCheckboxComponent extends _rowSelectAllCheckbox2.default {}) || _class);
});