define("ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aZCKJNuF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,3],null,[[\"summary\"],[[32,0,[\"summary\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"bs-form\",[],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"inline\",[32,0],[32,0,[\"noop\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"label\"],[12],[1,[32,0,[\"summary\"]]],[13],[2,\"\\n    \"],[8,\"bs-button\",[[16,0,[30,[36,0],[\"clearFilters border-0 \",[32,0,[\"themeInstance\",\"buttonLink\"]]],null]],[16,\"disabled\",[30,[36,1],[[32,0,[\"anyFilterUsed\"]],\"disabled\"],null]]],[[\"@outline\",\"@onClick\"],[true,[30,[36,2],[[32,0,[\"doClearFilters\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"clearAllFiltersIcon\"]]],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"emt-sr-only\"],[12],[1,[32,0,[\"themeInstance\",\"clearAllFiltersMsg\"]]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"unless\",\"fn\",\"hash\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/summary.hbs"
  });
});