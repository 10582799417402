define("ember-models-table/templates/components/models-table/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nXAl9M1m",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"button\"],[15,0,[30,[36,0],[[32,0,[\"themeInstance\",\"buttonLink\"]],\" \",[32,0,[\"themeInstance\",\"expandAllRows\"]]],null]],[15,\"onclick\",[30,[36,1],[[32,0,[\"doExpandAllRows\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"expandAllRowsIcon\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"button\"],[15,0,[30,[36,0],[[32,0,[\"themeInstance\",\"buttonLink\"]],\" \",[32,0,[\"themeInstance\",\"collapseAllRows\"]]],null]],[15,\"onclick\",[30,[36,1],[[32,0,[\"doCollapseAllRows\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"collapseAllRowsIcon\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/expand-all-toggle.hbs"
  });
});