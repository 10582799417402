window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"uiClientAdapterApiHost":"","sgbApi":{"host":"https://api.gridrabbit.com","namespace":"api","authUrl":"https://api.gridrabbit.com/api/auth"},"sgbLiveStreamSocket":{"host":"wss://wss.gridrabbit.com"},"uploadApi":{"host":"https://static.gridrabbit.com","uploadUrl":"https://static.gridrabbit.com/uploadimage","imageUrl":"https://static.gridrabbit.com/image/template"},"FEATURES":{"showObjectCharts":true,"showObjectChartsGroupWatthoursPieChart":true,"showObjectChartsGroupWatthoursBarChart":true,"topGroupCharts":true,"dashboardBarChart":true,"calgaryDashboard":true,"newDashboard":true,"residentalView":true,"floorplanView":true,"floorplanUpload":{"forUsers":["grazina@sgb","hw@sgb","peterz@sgb","isj@sgb","laszlo@sgb"]},"buildingFloorplanEdit":{"forUsers":["grazina@sgb","hw@sgb","peterz@sgb","isj@sgb","laszlo@sgb"]},"adminReports":{"forUsers":["grazina@sgb","hw@sgb","peterz@sgb","isj@sgb","laszlo@sgb"],"forRoles":[3,24]},"adminBilling":{"forUsers":["grazina@sgb","hw@sgb","peterz@sgb","isj@sgb","laszlo@sgb"],"forRoles":[3,24]},"systemSettings":{"forUsers":["grazina@sgb","hw@sgb","peterz@sgb","isj@sgb","laszlo@sgb","sameer-hyattrc"]},"devicePresets":{"forUsers":["grazina@sgb","hw@sgb","peterz@sgb","isj@sgb","laszlo@sgb","sameer-hyattrc"]},"createDevice":{"forUsers":["grazina@sgb","hw@sgb","peterz@sgb","isj@sgb","laszlo@sgb"],"forRoles":[3]}},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":true,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true});

var runningTests = false;


