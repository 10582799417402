define("sgb-object-graphs/templates/components/sgb-chart-refresh-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <a href="#" {{on "click" (fn this.refresh)}} title="Refresh data">
    <i class="fas fa-sync"></i>
  </a>
  
  
  */
  {
    "id": "0ingO7DH",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[24,\"title\",\"Refresh data\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"refresh\"]]],null]],null],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fas fa-sync\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "moduleName": "sgb-object-graphs/templates/components/sgb-chart-refresh-widget.hbs"
  });

  _exports.default = _default;
});