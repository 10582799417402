define("ember-models-table/components/models-table/expand-all-toggle", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/expand-all-toggle"], function (_exports, _component, _expandAllToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
   * Component to expand or collapse all rows
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/expand-toggle',
   *     componentForFilterCell: 'models-table/expand-all-toggle',
   *     mayBeHidden: false
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   *
   * const data = [ ... ];
   * ```
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @multipleExpand={{true}}
   *   @expandedRowComponent={{component "path/to/your-component"}}
   * />
   * ```
   *
   * @class ModelsTableExpandAllToggle
   * @extends Ember.Component
   * @namespace Components
   */
  let ExpandAllToggleComponent = _exports.default = (_dec = (0, _component.layout)(_expandAllToggle.default), _dec2 = Ember._action, _dec3 = Ember._action, _dec(_class = (_class2 = class ExpandAllToggleComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * @property column
       * @type Utils.ModelsTableColumn
       * @default null
       */
      _defineProperty(this, "column", null);
      /**
       * Bound from [ModelsTable.data](Components.ModelsTable.html#property_data)
       *
       * @property data
       * @default null
       * @type object[]
       */
      _defineProperty(this, "data", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @default null
       * @type object[]
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @event toggleAllSelection
       */
      _defineProperty(this, "toggleAllSelection", null);
    }
    /**
     * @event doCollapseAllRows
     * @protected
     */
    doCollapseAllRows() {
      this.collapseAllRows();
      return false;
    }

    /**
     * @event doExpandAllRows
     * @protected
     */
    doExpandAllRows() {
      this.expandAllRows();
      return false;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "doCollapseAllRows", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doCollapseAllRows"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doExpandAllRows", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doExpandAllRows"), _class2.prototype)), _class2)) || _class);
});