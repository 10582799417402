define("ember-changeset-validations/utils/with-defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = withDefaults;
  /**
   * Create a new object with defaults
   *
   * @public
   * @param  {Object} obj
   * @param  {Object} defaults
   * @return {Object}
   */
  function withDefaults(obj = {}, defaults = {}) {
    return Ember.assign(Ember.assign({}, defaults), obj);
  }
});