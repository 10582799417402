define("ember-models-table/components/models-table/pagination-numeric", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/pagination-numeric"], function (_exports, _component, _paginationNumeric) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Numeric navigation used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationNumeric />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PaginationNumeric as |PN|>
   *       {{#each PN.visiblePageNumbers as |page|}}
   *         {{#if page.isLink}}
   *           <button
   *             class="{{MT.themeInstance.paginationNumericItem}} {{if page.isActive MT.themeInstance.paginationNumericItemActive}} {{MT.themeInstance.buttonDefault}}"
   *             {{action MT.goToPage page.label}}>
   *             {{page.label}}
   *           </button>
   *         {{else}}
   *           <button
   *             type="button"
   *             class="{{MT.themeInstance.buttonDefault}} {{MT.themeInstance.paginationNumericItem}}"
   *             disabled="disabled">
   *             {{page.label}}
   *           </button>
   *         {{/if}}
   *       {{/each}}
   *       <PN.PageNumberSelect />
   *     </Footer.PaginationNumeric>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * ModelsTablePaginationNumeric yields references to the following contextual components:
   *
   * * PageNumberSelect - selectbox with list of available pages
   *
   * References to the following properties are yielded:
   *
   * * [visiblePageNumbers](Components.ModelsTablePaginationNumeric.html#property_visiblePageNumbers)
   *
   * @class ModelsTablePaginationNumeric
   * @namespace Components
   * @extends Ember.Component
   */
  let PaginationNumericComponent = _exports.default = (_dec = (0, _component.layout)(_paginationNumeric.default), _dec2 = Ember.computed.alias('themeInstance.paginationWrapper'), _dec3 = Ember.computed.alias('themeInstance.paginationWrapperNumeric'), _dec4 = Ember.computed('pagesCount', 'currentPageNumber', 'collapseNumPaginationForPagesCount'), _dec5 = Ember.computed('elementId'), _dec6 = Ember._action, _dec(_class = (_class2 = class PaginationNumericComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * @property themePaginationWrapperClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themePaginationWrapperClass", _descriptor, this);
      /**
       * @property themePaginationWrapperNumericClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themePaginationWrapperNumericClass", _descriptor2, this);
      /**
       * Bound from [ModelsTable.collapseNumPaginationForPagesCount](Components.ModelsTable.html#property_collapseNumPaginationForPagesCount)
       *
       * @property collapseNumPaginationForPagesCount
       * @type number
       * @default null
       */
      _defineProperty(this, "collapseNumPaginationForPagesCount", null);
      /**
       * Bound from [ModelsTable.currentPageNumber](Components.ModelsTable.html#property_currentPageNumber)
       *
       * @property currentPageNumber
       * @type number
       * @default 1
       */
      _defineProperty(this, "currentPageNumber", 1);
      /**
       * Bound from [ModelsTable.showCurrentPageNumberSelect](Components.ModelsTable.html#property_showCurrentPageNumberSelect)
       *
       * @property showCurrentPageNumberSelect
       * @type boolean
       * @default null
       */
      _defineProperty(this, "showCurrentPageNumberSelect", null);
      /**
       * Bound from [ModelsTable.currentPageNumberOptions](Components.ModelsTable.html#property_currentPageNumberOptions)
       *
       * @property currentPageNumberOptions
       * @type SelectOption[]
       * @default null
       */
      _defineProperty(this, "currentPageNumberOptions", null);
      /**
       * Bound from [ModelsTable.arrangedContentLength](Components.ModelsTable.html#property_arrangedContentLength)
       *
       * @property recordsCount
       * @type number
       * @default null
       */
      _defineProperty(this, "recordsCount", null);
      /**
       * Bound from [ModelsTable.pageSize](Components.ModelsTable.html#property_pageSize)
       *
       * @property pageSize
       * @type number
       * @default 10
       */
      _defineProperty(this, "pageSize", 10);
      /**
       * Bound from [ModelsTable.pagesCount](Components.ModelsTable.html#property_pagesCount)
       *
       * @property pagesCount
       * @type number
       * @default null
       */
      _defineProperty(this, "pagesCount", null);
      /**
       * Closure action [ModelsTable.gotoCustomPage](Components.ModelsTable.html#event_gotoCustomPage)
       *
       * @event goToPage
       */
      _defineProperty(this, "goToPage", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
    /**
     * List of links to the page
     * Used if [ModelsTable.useNumericPagination](Components.ModelsTable.html#property_useNumericPagination) is true
     *
     * @type object[]
     * @property visiblePageNumbers
     * @default []
     * @protected
     */
    get visiblePageNumbers() {
      const {
        pagesCount,
        currentPageNumber,
        collapseNumPaginationForPagesCount
      } = this;
      const notLinkLabel = '...';
      const showAll = pagesCount <= collapseNumPaginationForPagesCount;
      let groups = []; // array of 8 numbers
      let labels = Ember.A([]);
      groups[0] = 1;
      groups[1] = Math.min(1, pagesCount);
      groups[6] = Math.max(1, pagesCount);
      groups[7] = pagesCount;
      groups[3] = Math.max(groups[1] + 1, currentPageNumber - 1);
      groups[4] = Math.min(groups[6] - 1, currentPageNumber + 1);
      groups[2] = Math.floor((groups[1] + groups[3]) / 2);
      groups[5] = Math.floor((groups[4] + groups[6]) / 2);
      if (showAll) {
        for (let i = groups[0]; i <= groups[7]; i++) {
          labels[i] = i;
        }
      } else {
        for (let n = groups[0]; n <= groups[1]; n++) {
          labels[n] = n;
        }
        const userGroup2 = groups[4] >= groups[3] && groups[3] - groups[1] > 1;
        if (userGroup2) {
          labels[groups[2]] = notLinkLabel;
        }
        for (let i = groups[3]; i <= groups[4]; i++) {
          labels[i] = i;
        }
        const userGroup5 = groups[4] >= groups[3] && groups[6] - groups[4] > 1;
        if (userGroup5) {
          labels[groups[5]] = notLinkLabel;
        }
        for (let i = groups[6]; i <= groups[7]; i++) {
          labels[i] = i;
        }
      }
      return Ember.A(labels.compact().map(label => ({
        label,
        isLink: label !== notLinkLabel,
        isActive: label === currentPageNumber
      })));
    }

    /**
     * @property inputId
     * @type string
     * @private
     */
    get inputId() {
      return `${this.elementId}-page-number-select`;
    }

    /**
     * @event gotoCustomPage
     * @param {number} pageNumber
     * @param {Event} e
     * @protected
     */
    gotoCustomPage(pageNumber, e) {
      if (e) {
        e.stopPropagation();
      }
      this.goToPage(pageNumber);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "themePaginationWrapperClass", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "themePaginationWrapperNumericClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "visiblePageNumbers", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "visiblePageNumbers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "gotoCustomPage", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "gotoCustomPage"), _class2.prototype)), _class2)) || _class);
});