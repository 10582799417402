define("sgb-object-graphs/components/sgb-group-watthours-bar-chart", ["exports", "sgb-object-graphs/components/sgb-base-chart", "sgb-object-graphs/components/sgb-device-line-chart", "sgb-object-graphs/components/sgb-group-watthours-pie-chart"], function (_exports, _sgbBaseChart, _sgbDeviceLineChart, _sgbGroupWatthoursPieChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  class ReduceDataIntoSlots {
    constructor(slot_reduce_fn, reduce_initial_value) {
      this.slot_reduce_fn = slot_reduce_fn;
      this.reduce_initial_value = reduce_initial_value;
    }

    process(openTsdbData) {
      let self = this;
      let slot_starts = this.get_slot_starts(this.to_timestamps(openTsdbData));
      openTsdbData.forEach(function (single_tsdb_data) {
        let new_dps = slot_starts.map(slot => [slot, self.reduce_initial_value]);
        single_tsdb_data.dps.forEach(function (_ref) {
          let [ts, val] = _ref;
          let slots_lte_ts = slot_starts.filter(slot => ts < slot);
          let slot_index = slot_starts.length - slots_lte_ts.length - 1;
          let dp = new_dps[slot_index];

          if (dp == undefined) {
            console.error({
              msg: 'cant find slot',
              ts: ts,
              slots_lte_ts: slots_lte_ts,
              slot_index: slot_index,
              slot_starts: slot_starts
            });
          } else {
            dp[1] = self.slot_reduce_fn(dp[1], val);
          }
        });
        single_tsdb_data.dps = new_dps;
      });
      return openTsdbData;
    }

    to_timestamps(openTsdbData) {
      let timestamps = openTsdbData.map(metric => metric.dps.map(dp => dp[0])); // when timestamps.length == 0, TypeError: Reduce of empty array with no initial value

      return timestamps.reduce((acc, current) => acc.concat(current), []);
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(ReduceDataIntoSlots, true);

  window.__CLASSIC_OWN_CLASSES__.set(ReduceDataIntoSlots, false);

  class ReduceDataIntoNSlots extends ReduceDataIntoSlots {
    // eslint-disable-line no-unused-vars
    constructor(nr_of_slots, slot_reduce_fn, reduce_initial_value) {
      super(slot_reduce_fn, reduce_initial_value);
      this.nr_of_slots = nr_of_slots;
    }

    get_slot_starts(values) {
      values.sort();

      if (values.length <= this.nr_of_slots) {
        return values;
      }

      let mn = values[0];
      let mx = values[values.length - 1];
      let step = Math.floor((mx - mn) / this.nr_of_slots);
      let res = [];

      for (var i = 0; i < this.nr_of_slots; i++) {
        res.push(mn + i * step);
      }

      return res;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(ReduceDataIntoNSlots, true);

  window.__CLASSIC_OWN_CLASSES__.set(ReduceDataIntoNSlots, false);

  class ReduceDataIntoHourSlots extends ReduceDataIntoSlots {
    get_slot_starts(values) {
      if (values.length == 0) {
        return values;
      }

      let mn = Math.min(...values);
      let mx = Math.max(...values);
      const hour_in_msec = 1000 * 60 * 60;
      let res = [];

      for (let hourStartTime = mn; hourStartTime < mx; hourStartTime += hour_in_msec) {
        res.push(hourStartTime);
      }

      return res;
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(ReduceDataIntoHourSlots, false);

  class TsdbMetricResultToChartjsBarChartData {
    hex2rgb_list(input) {
      if (input == null) {
        return input;
      }

      return input.replace('#', '').match(/.{2}/g).map(hex => parseInt(hex, 16));
    }

    process(openTsdbData) {
      let labels = []; // if null, TypeError: Cannot read property 'map' of null ir Cannot read property 'length' of null

      let self = this;
      let unit = null;
      let datasets = openTsdbData.map(function (single_tsdb_metric) {
        if (unit == null) {
          unit = single_tsdb_metric.sgb.si.unit;
        } else if (unit != single_tsdb_metric.sgb.si.unit) {
          //  TODO: need to consolidate units across datasets for the same unit - in ApplySIUnits?
          console.error(`mutliple units - first ${unit}, now ${single_tsdb_metric.sgb.si.unit}`);
        }

        if (labels.length == 0) {
          labels = single_tsdb_metric.dps.map(dp => new Date(dp[0]));
        }

        return {
          label: single_tsdb_metric.sgb_label,
          data: single_tsdb_metric.dps.map(dp => dp[1]),
          sgb: {
            color: self.hex2rgb_list(single_tsdb_metric.sgb_color)
          }
        };
      });

      if (!datasets || datasets.length == 0) {
        // TODO: allow sending some error message too
        console.warn(`Converted datasets was empty - incoming tsdb data had ${openTsdbData.length}`);
        return null;
      }

      return {
        datasets: datasets,
        labels: labels,
        sgb: {
          unit: unit
        }
      };
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(TsdbMetricResultToChartjsBarChartData, false);

  class EnsureSameUnitAcrossAlTsdbMetrics {
    process(openTsdbData) {
      let max_si = null;
      openTsdbData.forEach(function (single_tsdb_data) {
        if (max_si == null || max_si.denom < single_tsdb_data.sgb.si.denom) {
          max_si = single_tsdb_data.sgb.si;
        }
      });
      openTsdbData.forEach(function (single_tsdb_data) {
        if (max_si.denom != single_tsdb_data.sgb.si.denom) {
          let delta_denom = max_si.denom / single_tsdb_data.sgb.si.denom;
          single_tsdb_data.sgb.si = max_si;
          single_tsdb_data.dps.forEach(function (dps) {
            dps[1] /= delta_denom;
          });
        }
      });
      return openTsdbData;
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(EnsureSameUnitAcrossAlTsdbMetrics, false);

  class EnsureBarChartsAreAllFullyPresentOnScreenAfterTimeLabelsAreApplied {
    constructor(allDates) {
      this.allDates = allDates.slice().sort();
      this.mn = allDates[0];
      this.mx = allDates[allDates.length - 1];
    }

    process(options) {
      let time = options.scales.x.time;
      let ticks = options.scales.x.ticks;
      let stepSizeInMilliseconds = (time.stepSize || 1) * 60 * 1000;
      let offsetIMilliseconds = stepSizeInMilliseconds / 2; // options.scales.x.min = new Date(this.mn.getTime() - offsetIMilliseconds);
      // options.scales.x.max = new Date(this.mx.getTime() + offsetIMilliseconds);

      return options;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(EnsureBarChartsAreAllFullyPresentOnScreenAfterTimeLabelsAreApplied, true);

  window.__CLASSIC_OWN_CLASSES__.set(EnsureBarChartsAreAllFullyPresentOnScreenAfterTimeLabelsAreApplied, false);

  let SgbGroupWatthoursBarChart = (_dec = Ember.inject.service('object-lookup-for-charts'), classic(_class = (_class2 = class SgbGroupWatthoursBarChart extends _sgbBaseChart.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "objectLookup", _descriptor, this);

      _defineProperty(this, "chartComponentName", 'sgb-ember-bar-chart');

      _defineProperty(this, "decimalPlaces", 4);
    }

    opentsdb_to_chartjs(openTsdbData) {
      // only needed for testing dummy data
      this.objectLookup.reset?.();
      let processors = [new _sgbGroupWatthoursPieChart.MakeACopyOfTheTsdbData(), new _sgbGroupWatthoursPieChart.DeviceOrDeviceGroupLevelDataMerger(this.objectLookup, this.aggregateLevel), new _sgbBaseChart.RemoveOnlyZeroMetrics(), new _sgbGroupWatthoursPieChart.SortByIntegerTags(['device_type_id', 'device_id']), new _sgbGroupWatthoursPieChart.AssignGroup(this.objectLookup), new _sgbGroupWatthoursPieChart.AssignColorTintsBasedOnGroup(), new ReduceDataIntoHourSlots((a, b) => a + b, 0), new _sgbDeviceLineChart.ForEachTsdbSingleMetric(new _sgbGroupWatthoursPieChart.ApplySIUnits(single_tsdb_data => single_tsdb_data.dps, // TODO: introduce NullMetric later down the line
      single_tsdb_data => 'Wh', // eslint-disable-line no-unused-vars
      dps => dps[1], _sgbGroupWatthoursPieChart.map_in_place_dps_val)), new EnsureSameUnitAcrossAlTsdbMetrics(), new _sgbDeviceLineChart.ForEachTsdbSingleMetric(new _sgbDeviceLineChart.RoundDpsValuesFromSgbMetric(this.decimalPlaces)), new TsdbMetricResultToChartjsBarChartData(), new _sgbDeviceLineChart.AssignColorsToDatasets([['backgroundColor', 0.8], ['borderColor', 1], ['hoverBackgroundColor', 0.9]], dp => dp.sgb.color) //new ApplySIUnits(d => d.datasets[0].data, data => 'Wh', x => x, map_in_place_list), // TODO: introduce  wrappers InChartJS vs InTsdb?
      //new FixDecimalPlaces(d => d.datasets[0].data, 2, map_in_place_list)
      ];
      return (0, _sgbBaseChart.applyProcessors)(openTsdbData, processors);
    }

    getOptions(data) {
      let unit = data.sgb && data.sgb.unit ? ` ${data.sgb.unit}` : '';
      let options = {
        plugins: {
          tooltip: {
            position: 'nearest',
            mode: 'point',
            intersect: false
          }
        },
        legend: {
          position: 'top'
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              autoSkip: false
            },
            // ensure all labels are shown there
            grid: {
              color: '#5a658340'
            }
          },
          y: {
            stacked: true,
            ticks: {
              callback: function (value, index, values) {
                // eslint-disable-line no-unused-vars
                let rounded = value.toFixed(2);
                return `${rounded}${unit}`;
              }
            },
            grid: {
              color: '#5a658340'
            }
          }
        }
      };
      let timeFormat = this.timeFormat; // TODO: move up?

      let nrOfXAxisLabels = this.nrOfXAxisLabels; // TODO: move up?

      let allDates = data.labels;
      let processors = [new _sgbDeviceLineChart.OptionsApplyFontColor(this.fontColor, true), new _sgbDeviceLineChart.OptionsTimeLabelsForAxisX(allDates, nrOfXAxisLabels, timeFormat), new EnsureBarChartsAreAllFullyPresentOnScreenAfterTimeLabelsAreApplied(allDates)];
      return (0, _sgbBaseChart.applyProcessors)(options, processors);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "objectLookup", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = SgbGroupWatthoursBarChart;
});