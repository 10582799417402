define("ember-models-table/templates/components/models-table/themes/ember-paper/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SFrG0h+t",
    "block": "{\"symbols\":[\"@record\",\"@index\",\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"isExpanded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[30,[36,0],[[32,0,[\"doCollapseRow\"]],[32,2],[32,1]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[[16,0,[32,0,[\"themeInstance\",\"collapseRowIcon\"]]]],[[\"@icon\"],[[32,0,[\"themeInstance\",\"collapseRowIcon\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[30,[36,0],[[32,0,[\"doExpandRow\"]],[32,2],[32,1]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[[16,0,[32,0,[\"themeInstance\",\"expandRowIcon\"]]]],[[\"@icon\"],[[32,0,[\"themeInstance\",\"expandRowIcon\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,3,null]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/expand-toggle.hbs"
  });
});