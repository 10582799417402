define("@zestia/ember-simple-infinite-scroller/components/infinite-scroller", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class={{concat
      "infinite-scroller"
      (if this.isLoading " infinite-scroller--loading")
      (if this.isScrollable " infinite-scroller--scrollable")
    }}
    ...attributes
    {{did-insert this.handleInsertElement}}
    {{will-destroy this.handleDestroyElement}}
  >
    {{yield this.api}}
  </div>
  */
  {
    "id": "KMMgZoNl",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,1],[\"infinite-scroller\",[30,[36,0],[[32,0,[\"isLoading\"]],\" infinite-scroller--loading\"],null],[30,[36,0],[[32,0,[\"isScrollable\"]],\" infinite-scroller--scrollable\"],null]],null]],[17,1],[4,[38,2],[[32,0,[\"handleInsertElement\"]]],null],[4,[38,3],[[32,0,[\"handleDestroyElement\"]]],null],[12],[2,\"\\n  \"],[18,2,[[32,0,[\"api\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"did-insert\",\"will-destroy\"]}",
    "moduleName": "@zestia/ember-simple-infinite-scroller/components/infinite-scroller.hbs"
  });
  const {
    round
  } = Math;
  let InfiniteScrollerComponent = _exports.default = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class InfiniteScrollerComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "debug", false);
      _defineProperty(this, "scroller", null);
      _defineProperty(this, "debounceId", null);
      _initializerDefineProperty(this, "isLoading", _descriptor, this);
      _initializerDefineProperty(this, "isScrollable", _descriptor2, this);
      this._scheduleCheckScrollable();
    }
    get api() {
      return {
        setElement: this.setElement,
        isScrollable: this.isScrollable,
        isLoading: this.isLoading,
        loadMore: this.loadMore
      };
    }
    get debounce() {
      return this.args.debounce ?? 100;
    }
    get percent() {
      return this.args.percent ?? 100;
    }
    get normalisedScrollerElement() {
      if (this.scroller instanceof Document) {
        return this.scroller.documentElement;
      } else {
        return this.scroller;
      }
    }
    handleInsertElement(element) {
      if (!this.scroller) {
        this._registerScroller(this.args.element ?? element);
      }
    }
    handleDestroyElement() {
      this._deregisterScroller();
    }
    handleScroll() {
      this.debounceId = Ember.run.debounce(this, '_checkShouldLoadMore', this.debounce);
    }
    loadMore() {
      this._loadMore();
    }
    setElement(element) {
      this._registerScroller(element);
    }
    _registerScroller(element) {
      if (this.scroller) {
        this._stopListening();
      }
      this.scroller = element;
      this._startListening();
    }
    _deregisterScroller() {
      this._stopListening();
      Ember.run.cancel(this.debounceId);
      this.scroller = null;
    }
    _startListening() {
      this.scroller.addEventListener('scroll', this.handleScroll);
    }
    _stopListening() {
      this.scroller.removeEventListener('scroll', this.handleScroll);
    }
    _checkShouldLoadMore() {
      const scrollState = this._getScrollState();
      const shouldLoadMore = scrollState.reachedBottom && !this.isLoading;
      this._debug({
        ...scrollState,
        shouldLoadMore
      });
      if (shouldLoadMore) {
        this._loadMore();
      }
    }
    _checkScrollable() {
      if (!this.scroller) {
        return;
      }
      const scrollState = this._getScrollState();
      this._debug({
        ...scrollState
      });
      this.isScrollable = scrollState.isScrollable;
    }
    _scheduleCheckScrollable() {
      Ember.run.scheduleOnce('afterRender', this, '_checkScrollable');
    }
    _debug(state) {
      if (!this.debug) {
        return;
      }
      console.table([state]); // eslint-disable-line
    }
    _getScrollState() {
      const element = this.normalisedScrollerElement;
      const scrollHeight = element.scrollHeight;
      const scrollTop = element.scrollTop;
      const clientHeight = element.clientHeight;
      const isScrollable = scrollHeight > clientHeight;
      const bottom = scrollHeight - clientHeight;
      const percent = this.percent;
      const percentScrolled = round(scrollTop / bottom * 100);
      const reachedBottom = percentScrolled >= percent;
      return {
        isScrollable,
        scrollHeight,
        clientHeight,
        scrollTop,
        bottom,
        percent,
        percentScrolled,
        reachedBottom
      };
    }
    _loadMore() {
      this.isLoading = true;
      Ember.RSVP.resolve(this.args.onLoadMore?.()).finally(() => {
        this.isLoading = false;
        this._scheduleCheckScrollable();
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isScrollable", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleInsertElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDestroyElement", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleDestroyElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleScroll", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadMore", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "loadMore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setElement", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setElement"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, InfiniteScrollerComponent);
});