define("sgb-object-graphs/helpers/humanize-interval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.humanizeInterval = humanizeInterval;

  class Unit {
    constructor(n, unit) {
      this.n = n;
      this.unit = unit;
    }

    toString() {
      if (this.n == 1) {
        return `1 ${this.unit}`;
      } else {
        return `${this.n} ${this.unit}s`;
      }
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(Unit, true);

  window.__CLASSIC_OWN_CLASSES__.set(Unit, false);

  class DaysOrHours {
    constructor(hours) {
      this.hours = new Unit(hours, 'hour');
      this.days = new Unit(Math.floor(hours / 24), 'day');
      this.extra_hours = hours % 24;
    }

    is_day() {
      return this.hours.n >= 48 && this.extra_hours === 0;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(DaysOrHours, true);

  window.__CLASSIC_OWN_CLASSES__.set(DaysOrHours, false);

  class Interval {
    constructor(start_hours_ago, end_hours_ago) {
      this.start = new DaysOrHours(start_hours_ago);
      this.end = new DaysOrHours(end_hours_ago);

      if (this.start.is_day() && this.end.is_day()) {
        this.property_name = 'days';
      } else {
        this.property_name = 'hours';
      }
    }

    toString() {
      let start = this.start[this.property_name],
          end = this.end[this.property_name];
      return `${start.n} to ${end.n} ${start.unit}s ago`;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(Interval, true);

  window.__CLASSIC_OWN_CLASSES__.set(Interval, false);

  class IntervalEndingNow extends Interval {
    constructor(start_hours_ago) {
      super(start_hours_ago, start_hours_ago);
    }

    toString() {
      let start = this.start[this.property_name];
      return `last ${start}`;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(IntervalEndingNow, true);

  window.__CLASSIC_OWN_CLASSES__.set(IntervalEndingNow, false);

  function humanizeInterval(params
  /*, hash*/
  ) {
    let [start_hours_ago, end_hours_ago] = params;
    start_hours_ago = parseInt(start_hours_ago);
    end_hours_ago = parseInt(end_hours_ago);
    let interval = end_hours_ago == 0 ? new IntervalEndingNow(start_hours_ago) : new Interval(start_hours_ago, end_hours_ago);
    return interval.toString();
  }

  var _default = Ember.Helper.helper(humanizeInterval);

  _exports.default = _default;
});