define("ember-models-table/templates/components/models-table/themes/ember-paper/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+z09jmNn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,2],null,[[\"summary\"],[[32,0,[\"summary\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"summary\"]]],[2,\"\\n  \"],[8,\"paper-button\",[[24,0,\"clearFilters\"]],[[\"@disabled\",\"@onClick\"],[[30,[36,0],[[32,0,[\"anyFilterUsed\"]],\"disabled\"],null],[30,[36,1],[[32,0,[\"doClearFilters\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"paper-icon\",[],[[\"@icon\"],[[32,0,[\"themeInstance\",\"clearAllFiltersIcon\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"span\"],[14,0,\"emt-sr-only\"],[12],[1,[32,0,[\"themeInstance\",\"clearAllFiltersMsg\"]]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"fn\",\"hash\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/summary.hbs"
  });
});