define("ember-models-table/components/models-table/row-filtering-cell", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-filtering-cell"], function (_exports, _component, _rowFilteringCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Filter-row cell used within [models-table/row-filtering](Components.ModelsTableRowFiltering.html).
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowFiltering as |RF|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RF.RowFilteringCell @column={{column}} />
   *         {{/each}}
   *       </Header.RowFiltering>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * If `column.componentForFilterCell` is provided it is yielded with next properties and actions:
   *
   * * [column](Component.ModelsTableCellContentDisplay.html#property_column)
   * * [selectedItems](Component.ModelsTableCellContentDisplay.html#property_selectedItems)
   * * [expandedItems](Component.ModelsTableCellContentDisplay.html#property_expandedItems)
   * * [data](Component.ModelsTableCellContentDisplay.html#property_data)
   * * [themeInstance](Component.ModelsTableCellContentDisplay.html#property_themeInstance)
   * * [expandAllRows](Component.ModelsTableCellContentDisplay.html#event_expandAllRows)
   * * [collapseAllRows](Component.ModelsTableCellContentDisplay.html#event_collapseAllRows)
   * * [toggleAllSelection](Component.ModelsTableCellContentDisplay.html#event_toggleAllSelection)
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowFiltering as |RF|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RF.RowFilteringCell @column={{column}} as |RowFilteringCellContent|>
   *             <RowFilteringCellContent/>
   *           </RF.RowFilteringCell>
   *         {{/each}}
   *       </Header.RowFiltering>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableRowFilteringCell
   * @extends Ember.Component
   */
  let RowFilteringCellComponent = _exports.default = (_dec = (0, _component.layout)(_rowFilteringCell.default), _dec2 = (0, _component.tagName)('th'), _dec3 = Ember.computed.alias('themeInstance.theadCell'), _dec4 = Ember.computed.alias('column.className'), _dec5 = Ember.computed.readOnly('column.realColspanForFilterCell'), _dec6 = Ember.computed('column.useFilter', 'themeInstance.theadCellNoFiltering'), _dec7 = Ember.computed('elementId'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class RowFilteringCellComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'th'
       */
      /**
       * @property themeTheadCellClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeTheadCellClass", _descriptor, this);
      /**
       * @property columnClassName
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "columnClassName", _descriptor2, this);
      /**
       * @property colspan
       * @type number
       * @protected
       */
      _initializerDefineProperty(this, "colspan", _descriptor3, this);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * @property column
       * @default null
       * @type Utils.ModelsTableColumn
       */
      _defineProperty(this, "column", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @default null
       * @type object[]
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @default null
       * @type object[]
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @event toggleAllSelection
       */
      _defineProperty(this, "toggleAllSelection", null);
    }
    /**
     * @property filteringClassName
     * @type string
     * @protected
     */
    get filteringClassName() {
      return this.column.useFilter ? '' : this.themeInstance.theadCellNoFiltering;
    }
    /**
     * @property inputId
     * @type string
     * @protected
     */
    get inputId() {
      return `${this.elementId}-column-filter`;
    }

    /**
     * @event noop
     * @protected
     */
    noop(e) {
      e.stopPropagation();
    }

    /**
     * @event updateColumnFilterString
     * @param {Event} e
     * @protected
     */
    updateColumnFilterString(e) {
      if (e) {
        e.stopPropagation();
      }
      Ember.set(this, 'column.filterString', e.target.value);
      return false;
    }

    /**
     * @event clearFilter
     * @param {Event} e
     * @protected
     */
    clearFilter(e) {
      if (e) {
        e.stopPropagation();
      }
      Ember.set(this, 'column.filterString', '');
      return false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "themeTheadCellClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "columnClassName", [_component.className, _dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "colspan", [_component.attribute, _dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "filteringClassName", [_component.className, _dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "filteringClassName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noop", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "updateColumnFilterString", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "updateColumnFilterString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clearFilter", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "clearFilter"), _class2.prototype)), _class2)) || _class) || _class);
});