define("ember-models-table/components/models-table/summary", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/summary", "ember-models-table/utils/fmt"], function (_exports, _component, _summary, _fmt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Summary block used within [models-table/footer](Components.ModelsTableFooter.html).
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.Summary />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * References to the following properties are yielded:
   *
   * * [summary](Components.ModelsTableSummary.html#property_summary) - message like "Show xx - yy from zzz"
   *
   * @class ModelsTableSummary
   * @namespace Components
   * @extends Ember.Component
   */
  let SummaryComponent = _exports.default = (_dec = (0, _component.layout)(_summary.default), _dec2 = Ember.computed.alias('themeInstance.footerSummary'), _dec3 = Ember.computed('useNumericPagination', 'themeInstance.{footerSummaryNumericPagination,footerSummaryDefaultPagination}'), _dec4 = Ember.computed('firstIndex', 'lastIndex', 'msg', 'recordsCount', 'themeInstance.tableSummaryMsg'), _dec5 = Ember.computed('elementId'), _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = class SummaryComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * @property themeFooterSummaryClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeFooterSummaryClass", _descriptor, this);
      /**
       * Bound from [ModelsTable.firstIndex](Components.ModelsTable.html#property_firstIndex)
       *
       * @property firstIndex
       * @type number
       * @default null
       */
      _defineProperty(this, "firstIndex", null);
      /**
       * Bound from [ModelsTable.lastIndex](Components.ModelsTable.html#property_lastIndex)
       *
       * @property lastIndex
       * @type number
       * @default null
       */
      _defineProperty(this, "lastIndex", null);
      /**
       * Bound from [ModelsTable.arrangedContentLength](Components.ModelsTable.html#property_arrangedContentLength)
       *
       * @property recordsCount
       * @type number
       * @default null
       */
      _defineProperty(this, "recordsCount", null);
      /**
       * Bound from [ModelsTable.anyFilterUsed](Components.ModelsTable.html#property_anyFilterUsed)
       *
       * @property anyFilterUsed
       * @type boolean
       * @default null
       */
      _defineProperty(this, "anyFilterUsed", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Closure action [ModelsTable.clearFilters](Components.ModelsTable.html#event_clearFilters)
       *
       * @event clearFilters
       */
      _defineProperty(this, "clearFilters", null);
      /**
       * Bound from [ModelsTable.useNumericPagination](Components.ModelsTable.html#property_useNumericPagination)
       *
       * @property useNumericPagination
       * @type boolean
       * @default null
       */
      _defineProperty(this, "useNumericPagination", null);
    }
    /**
     * @property paginationTypeClass
     * @type string
     * @protected
     */
    get paginationTypeClass() {
      return this.useNumericPagination ? this.themeInstance.footerSummaryNumericPagination : this.themeInstance.footerSummaryDefaultPagination;
    }
    /**
     * @property summary
     * @type string
     * @protected
     */
    get summary() {
      return (0, _fmt.default)(this.themeInstance.tableSummaryMsg, this.firstIndex, this.lastIndex, this.recordsCount);
    }

    /**
     * @property inputId
     * @type string
     * @protected
     */
    get inputId() {
      return `${this.elementId}-summary-input`;
    }

    /**
     * @event doClearFilters
     * @param {Event} e
     * @protected
     */
    doClearFilters(e) {
      if (e) {
        e.stopPropagation();
      }
      this.clearFilters();
    }

    /**
     * @event noop
     * @protected
     */
    noop() {}
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "themeFooterSummaryClass", [_component.className, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "paginationTypeClass", [_component.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "paginationTypeClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "summary", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "summary"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "inputId", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "inputId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doClearFilters", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "doClearFilters"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "noop", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "noop"), _class2.prototype)), _class2)) || _class);
});