define("ember-models-table/utils/column", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.propertyNameToTitle = propertyNameToTitle;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /*
   * Convert some string to the human readable one
   *
   * @param {string} name value to convert
   * @return string
   */
  function propertyNameToTitle(name) {
    return Ember.String.capitalize(Ember.String.dasherize(name).replace(/-/g, ' '));
  }

  /**
   * Column definition class for ModelsTable columns
   *
   * Check its public properties
   *
   * @class ModelsTableColumn
   * @extends Ember.Object
   * @namespace Utils
   */
  let ModelsTableColumn = _exports.default = (_dec = Ember.computed.readOnly('__mt.data'), _dec2 = Ember.computed('propertyName'), _dec3 = Ember.computed.not('isHidden'), _dec4 = Ember.computed.equal('sorting', 'asc'), _dec5 = Ember.computed.equal('sorting', 'desc'), _dec6 = Ember.computed.notEmpty('filterString'), _dec7 = Ember.computed('sortField', 'disableSorting'), _dec8 = Ember.computed('sortedBy', 'propertyName'), _dec9 = Ember.computed('filterField', 'disableFiltering'), _dec10 = Ember.computed('filteredBy', 'propertyName'), _dec11 = (0, _object.observes)('filterWithSelect', 'filterOptions.[]', 'filterString'), (_class = class ModelsTableColumn extends Ember.Object {
    constructor(...args) {
      super(...args);
      /**
       * Value inverted to the [isHidden](Utils.ModelsTableColumn.html#property_isHidden) initial value
       *
       * It set on column init and not changed any more
       *
       * @property defaultVisible
       * @type boolean
       * @private
       * @readOnly
       */
      /**
       * Field-name of the data's object shown in the current column. If it isn't provided, sorting and filtering options for current column are ignored
       *
       * @property propertyName
       * @default ''
       * @type string
       */
      _defineProperty(this, "propertyName", '');
      /**
       * Header for column. If it isn't provided, capitalized `propertyName` is used
       *
       * @property title
       * @default ''
       * @type string
       */
      _defineProperty(this, "title", null);
      /**
       * If `true` only `propertyName` will be shown in the column's cells and no components etc. Edit-mode won't affect such column.
       *
       * If `false` column's cells will be processed as usual (components will be used to display data and for edit-mode)
       *
       * @property simple
       * @type boolean
       * @default false
       */
      _defineProperty(this, "simple", false);
      /**
       * Custom component used in the column's cells.
       *
       * It will receive several options:
       *
       *  * `data` - whole dataset passed to the `models-table`
       *  * `record` - current row
       *  * `index` - current row index
       *  * `column` - current column (one of the [processedColumns](Components.ModelsTable.html#property_processedColumns))
       *  * `expandRow` - closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *  * `collapseRow` - closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *  * `expandAllRows` - closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *  * `collapseAllRows` - closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *  * `clickOnRow` - closure action [ModelsTable.clickOnRow](Components.ModelsTable.html#event_clickOnRow)
       *  * `editRow` - closure action [ModelsTable.editRow](Components.ModelsTableRow.html#event_editRow)
       *  * `cancelEditRow` - closure action [ModelsTable.cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow)
       *  * `themeInstance` - bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *  * `isExpanded` - is current row expanded
       *  * `isSelected` - is current row selected
       *  * `isEditRow` - is the row editable (one of the [processedColumns](Components.ModelsTableRow.html#property_isEditRow))
       *  * `isColumnEditable` - is the column currently editable
       *
       * @property component
       * @type string
       * @default ''
       */
      _defineProperty(this, "component", '');
      /**
       * Custom component used in the column's cells when the row is in edit mode
       *
       * It will receive several options:
       *
       *  * `data` - whole dataset passed to the `models-table`
       *  * `record` - current row
       *  * `index` - current row index
       *  * `column` - current column (one of the [processedColumns](Components.ModelsTable.html#property_processedColumns))
       *  * `expandRow` - closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *  * `collapseRow` - closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *  * `expandAllRows` - closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *  * `collapseAllRows` - closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *  * `clickOnRow` - closure action [ModelsTable.clickOnRow](Components.ModelsTable.html#event_clickOnRow)
       *  * `editRow` - closure action [ModelsTableRow.editRow](Components.ModelsTableRow.html#event_editRow)
       *  * `cancelEditRow` - closure action [ModelsTableRow.cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow)
       *  * `themeInstance` - bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *  * `isExpanded` - is current row expanded
       *  * `isSelected` - is current row selected
       *  * `isEditRow` - is the row editable (one of the [processedColumns](Components.ModelsTableRow.html#property_isEditRow))
       *  * `isColumnEditable` - is the column currently editable
       *
       * @property componentForEdit
       * @type string
       * @default ''
       */
      _defineProperty(this, "componentForEdit", '');
      /**
       * Is this column allowed to be editable
       *
       * @property editable
       * @type boolean|function
       * @default true
       */
      _defineProperty(this, "editable", true);
      /**
       * Custom component used in the header cell with filter
       *
       * It will receive several options:
       *
       * * `column` - current column (one of the [processedColumns](Components.ModelsTable.html#property_processedColumns))
       * * `data` - whole dataset passed to the `models-table`
       * * `selectedItems` - bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       * * `expandedItems` - bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       * * `themeInstance` - bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       * * `expandAllRows` - closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       * * `collapseAllRows` - closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       * * `toggleAllSelection` - closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @property componentForFilterCell
       * @type string
       * @default ''
       */
      _defineProperty(this, "componentForFilterCell", '');
      /**
       * Custom component used in the header cell with sorting and column title
       *
       * It will receive several options:
       *
       * * `column` - current column (one of the [processedColumns](Components.ModelsTable.html#property_processedColumns))
       * * `data` - whole dataset passed to the `models-table`
       * * `selectedItems` - bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       * * `expandedItems` - bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       * * `themeInstance` - bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       * * `expandAllRows` - closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       * * `collapseAllRows` - closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       * * `toggleAllSelection` - closure action [ModelsTable.toggleAllSelection](Components.ModelsTable.html#event_toggleAllSelection)
       *
       * @property componentForSortCell
       * @type string
       * @default ''
       */
      _defineProperty(this, "componentForSortCell", '');
      /**
       * Custom component used in the footer cell
       *
       * It will receive several options:
       *
       * * `selectedItems` - bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       * * `expandedItems` - bound from [ModelsTable.expandedItems](Components.ModelsTable.html#property_expandedItems)
       * * `data` - whole dataset passed to the `models-table`
       * * `mappedSelectedItems` - `selectedItems` mapped by `propertyName`
       * * `mappedExpandedItems` - `expandedItems` mapped by `propertyName`
       * * `mappedData` - `data` mapped by `propertyName`
       *
       * @property componentForFooterCell
       * @type string
       * @default ''
       */
      _defineProperty(this, "componentForFooterCell", '');
      /**
       * Colspan for cell in the sorting-row
       *
       * @property colspanForSortCell
       * @type number
       * @default 1
       */
      _defineProperty(this, "colspanForSortCell", 1);
      /**
       * @property realColspanForSortCell
       * @type number
       * @default 1
       * @protected
       */
      _defineProperty(this, "realColspanForSortCell", 1);
      /**
       * Colspan for cell in the filters-row
       *
       * @property colspanForFilterCell
       * @type number
       * @default 1
       */
      _defineProperty(this, "colspanForFilterCell", 1);
      /**
       * @property realColspanForFilterCell
       * @type number
       * @default 1
       * @protected
       */
      _defineProperty(this, "realColspanForFilterCell", 1);
      /**
       * Field-name for sorting by current column. If it isn't provided, `propertyName` is used
       *
       * @property sortedBy
       * @type string
       * @default null
       */
      _defineProperty(this, "sortedBy", null);
      /**
       * The default sorting for this column. Can be either `asc` or `desc`. Needs to be set in conjunction with `sortPrecedence`,
       otherwise it will not have any effect
       *
       * @property sortDirection
       * @type string
       * @default ''
       */
      _defineProperty(this, "sortDirection", '');
      /**
       * Sort precedence for this column - needs to be larger than -1 for sortDirection to take effect
       *
       * @property sortPrecedence
       * @type number
       * @default null
       */
      _defineProperty(this, "sortPrecedence", null);
      /**
       * If sorting should be disabled for this column
       *
       * @property disableSorting
       * @type boolean
       * @default false
       */
      _defineProperty(this, "disableSorting", false);
      /**
       * If filtering should be disabled for this column
       *
       * @property disableFiltering
       * @type boolean
       * @default false
       */
      _defineProperty(this, "disableFiltering", false);
      /**
       * FilterString a default filtering for this column
       *
       * @property filterString
       * @type string
       * @default ''
       */
      _defineProperty(this, "filterString", '');
      /**
       * Custom data's property that is used to filter column. If it isn't provided, `propertyName` is used
       *
       * @property filteredBy
       * @type string
       * @default null
       */
      _defineProperty(this, "filteredBy", null);
      /**
       * Sort direction for column
       *
       * @property sorting
       * @type string
       * @default ''
       */
      _defineProperty(this, "sorting", '');
      /**
       * Is current column hidden by default
       *
       * @property isHidden
       * @type boolean
       * @default false
       */
      _defineProperty(this, "isHidden", false);
      /**
       * Can current column be hidden. This field determines, if column appears in the columns-dropdown. If `mayBeHidden` is `true` and `isHidden` is also `true` for column, this column always be hidden
       *
       * @property mayBeHidden
       * @type boolean
       * @default true
       */
      _defineProperty(this, "mayBeHidden", true);
      /**
       * If `true` select-dropdown will be used for filtering by current column. Options are unique values for `data.@each.${propertyName}`
       *
       * @property filterWithSelect
       * @type boolean
       * @default false
       */
      _defineProperty(this, "filterWithSelect", false);
      /**
       * Should options in the select-box be sorted
       *
       * @property sortFilterOptions
       * @type boolean
       * @default false
       */
      _defineProperty(this, "sortFilterOptions", false);
      /**
       * List of option to the filter-box (used if [filterWithSelect](Utils.ModelsTableColumn.html#property_filterWithSelect) is true)
       *
       * @property predefinedFilterOptions
       * @type string[]|number[]|boolean[]
       * @default null
       */
      _defineProperty(this, "predefinedFilterOptions", null);
      /**
       * Custom class-name for cells in the current column. This class-name will also be added to the header and filter of the column
       *
       * @property className
       * @default ''
       * @type string
       */
      _defineProperty(this, "className", '');
      /**
       * Custom function used to filter rows (used if [filterWithSelect](Utils.ModelsTableColumn.html#property_filterWithSelect) is false)
       *
       * @property filterFunction
       * @type function
       * @default null
       */
      _defineProperty(this, "filterFunction", null);
      /**
       * Optional custom function used to sort rows
       *
       * @property sortFunction
       * @type function
       * @default null
       */
      _defineProperty(this, "sortFunction", null);
      /**
       * Placeholder for filter-input
       *
       * @property filterPlaceholder
       * @type string
       * @default ''
       */
      _defineProperty(this, "filterPlaceholder", '');
      /**
       * If this property is defined, link to the route will be rendered in the cell. [propertyName](Utils.ModelsTableColumn.html#property_propertyName) is used as an anchor. If it's not declared, `id` will be used. <br /> Main idea for `routeName` is to provide a simple way to generate links for each model in the `data`. It should not be used for any other purposes
       *
       * @property routeName
       * @type string
       * @default ''
       */
      _defineProperty(this, "routeName", '');
      /**
       * If this property is defined, link to the route will be rendered in the cell. [routeProperty](Utils.ModelsTableColumn.html#property_routeProperty) is used as an anchor. If it's not declared, `id` will be used. <br /> Main idea for `routeName` is to provide a simple way to generate links for each model in the `data`. It should not be used for any other purposes
       *
       * @property routeProperty
       * @type string
       * @default ''
       */
      _defineProperty(this, "routeProperty", 'id');
      /**
       * Object containing the definition of the column passed into the component
       *
       * @property originalDefinition
       * @type object
       * @default null
       * @protected
       */
      _defineProperty(this, "originalDefinition", null);
      /**
       * @property __mt
       * @type Components.ModelsTable
       * @private
       */
      _defineProperty(this, "__mt", null);
      /**
       * @property data
       * @type object[]
       * @default []
       * @protected
       */
      _initializerDefineProperty(this, "data", _descriptor, this);
      /**
       * @property isVisible
       * @protected
       * @type boolean
       * @default true
       */
      _initializerDefineProperty(this, "isVisible", _descriptor2, this);
      /**
       * @property sortAsc
       * @protected
       * @type boolean
       * @default true
       */
      _initializerDefineProperty(this, "sortAsc", _descriptor3, this);
      /**
       * @property sortDesc
       * @protected
       * @type boolean
       * @default false
       */
      _initializerDefineProperty(this, "sortDesc", _descriptor4, this);
      /**
       * @property filterUsed
       * @protected
       * @type boolean
       * @default false
       */
      _initializerDefineProperty(this, "filterUsed", _descriptor5, this);
    }
    /**
     * @property cssPropertyName
     * @type string
     * @protected
     */
    get cssPropertyName() {
      return this.propertyName.replace(/\./g, '-');
    }
    /**
     * Allow sorting for column or not
     *
     * @property useSorting
     * @protected
     * @type boolean
     * @default true
     */
    get useSorting() {
      return this.sortField && !this.disableSorting;
    }

    /**
     * @property sortField
     * @protected
     * @type string
     */
    get sortField() {
      return this.sortedBy || this.propertyName;
    }

    /**
     * Allow filtering for column or not
     *
     * @protected
     * @property useFilter
     * @type boolean
     * @default true
     */
    get useFilter() {
      return this.filterField && !this.disableFiltering;
    }

    /**
     * @protected
     * @property filterField
     * @type string
     */
    get filterField() {
      return this.filteredBy || this.propertyName;
    }

    /**
     * If preselected option doesn't exist after `filterOptions` update,
     * `filterString` is reverted to empty string (basically, filtering for this column is dropped)
     *
     * @method cleanFilterString
     * @protected
     */
    cleanFilterString() {
      const {
        filterOptions,
        filterWithSelect,
        filterString
      } = this;
      if (!filterWithSelect || Ember.isEmpty(filterOptions)) {
        return;
      }
      const filterOptionExists = Ember.A(filterOptions).find(option => {
        const value = Ember.get(option, 'value');
        return [value, '' + value].indexOf(filterString) !== -1;
      });
      if (!filterOptionExists) {
        Ember.set(this, 'filterString', '');
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "data", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cssPropertyName", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "cssPropertyName"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isVisible", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sortAsc", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sortDesc", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterUsed", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "useSorting", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "useSorting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortField", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "sortField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "useFilter", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "useFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterField", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "filterField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cleanFilterString", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "cleanFilterString"), _class.prototype)), _class));
});