define("ember-async-data/index", ["exports", "ember-async-data/tracked-async-data", "ember-async-data/helpers/load"], function (_exports, _trackedAsyncData, _load) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "TrackedAsyncData", {
    enumerable: true,
    get: function () {
      return _trackedAsyncData.default;
    }
  });
  Object.defineProperty(_exports, "load", {
    enumerable: true,
    get: function () {
      return _load.load;
    }
  });
});