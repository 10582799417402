define("ember-changeset/utils/is-leaf", ["exports", "validated-changeset"], function (_exports, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isLeafInChanges = isLeafInChanges;
  function isLeafInChanges(key, changes) {
    return Ember.get(changes, key) && Ember.get(changes, key) instanceof _validatedChangeset.Change;
  }
});