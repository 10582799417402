define("ember-models-table/themes/bootstrap3", ["exports", "ember-models-table/themes/default"], function (_exports, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @class Bootstrap3Theme
   * @namespace Themes
   * @extends Themes.DefaultTheme
   */
  class Bootstrap3Theme extends _default.default {
    constructor(...args) {
      super(...args);
      /**
       * @property rowSelectAllCheckboxComponent
       * @type string
       * @default 'models-table/themes/bootstrap3/row-select-all-checkbox'
       */
      /**
       * @property componentsPath
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/'
       */
      _defineProperty(this, "componentsPath", 'models-table/themes/bootstrap3/');
      /**
       * @property table
       * @type string
       * @default 'table table-striped table-bordered table-condensed'
       */
      _defineProperty(this, "table", 'table table-striped table-bordered table-condensed');
      /**
       * @property buttonsGroup
       * @type string
       * @default 'btn-group'
       */
      _defineProperty(this, "buttonsGroup", 'btn-group');
      /**
       * @property globalFilterWrapper
       * @type string
       * @default 'pull-left'
       */
      _defineProperty(this, "globalFilterWrapper", 'pull-left');
      /**
       * @property sortGroupedPropertyBtn
       * @type string
       * @default 'btn btn-default'
       */
      _defineProperty(this, "sortGroupedPropertyBtn", 'btn btn-default');
      /**
       * @property columnsDropdownWrapper
       * @type string
       * @default 'pull-right columns-dropdown'
       */
      _defineProperty(this, "columnsDropdownWrapper", 'pull-right columns-dropdown');
      /**
       * @property columnsDropdown
       * @type string
       * @default 'dropdown-menu pull-right'
       */
      _defineProperty(this, "columnsDropdown", 'dropdown-menu pull-right');
      /**
       * @property columnsDropdownDivider
       * @type string
       * @default 'divider'
       */
      _defineProperty(this, "columnsDropdownDivider", 'divider');
      /**
       * @property dataGroupBySelectWrapper
       * @type string
       * @default 'pull-left'
       */
      _defineProperty(this, "dataGroupBySelectWrapper", 'data-group-by-wrapper pull-left');
      /**
       * @property footerSummaryNumericPagination
       * @type string
       * @default 'col-md-4 col-sm-4 col-xs-4'
       */
      _defineProperty(this, "footerSummaryNumericPagination", 'col-md-4 col-sm-4 col-xs-4');
      /**
       * @property footerSummaryDefaultPagination
       * @type string
       * @default 'col-md-5 col-sm-5 col-xs-5'
       */
      _defineProperty(this, "footerSummaryDefaultPagination", 'col-md-5 col-sm-5 col-xs-5');
      /**
       * @property pageSizeWrapper
       * @type string
       * @default 'col-md-2 col-sm-2 col-xs-2'
       */
      _defineProperty(this, "pageSizeWrapper", 'col-md-2 col-sm-2 col-xs-2');
      /**
       * @property pageSizeSelectWrapper
       * @type string
       * @default 'pull-right'
       */
      _defineProperty(this, "pageSizeSelectWrapper", 'pull-left');
      /**
       * @property currentPageSizeSelectWrapper
       * @type string
       * @default 'pull-right'
       */
      _defineProperty(this, "currentPageSizeSelectWrapper", 'pull-right');
      /**
       * @property paginationInternalWrapper
       * @type string
       * @default 'btn-toolbar pull-right'
       */
      _defineProperty(this, "paginationInternalWrapper", 'btn-toolbar pull-right');
      /**
       * @property paginationWrapperNumeric
       * @type string
       * @default 'col-md-6 col-sm-6 col-xs-6'
       */
      _defineProperty(this, "paginationWrapperNumeric", 'col-md-6 col-sm-6 col-xs-6');
      /**
       * @property paginationWrapperDefault
       * @type string
       * @default 'col-md-5 col-sm-5 col-xs-5'
       */
      _defineProperty(this, "paginationWrapperDefault", 'col-md-5 col-sm-5 col-xs-5');
      /**
       * @property paginationBlock
       * @type string
       * @default 'btn-group'
       */
      _defineProperty(this, "paginationBlock", 'btn-group');
      /**
       * @property paginationNumericItemActive
       * @type string
       * @default 'active'
       */
      _defineProperty(this, "paginationNumericItemActive", 'active');
      /**
       * @property buttonDefault
       * @type string
       * @default 'btn btn-default'
       */
      _defineProperty(this, "buttonDefault", 'btn btn-default');
      /**
       * @property buttonLink
       * @type string
       * @default 'btn btn-link'
       */
      _defineProperty(this, "buttonLink", 'btn btn-link');
      /**
       * @property form
       * @type string
       * @default 'form-inline'
       */
      _defineProperty(this, "form", 'form-inline');
      /**
       * @property formElementWrapper
       * @type string
       * @default 'form-group'
       */
      _defineProperty(this, "formElementWrapper", 'form-group');
      /**
       * @property input
       * @type string
       * @default 'form-control'
       */
      _defineProperty(this, "input", 'form-control');
      /**
       * @property select
       * @type string
       * @default ''
       */
      _defineProperty(this, "select", '');
      /**
       * @property tfooterWrapper
       * @type string
       * @default 'table-footer clearfix'
       */
      _defineProperty(this, "tfooterWrapper", 'table-footer clearfix');
      /**
       * @property tfooterInternalWrapper
       * @type string
       * @default 'row'
       */
      _defineProperty(this, "tfooterInternalWrapper", 'row');
      /**
       * @property clearFilterIcon
       * @type string
       * @default 'glyphicon glyphicon-remove-sign form-control-feedback'
       */
      _defineProperty(this, "clearFilterIcon", 'glyphicon glyphicon-remove-sign form-control-feedback');
      /**
       * @property clearAllFiltersIcon
       * @type string
       * @default 'glyphicon glyphicon-remove-circle'
       */
      _defineProperty(this, "clearAllFiltersIcon", 'glyphicon glyphicon-remove-circle');
      /**
       * @property sortAscIcon
       * @type string
       * @default 'glyphicon glyphicon-triangle-top'
       */
      _defineProperty(this, "sortAscIcon", 'glyphicon glyphicon-triangle-top');
      /**
       * @property sortDescIcon
       * @type string
       * @default 'glyphicon glyphicon-triangle-bottom'
       */
      _defineProperty(this, "sortDescIcon", 'glyphicon glyphicon-triangle-bottom');
      /**
       * @property columnVisibleIcon
       * @type string
       * @default 'glyphicon glyphicon-check'
       */
      _defineProperty(this, "columnVisibleIcon", 'glyphicon glyphicon-check');
      /**
       * @property columnHiddenIcon
       * @type string
       * @default 'glyphicon glyphicon-unchecked'
       */
      _defineProperty(this, "columnHiddenIcon", 'glyphicon glyphicon-unchecked');
      /**
       * @property navFirstIcon
       * @type string
       * @default 'glyphicon glyphicon-chevron-left'
       */
      _defineProperty(this, "navFirstIcon", 'glyphicon glyphicon-chevron-left');
      /**
       * @property navPrevIcon
       * @type string
       * @default 'glyphicon glyphicon-menu-left'
       */
      _defineProperty(this, "navPrevIcon", 'glyphicon glyphicon-menu-left');
      /**
       * @property navNextIcon
       * @type string
       * @default 'glyphicon glyphicon-menu-right'
       */
      _defineProperty(this, "navNextIcon", 'glyphicon glyphicon-menu-right');
      /**
       * @property navLastIcon
       * @type string
       * @default 'glyphicon glyphicon-chevron-right'
       */
      _defineProperty(this, "navLastIcon", 'glyphicon glyphicon-chevron-right');
      /**
       * @property caretIcon
       * @type string
       * @default 'caretIcon'
       */
      _defineProperty(this, "caretIcon", 'caret');
      /**
       * @property expandRowIcon
       * @type string
       * @default 'glyphicon glyphicon-plus'
       */
      _defineProperty(this, "expandRowIcon", 'glyphicon glyphicon-plus');
      /**
       * @property expandAllRowsIcon
       * @type string
       * @default 'glyphicon glyphicon-plus'
       */
      _defineProperty(this, "expandAllRowsIcon", 'glyphicon glyphicon-plus');
      /**
       * @property collapseRowIcon
       * @type string
       * @default 'glyphicon glyphicon-minus'
       */
      _defineProperty(this, "collapseRowIcon", 'glyphicon glyphicon-minus');
      /**
       * @property collapseAllRowsIcon
       * @type string
       * @default 'glyphicon glyphicon-plus'
       */
      _defineProperty(this, "collapseAllRowsIcon", 'glyphicon glyphicon-minus');
      /**
       * @property selectSomeRowsIcon
       * @type string
       * @default 'glyphicon glyphicon-unchecked glyphicon-minus'
       */
      _defineProperty(this, "selectSomeRowsIcon", 'glyphicon glyphicon-minus');
      /**
       * @property selectAllRowsIcon
       * @type string
       * @default 'glyphicon glyphicon-check'
       */
      _defineProperty(this, "selectAllRowsIcon", 'glyphicon glyphicon-check');
      /**
       * @property deselectAllRowsIcon
       * @type string
       * @default 'glyphicon glyphicon-unchecked'
       */
      _defineProperty(this, "deselectAllRowsIcon", 'glyphicon glyphicon-unchecked');
      /**
       * @property selectRowIcon
       * @type string
       * @default 'glyphicon glyphicon-check'
       */
      _defineProperty(this, "selectRowIcon", 'glyphicon glyphicon-check');
      /**
       * @property deselectRowIcon
       * @type string
       * @default 'glyphicon glyphicon-unchecked'
       */
      _defineProperty(this, "deselectRowIcon", 'glyphicon glyphicon-unchecked');
      /**
       * @property editRowButton
       * @type string
       * @default 'btn btn-default'
       */
      _defineProperty(this, "editRowButton", 'btn btn-default');
      /**
       * @property saveRowButton
       * @type string
       * @default 'btn btn-default'
       */
      _defineProperty(this, "saveRowButton", 'btn btn-default');
      /**
       * @property cancelRowButton
       * @type string
       * @default 'btn btn-default'
       */
      _defineProperty(this, "cancelRowButton", 'btn btn-default');
    }
  }
  _exports.default = Bootstrap3Theme;
});