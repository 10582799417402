define("ember-models-table/templates/components/models-table/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "599a400v",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"option\"],[15,2,[32,1,[\"value\"]]],[15,\"selected\",[30,[36,0],[[32,0,[\"value\"]],[32,1,[\"value\"]],[32,0,[\"type\"]]],null]],[12],[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/select.hbs"
  });
});