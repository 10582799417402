define("sgb-object-graphs/components/sgb-ajax-base-chart", ["exports", "@ember-decorators/component", "@ember-decorators/object", "sgb-object-graphs/templates/components/sgb-ajax-base-chart", "ember-concurrency"], function (_exports, _component, _object, _sgbAjaxBaseChart, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SgbAjaxBaseChart = (_dec = (0, _component.layout)(_sgbAjaxBaseChart.default), _dec2 = (0, _component.classNames)('sgb-chart'), _dec3 = Ember.inject.service, _dec4 = Ember.computed('_fontColor', 'css_selector_to_copy_color_from', 'restyleIfChanged', 'this.css_selector_to_copy_color_from'), _dec5 = Ember.computed('chartInfo.componentName', 'chartinfo_component_name_override'), _dec6 = Ember.computed('loadDataStart', 'loadDataEnd'), _dec7 = (0, _object.observes)('refreshRequestDate'), _dec8 = (0, _object.observes)('url', 'shouldUpdateGraphData'), _dec9 = Ember.computed('chartInfo.url', 'chartinfo_url_override'), _dec10 = Ember.computed('chartInfo.refreshUrl', 'chartinfo_url_override'), classic(_class = _dec(_class = _dec2(_class = (_class2 = class SgbAjaxBaseChart extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _defineProperty(this, "_fontColor", null);

      _defineProperty(this, "messages", null);

      _defineProperty(this, "refreshRate", 60 * 3);
    }

    get fontColor() {
      let color = this._fontColor || '#c1c6d5';

      if (!color) {
        let css_selector_to_copy_color_from = this.css_selector_to_copy_color_from;

        if (css_selector_to_copy_color_from) {
          let selector = document.querySelector(css_selector_to_copy_color_from);

          if (selector) {
            let computedStyle = window.getComputedStyle(selector, null);

            if (computedStyle) {
              color = computedStyle.getPropertyValue('color');
            }
          } // color = window.getComputedStyle(document.querySelector(css_selector_to_copy_color_from), null).getPropertyValue('color');

        }
      }

      return color;
    }

    set fontColor(value) {
      this.set('_fontColor', value);
      return value;
    }

    thereIsNoDataBecause(reason_for_no_data) {
      this.set('statusMessage', reason_for_no_data);
      this.set('openTsdbData', null);
    }

    receivedTsdbData(data) {
      this.set('statusMessage', null);
      this.set('openTsdbData', data);
    }

    get chartComponentName() {
      let res = this.chartinfo_component_name_override || this.get('chartInfo.componentName');
      return res;
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.updateFromHash(this.options, null, true);
    }

    init() {
      super.init(...arguments);
      this.set('messages', {
        shouldnt_get_data: 'should not get data - bad incoming parameters',
        loading: 'loading data....',
        error_getting_data: 'Error while retrieving data'
      });
      this.set('hashAndDirectParams', new HashAndDirectParams({
        get: (obj, name) => obj.get(name),
        set: (obj, name, value) => obj.set(name, value),
        obj: this
      }));
      let defaults = {
        start_hours_ago: 12,
        end_hours_ago: 0,
        saveAsPNG: true,
        showInterval: true
      };
      this.updateFromHash(this.options, this.whenUndefined, true);
      this.updateFromHash(defaults, this.whenUndefined, false);
      this.updateGraphData();
    }

    updateFromHash(hash, predicate, undo) {
      this.hashAndDirectParams.fromHash(hash, {
        predicate: predicate,
        undo: undo
      });
    }

    whenUndefined(obj, name) {
      return obj.get(name) == undefined;
    }

    updateIf(propertyName, value, shouldUpdateWhenCurrentIs) {
      let currentValue = this.get(propertyName);

      if (shouldUpdateWhenCurrentIs(currentValue)) {
        this.set(propertyName, value);
      }
    }

    didRender() {
      super.didRender(...arguments);
      this.set('chartCanvas', this.element.querySelector('canvas'));
    }

    get canUserRefresh() {
      const start = this.loadDataStart;
      const end = this.loadDataEnd;
      return start && end && start <= end;
    }

    userRefresh() {
      if (this.canUserRefresh) {
        this.updateGraphData();
      }
    }

    handleObjectChanged() {
      let lastLoadedUrl = this.lastLoadedUrl;
      let url = this.url;

      if (lastLoadedUrl != url) {
        // TODO: probably should check elapsed time since last check too?
        this.updateGraphData();
      }
    }

    updateGraphData() {
      Ember.run.debounce(this, this._updateGraphData, 100);
    }

    get url() {
      return this.chartinfo_url_override ?? this.chartInfo.url;
    }

    get refreshUrl() {
      return this.chartinfo_url_override ?? this.chartInfo.refreshUrl;
    }

    getHumanizedErrorMessage(raw_error_msg) {
      const there_is_no_data = 'There is no data for the selected period';
      const pattern2human = [[/No such name for 'tagv': '[^']+'/, there_is_no_data], ['No data returned. Empty data array. Ensure all query parameters are correct.', there_is_no_data]];

      if (raw_error_msg == null) {
        return null;
      }

      let translated = pattern2human.filter(_ref => {
        let [pattern, human_msg] = _ref;
        return raw_error_msg.match(pattern);
      }); // eslint-disable-line no-unused-vars

      if (translated.length != 0) {
        return translated[0][1];
      } else {
        return null;
      }
    }

    _updateGraphData() {
      this.refreshTask.cancelAll();

      if (!this.shouldUpdateGraphData) {
        this.thereIsNoDataBecause(this.messages.shouldnt_get_data);
        return;
      }

      let url = this.url;
      this.set('loadDataStart', new Date());
      this.thereIsNoDataBecause(this.messages.loading); // TODO: this will cause issues when we refresh

      this.ajax.request(url).then(data => {
        this.set('loadDataEnd', new Date());

        if (data.error) {
          throw new Error(data.error.message);
        } // TODO: handle the case when there is data, but no datapoints in dps


        this.receivedTsdbData(data);
        this.set('lastLoadedUrl', url);
        this.performRefreshTask();
      }).catch(err => {
        let payload_error_msg = err.payload ? err.payload.message : '';
        let error_msg = err.message;
        let fallback_error_message = this.messages.error_getting_data;
        let human_messages = [payload_error_msg, error_msg].map(msg => this.getHumanizedErrorMessage(msg)).filter(x => x != null);
        let error_msg_to_show = human_messages.concat([fallback_error_message])[0];
        this.thereIsNoDataBecause(error_msg_to_show);
        this.set('loadDataEnd', new Date());
        console.error('Chart update', err); // eslint-disable-line no-console
      });
    }

    performRefreshTask() {
      this.refreshTask.cancelAll();
      this.refreshTask.perform();
    } // refresh every 3 minutes


    *refreshTask() {
      let counter = 0;

      while (1) {
        console.log('refreshTask: chart');
        yield (0, _emberConcurrency.timeout)(1000 * this.refreshRate);
        this.refreshGraphData(counter++);
      }
    }

    refreshGraphData(counter) {
      // console.log('refreshTask', this.refreshUrl);
      if (this.shouldUpdateGraphData && this.refreshUrl) {
        const url = this.refreshUrl;
        this.ajax.request(url).then(data => {
          if (data.error) {
            throw new Error(data.error.message);
          } // console.log('refreshTask data', data);


          const oldData = this.openTsdbData;

          if (!Array.isArray(oldData) || !Array.isArray(data)) {
            return;
          } // add some new data - test

          /*
                  const tmpdps = this.openTsdbData?.[0]?.dps ?? [];
                  const last = tmpdps[tmpdps.length - 1]?.[0] ?? 0;
                  const now = new Date(last).getTime()  + 1000 * 3600;
                  const value = counter;
                   for (const d of data) {
                    if (d?.dps) {
                      d?.dps.push([now, value]);
                    }
                  }
          */


          let changed = false;

          for (const od of oldData) {
            for (const nd of data) {
              if (od.metric == nd.metric && this.shallowEqual(od.tags, nd.tags)) {
                if (od?.dps?.length && nd?.dps?.length) {
                  const [odMaxTs] = od.dps[od.dps.length - 1];

                  for (const ndp of nd.dps) {
                    const [newTs, value] = ndp;

                    if (newTs > odMaxTs) {
                      od.dps.push([newTs, value]);
                      changed = true;
                    }
                  }
                }
              }
            }
          }

          if (changed) {
            // refreshes with redraw
            this.receivedTsdbData(JSON.parse(JSON.stringify(oldData)));
          }
        }).catch(err => {
          console.error('Chart refresh', err); // eslint-disable-line no-console
        });
        ;
      }
    }

    shallowEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }

      return true;
    }

    willDestroyElement() {
      this._super(...arguments);

      this.refreshTask.cancelAll();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "fontColor", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "fontColor"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chartComponentName", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "chartComponentName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "canUserRefresh", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "canUserRefresh"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "userRefresh", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "userRefresh"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleObjectChanged", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleObjectChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "url", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "url"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "refreshUrl", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshUrl"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "refreshTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class2.prototype, "refreshTask"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = SgbAjaxBaseChart;
});