define("ember-models-table/components/models-table/themes/ember-bootstrap-v3/summary", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/summary", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/summary"], function (_exports, _component, _summary, _summary2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   * @class Ebs3ModelsTableSummary
   * @namespace Components
   * @extends Components.ModelsTableSummary
   */
  let Ebs3ModelsTableSummaryComponent = _exports.default = (_dec = (0, _component.layout)(_summary2.default), _dec(_class = class Ebs3ModelsTableSummaryComponent extends _summary.default {}) || _class);
});