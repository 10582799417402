define("sgb-object-graphs/templates/components/sgb-ajax-base-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.title}}
    <h4>{{this.title}}</h4>
  {{/if}}
  {{#if @openTsdbData}}
    {{#if @saveAsPNG}}
      <SaveCanvasAsPng @canvas={{this.chartCanvas}} @fileName={{this.fileName}} />
    {{/if}}
    {{#if @showInterval}}
      {{humanize-interval @start_hours_ago @end_hours_ago}}
    {{/if}}
  {{/if}}
  {{component this.chartComponentName
    aggregateLevel=this.aggregateLevel
    fontColor=this.fontColor
    heightVh=this.heightVh
    metrics=this.metrics
    nrOfXAxisLabels=this.nrOfXAxisLabels
    temperatureUnit=this.temperatureUnit
    objectPayload=this.objectPayload
    objectTree=this.objectTree
    onlyShowDataLines=this.onlyShowDataLines
    openTsdbData=this.openTsdbData
    overlayText=this.overlayText
    statusMessage=this.statusMessage
    timeFormat=this.timeFormat
    title=this.title
    useMinimalSpaceForChart=this.useMinimalSpaceForChart
  }}
  {{yield}}
  
  */
  {
    "id": "y/Yi3lYs",
    "block": "{\"symbols\":[\"@end_hours_ago\",\"@start_hours_ago\",\"@saveAsPNG\",\"@showInterval\",\"@openTsdbData\",\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"title\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h4\"],[12],[1,[32,0,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,5]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"save-canvas-as-png\",[],[[\"@canvas\",\"@fileName\"],[[32,0,[\"chartCanvas\"]],[32,0,[\"fileName\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,2],[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,[30,[36,2],[[32,0,[\"chartComponentName\"]]],[[\"aggregateLevel\",\"fontColor\",\"heightVh\",\"metrics\",\"nrOfXAxisLabels\",\"temperatureUnit\",\"objectPayload\",\"objectTree\",\"onlyShowDataLines\",\"openTsdbData\",\"overlayText\",\"statusMessage\",\"timeFormat\",\"title\",\"useMinimalSpaceForChart\"],[[32,0,[\"aggregateLevel\"]],[32,0,[\"fontColor\"]],[32,0,[\"heightVh\"]],[32,0,[\"metrics\"]],[32,0,[\"nrOfXAxisLabels\"]],[32,0,[\"temperatureUnit\"]],[32,0,[\"objectPayload\"]],[32,0,[\"objectTree\"]],[32,0,[\"onlyShowDataLines\"]],[32,0,[\"openTsdbData\"]],[32,0,[\"overlayText\"]],[32,0,[\"statusMessage\"]],[32,0,[\"timeFormat\"]],[32,0,[\"title\"]],[32,0,[\"useMinimalSpaceForChart\"]]]]]],[2,\"\\n\"],[18,6,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"humanize-interval\",\"if\",\"component\"]}",
    "moduleName": "sgb-object-graphs/templates/components/sgb-ajax-base-chart.hbs"
  });

  _exports.default = _default;
});