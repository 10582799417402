define("sgb-object-graphs/components/sgb-chart-refresh-widget", ["exports", "@ember-decorators/component", "sgb-object-graphs/templates/components/sgb-chart-refresh-widget"], function (_exports, _component, _sgbChartRefreshWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SgbChartRefreshWidget = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_sgbChartRefreshWidget.default), _dec3 = Ember._action, classic(_class = _dec(_class = _dec2(_class = (_class2 = class SgbChartRefreshWidget extends Ember.Component {
    refresh() {
      //  TODO: somehow we should be able to display when the dashboard was loaded
      this.set('refreshRequestDate', new Date());
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "refresh", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "refresh"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = SgbChartRefreshWidget;
});