define("sgb-object-graphs/templates/components/sgb-base-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.shouldShowChart}}
    {{!--
    <!-- TODO: uncomment to show the below pres, and we'll see the ever vertically growing canvas -->
    <pre>
      {{openTsdbDataJsonString}}
    </pre>
    <a href="https://jsfiddle.net/Renison/g0tpfL8j/">here is a chartjs fiddle where you can check if the config is sane</a>
    <pre>
    {{chartjsOptionsJsonString}}
    </pre>
    --}}
    {{component this.chartComponentName
      config=this.chartjsOptions
      fontColor=this.fontColor
      heightVh=this.heightVh
      metrics=this.metrics
      nrOfXAxisLabels=this.nrOfXAxisLabels
      onlyShowDataLines=this.onlyShowDataLines
      openTsdbData=this.openTsdbData
      overlayText=this.overlayText
      temperatureUnit=this.temperatureUnit
      timeFormat=this.timeFormat
      title=this.title
      useMinimalSpaceForChart=this.useMinimalSpaceForChart
    }}
    {{this.statusMessage}}
  {{else}}
    {{#if this.statusMessage}}
      {{this.statusMessage}}
    {{else}}
      Sorry, couldn't convert recieved data to a chart.
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "i5AN7tuI",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"shouldShowChart\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[32,0,[\"chartComponentName\"]]],[[\"config\",\"fontColor\",\"heightVh\",\"metrics\",\"nrOfXAxisLabels\",\"onlyShowDataLines\",\"openTsdbData\",\"overlayText\",\"temperatureUnit\",\"timeFormat\",\"title\",\"useMinimalSpaceForChart\"],[[32,0,[\"chartjsOptions\"]],[32,0,[\"fontColor\"]],[32,0,[\"heightVh\"]],[32,0,[\"metrics\"]],[32,0,[\"nrOfXAxisLabels\"]],[32,0,[\"onlyShowDataLines\"]],[32,0,[\"openTsdbData\"]],[32,0,[\"overlayText\"]],[32,0,[\"temperatureUnit\"]],[32,0,[\"timeFormat\"]],[32,0,[\"title\"]],[32,0,[\"useMinimalSpaceForChart\"]]]]]],[2,\"\\n  \"],[1,[32,0,[\"statusMessage\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"statusMessage\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"statusMessage\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Sorry, couldn't convert recieved data to a chart.\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\"]}",
    "moduleName": "sgb-object-graphs/templates/components/sgb-base-chart.hbs"
  });

  _exports.default = _default;
});