define("ember-models-table/templates/components/models-table/cell-column-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "i+up7tOd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"minSelected\",\"maxSelected\",\"sumSelected\",\"avgSelected\",\"medianSelected\",\"minData\",\"maxData\",\"sumData\",\"avgData\",\"medianData\"],[[32,0,[\"minSelected\"]],[32,0,[\"maxSelected\"]],[32,0,[\"sumSelected\"]],[32,0,[\"avgSelected\"]],[32,0,[\"medianSelected\"]],[32,0,[\"minData\"]],[32,0,[\"maxData\"]],[32,0,[\"sumData\"]],[32,0,[\"avgData\"]],[32,0,[\"medianData\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/cell-column-summary.hbs"
  });
});