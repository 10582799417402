define("ember-debug-logger/utils/debug-logger", ["exports", "debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = debugLogger;
  _exports.instanceLogger = instanceLogger;
  /**
   * Creates a function for debug logging, keyed by either an explicitly-given
   * namespace or the container key of the object it's attached to.
   *
   * Logging can then be enabled during debugging based on globbing of keys.
   * For instance, to enable logging for all routes, you could enter this in the
   * developer console:
   *   debug.enable('route:*');
   *
   * Or, to enable all logging:
   *   debug.enable('*');
   *
   * Logging preferences are persisted in local storage, and you'll need to reload
   * the page for changes to take effect.
   */
  function debugLogger(key) {
    return key ? (0, _debug.default)(key) : instanceLogger;
  }
  function instanceLogger() {
    let host = this;
    let logger = host && host._debugLoggerInstance;
    if (!logger) {
      const loggerKey = host && host._debugContainerKey;
      if (!loggerKey) {
        throw new Error('On non-container-managed objects, debug-logger requires an explicit key.');
      }
      logger = (0, _debug.default)(loggerKey);
      Object.defineProperty(this, '_debugLoggerInstance', {
        value: logger
      });
    }
    return logger.apply(this, arguments);
  }
  // For backwards compatibility and convenience in development, expose
  // `debug` as a global to make e.g. `debug.enable('*')` easy.
  // @ts-ignore
  window.debug = _debug.default;
});