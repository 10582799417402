define("ember-models-table/components/models-table/cell-edit-toggle", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/cell-edit-toggle"], function (_exports, _component, _cellEditToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Component for cells used as toggle for edit-mode.
   *
   * Properties and event-handlers from [models-table/cell](Components.ModelsTableCell.html) are bound here
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable
   *   @data={{data}}
   *   @columns={{columns}}
   *   @columnComponents={{hash
   *     editRow=(component
   *       "models-table/cell-edit-toggle"
   *       saveRowAction=(action "onSaveRow")
   *       cancelRowAction=(action "onCancelRow")
   *   )}}
   * />
   * ```
   *
   * ```js
   * import Controller from '@ember/controller';
   * import {action} from '@ember/object';
   *
   * export default class InLineEditController extends Controller {
   *
   *   data = [];
   *
   *   columns = [
   *     {propertyName: 'firstName'},
   *     {propertyName: 'lastName'},
   *     {
   *       title: 'Edit',
   *       component: 'editRow',
   *       editable: false // <--- IMPORTANT
   *     }
   *   ];
   *
   *   @action
   *   onSaveRow(param) {
   *     return param.record.save();
   *   }
   *
   *   @action
   *   onCancelRow({record}) {
   *     record.rollbackAttributes();
   *     return true;
   *   }
   * }
   * ```
   *
   * @namespace Components
   * @class ModelsTableCellEditToggle
   * @extends Ember.Component
   */
  let CellEditToggleComponent = _exports.default = (_dec = (0, _component.layout)(_cellEditToggle.default), _dec2 = Ember.computed.alias('themeInstance.editRowButtonLabelMsg'), _dec3 = Ember.computed.alias('themeInstance.cancelRowButtonLabelMsg'), _dec4 = Ember.computed.alias('themeInstance.saveRowButtonLabelMsg'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = class CellEditToggleComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * Closure action sent on Edit Button being clicked
       *
       * Action will send one parameter - object with fields:
       *
       * * `record` - The record to be edited
       *
       * @event editRowAction
       * @return Must return a truthy value to allow the row to enter the Edit state. May return a Promise.
       */
      _defineProperty(this, "editRowAction", null);
      /**
       * Closure action sent on Save Button being clicked
       *
       * Action will send one parameter - object with fields:
       *
       * * `record` - The record to be saved
       *
       * @event saveRowAction
       * @return Must return a truthy value to allow the row to exit the Edit state. May return a Promise.
       */
      _defineProperty(this, "saveRowAction", null);
      /**
       * Closure action sent on Cancel Button being clicked
       *
       * Action will send one parameter - object with fields:
       *
       * * `record` - The record currently being edited
       *
       * @event cancelRowAction
       * @return Must return a truthy value to allow the row to exit the Edit state. May return a Promise.
       */
      _defineProperty(this, "cancelRowAction", null);
      /**
       * One of the [data](Components.ModelsTable.html#property_data)
       *
       * @default null
       * @property record
       * @type object
       */
      _defineProperty(this, "record", null);
      /**
       * Row's index where current cell is
       *
       * @property index
       * @default null
       * @type number
       */
      _defineProperty(this, "index", null);
      /**
       * @property column
       * @default null
       * @type Utils.ModelsTableColumn
       */
      _defineProperty(this, "column", null);
      /**
       * @property isEditRow
       * @default null
       * @protected
       * @type boolean
       */
      _defineProperty(this, "isEditRow", null);
      /**
       * @property groupedLength
       * @type number
       * @default null
       */
      _defineProperty(this, "groupedLength", null);
      /**
       * Closure action [ModelsTable.expandRow](Components.ModelsTable.html#event_expandRow)
       *
       * @event expandRow
       */
      _defineProperty(this, "expandRow", null);
      /**
       * Closure action [ModelsTable.collapseRow](Components.ModelsTable.html#event_collapseRow)
       *
       * @event collapseRow
       */
      _defineProperty(this, "collapseRow", null);
      /**
       * Closure action [ModelsTable.expandAllRows](Components.ModelsTable.html#event_expandAllRows)
       *
       * @event expandAllRows
       */
      _defineProperty(this, "expandAllRows", null);
      /**
       * Closure action [ModelsTable.collapseAllRows](Components.ModelsTable.html#event_collapseAllRows)
       *
       * @event collapseAllRows
       */
      _defineProperty(this, "collapseAllRows", null);
      /**
       * Closure action [ModelsTableRow.editRow](Components.ModelsTableRow.html#event_editRow)
       *
       * @event editRow
       */
      _defineProperty(this, "editRow", null);
      /**
       * Closure action [ModelsTableRow.saveRow](Components.ModelsTableRow.html#event_saveRow)
       *
       * @event saveRow
       */
      _defineProperty(this, "saveRow", null);
      /**
       * Closure action [ModelsTableRow.cancelEditRow](Components.ModelsTableRow.html#event_cancelEditRow)
       *
       * @event cancelEditRow
       */
      _defineProperty(this, "cancelEditRow", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Is current row expanded or not
       *
       * @default null
       * @property isExpanded
       * @type boolean
       */
      _defineProperty(this, "isExpanded", null);
      /**
       * Is current row selected or not
       *
       * @default null
       * @property isSelected
       * @type boolean
       */
      _defineProperty(this, "isSelected", null);
      /**
       * @property isColumnEditable
       * @type boolean
       * @default false
       */
      _defineProperty(this, "isColumnEditable", false);
      /**
       * The label for the Edit Button
       *
       * @property editButtonLabel
       * @type string
       * @default themeInstance.editRowButtonLabelMsg
       * @protected
       */
      _initializerDefineProperty(this, "editButtonLabel", _descriptor, this);
      /**
       * The label for the Cancel Button
       *
       * @property cancelButtonLabel
       * @type string
       * @default themeInstance.cancelRowButtonLabelMsg
       * @protected
       */
      _initializerDefineProperty(this, "cancelButtonLabel", _descriptor2, this);
      /**
       * The label for the Save Button
       *
       * @property saveButtonLabel
       * @type string
       * @default themeInstance.saveRowButtonLabelMsg
       * @protected
       */
      _initializerDefineProperty(this, "saveButtonLabel", _descriptor3, this);
    }
    click(event) {
      event.stopPropagation();
    }

    /**
     * Fires when "Save" button is clicked. Edit-mode for row is turned off
     *
     * @event saveClicked
     * @protected
     */
    saveClicked() {
      let actionResult = true;
      if (this.saveRowAction) {
        actionResult = this.saveRowAction({
          record: this.record
        });
      }
      Ember.RSVP.resolve(actionResult).then(result => {
        if (result) {
          this.saveRow();
        }
      });
    }

    /**
     * Fires when "Edit" button is clicked. Edit-mode for row is turned on
     *
     * @event editClicked
     * @protected
     */
    editClicked() {
      let actionResult = true;
      if (this.editRowAction) {
        actionResult = this.editRowAction({
          record: this.record
        });
      }
      Ember.RSVP.resolve(actionResult).then(result => {
        if (result) {
          this.editRow();
        }
      });
    }

    /**
     * Fires when "Cancel Edit" button is clicked. Edit-mode for row is turned off
     *
     * @event cancelClicked
     * @protected
     */
    cancelClicked() {
      let actionResult = true;
      if (this.cancelRowAction) {
        actionResult = this.cancelRowAction({
          record: this.record
        });
      }
      Ember.RSVP.resolve(actionResult).then(result => {
        if (result) {
          this.cancelEditRow();
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "editButtonLabel", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "cancelButtonLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "saveButtonLabel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "saveClicked", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "saveClicked"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editClicked", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "editClicked"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "cancelClicked", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "cancelClicked"), _class2.prototype)), _class2)) || _class);
});