define("sgb-object-graphs/templates/components/sgb-ajax-singlestat-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <h4>{{this.title}}</h4>
  {{#if this.hasValue}}
    <h4 class="sgb-ajax-singlestat-number-number">{{this.value}}</h4>
  {{else}}
    {{this.statusMessage}}
  {{/if}}
  
  
  */
  {
    "id": "d7Dv+Kqk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[1,[32,0,[\"title\"]]],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"hasValue\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"h4\"],[14,0,\"sgb-ajax-singlestat-number-number\"],[12],[1,[32,0,[\"value\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"statusMessage\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "sgb-object-graphs/templates/components/sgb-ajax-singlestat-number.hbs"
  });

  _exports.default = _default;
});