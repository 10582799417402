define("ember-scanner/components/code-scanner", ["exports", "@ember-decorators/object", "@ember-decorators/component", "ember-scanner/templates/code-scanner"], function (_exports, _object, _component, _codeScanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let CodeScanner = (_dec = (0, _component.layout)(_codeScanner.default), _dec2 = (0, _component.tagName)('video'), _dec3 = (0, _component.classNames)('code-scanner'), _dec4 = (0, _object.observes)('device'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class CodeScanner extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "device", undefined);
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      emberAutoImportDynamic("@zxing/library").then(module => {
        if (this.isDestroyed) return;
        this.set('codeReader', new module.BrowserMultiFormatReader());
        this.startVideoScanning();
        this.codeReader.getVideoInputDevices().then(videoInputDevices => {
          if (this.onDevicesFound) {
            this.onDevicesFound(videoInputDevices);
          }
        }).catch(error => {
          if (this.onDevicesError) {
            this.onDevicesError(error);
          }
        });
      });
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this.codeReader) {
        this.codeReader.reset();
      }
    }

    // eslint-disable-next-line ember/no-observers
    onDeviceChange() {
      this.codeReader.reset();
      this.startVideoScanning();
    }
    startVideoScanning() {
      this.codeReader.decodeFromInputVideoDevice(this.device, this.elementId).then(result => {
        if (this.isDestroyed) return;
        if (this.onScanSuccess) {
          this.onScanSuccess(result);
        }
      }).catch(error => {
        if (this.isDestroyed) return;
        if (this.onScanError) {
          this.onScanError(error);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "onDeviceChange", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "onDeviceChange"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  var _default = _exports.default = CodeScanner;
});