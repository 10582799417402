define("sgb-object-graphs/components/sgb-ajax-n-day-kw-chart", ["exports", "sgb-object-graphs/components/sgb-ajax-base-chart"], function (_exports, _sgbAjaxBaseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SgbAjaxNDayKwChart = ( // TODO: create IncomingParams computed that would return {name: val, name2: val2, ...} and e.g.:
  //       chartinfo could depend on it, handleObjectChanged, etc.
  //       and other options (title, etc.) could depend on this to ensure chain
  //       https://github.com/emberjs/ember.js/blob/v2.16.2/packages/ember-metal/lib/computed.js#L455
  _dec = Ember.computed('top_group_id', 'start_hours_ago', 'end_hours_ago'), _dec2 = Ember.computed('top_group_id'), classic(_class = (_class2 = class SgbAjaxNDayKwChart extends _sgbAjaxBaseChart.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "shouldUpdateGraphData", true);

      _defineProperty(this, "title", 'Watts Use');
    }

    //  TODO: shouldn't it also depend on title/metrics?
    get chartInfo() {
      let top_group_id = this.top_group_id;
      let start_hours_ago = this.start_hours_ago;
      let end_hours_ago = this.end_hours_ago;
      return {
        componentName: 'sgb-device-line-chart',
        url: `groups/${top_group_id}/${start_hours_ago}-${end_hours_ago}/kw-chart`,
        // only set refreshUrl when end_hours_ago not defined
        refreshUrl: !end_hours_ago ? `groups/${top_group_id}/1-0/kw-chart` : null
      };
    }

    get metrics() {
      return [Metrics.watts_max, Metrics.watts_min, Metrics.temperature_f_avg, Metrics.cool_setpoint_f];
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "chartInfo", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "chartInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "metrics", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "metrics"), _class2.prototype)), _class2)) || _class);
  _exports.default = SgbAjaxNDayKwChart;
});