define("sgb-object-graphs/mixins/has-object-chart-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    emberenv: Ember.inject.service(),

    checkFeatureFlag(name) {
      let allowed = this.get('emberenv.featureFlags')[name];

      if (allowed == undefined) {
        console.error(`No feature flag is defined for ${name}`);
      }

      return allowed;
    },

    hasSgbObjectChart: Ember.computed('emberenv.featureFlags', 'objectPayload', 'isTopGroup', 'treeForChart', function () {
      let objectPayload = this.objectPayload;
      let tree = this.treeForChart;
      let isTopGroup = this.isTopGroup;

      if (!objectPayload) {
        return false;
      }

      if (!tree) {
        return false;
      }

      if (!this.checkFeatureFlag('showObjectCharts')) {
        return false;
      }

      let tp = objectPayload.hierarchy.type;

      if (tp == 'group') {
        if (!this.checkFeatureFlag('showObjectChartsGroupWatthoursPieChart')) {
          return false;
        }

        if (isTopGroup && this.checkFeatureFlag('topGroupCharts')) {
          return true;
        }

        let children = tree.filterBy('parent', objectPayload.id.toString());
        let child_groups = children.filterBy('objectType', 'group');
        return child_groups.length == 0;
      } else if (tp == 'device') {
        let metrics = DeviceTypesToMetrics.metrics_for_device_type(objectPayload.device_type.id);
        return metrics.length != 0;
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});