define("ember-models-table/templates/components/models-table/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5vgNTeaD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"input\",[[16,0,[32,0,[\"themeInstance\",\"input\"]]],[24,4,\"text\"]],[[\"@value\"],[[30,[36,0],[[32,0,[\"record\"]],[32,0,[\"column\",\"propertyName\"]]],null]]],null],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/cell-content-edit.hbs"
  });
});