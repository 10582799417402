define("ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "F58BuzYX",
    "block": "{\"symbols\":[\"Form\",\"GlobalFilter\",\"&default\"],\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"bs-form\",[[24,0,\"globalSearch\"]],[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"inline\",[32,0],[32,0,[\"noop\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"element\"]],[[24,0,\"input-group\"]],[[\"@property\",\"@type\"],[\"value\",\"text\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"themeInstance\",\"searchLabelMsg\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"label\"],[15,\"for\",[32,2,[\"id\"]]],[14,0,\"input-group-addon\"],[12],[1,[32,0,[\"themeInstance\",\"searchLabelMsg\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[8,[32,2,[\"control\"]],[[16,\"placeholder\",[32,0,[\"themeInstance\",\"searchPlaceholderMsg\"]]],[24,0,\"filterString\"]],[[],[]],null],[2,\"\\n      \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n        \"],[8,\"bs-button\",[[24,0,\"clearFilterIcon\"],[16,\"disabled\",[30,[36,1],[[32,0,[\"globalFilterUsed\"]],\"disabled\"],null]]],[[\"@type\",\"@onClick\"],[\"secondary\",[30,[36,2],[[32,0,[\"clearGlobalFilter\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \\n          \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"clearFilterIcon\"]]],[12],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"emt-sr-only\"],[12],[1,[32,0,[\"themeInstance\",\"clearGlobalFilterMsg\"]]],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"unless\",\"fn\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v3/global-filter.hbs"
  });
});