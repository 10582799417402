define("sgb-object-graphs/components/save-canvas-as-png", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SaveCanvasAsPng = (_dec = (0, _component.tagName)('a'), _dec2 = (0, _component.classNames)('far fa-save'), _dec3 = (0, _component.attributeBindings)('ariaLabel:aria-label', 'ariaHidden:aria-hidden'), classic(_class = _dec(_class = _dec2(_class = _dec3(_class = class SaveCanvasAsPng extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "ariaLabel", 'Download as PNG');

      _defineProperty(this, "ariaHidden", false);
    }

    click() {
      // this.sendAction('downloadPNG') doesn't work
      var canvas = this.canvas;
      var a = this.element;
      a.href = canvas.toDataURL(); // TODO: a.set('href', canvas.toDataURL()) doesn't work  :(

      var fileName = this.fileName || 'canvas';
      a.download = `${fileName}.png`;
      return true; // trigger default browser handling for it
    }

  }) || _class) || _class) || _class) || _class);
  _exports.default = SaveCanvasAsPng;
});