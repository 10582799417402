define("ember-performance-tracking/initializers/route-perf", ["exports", "ember-performance-tracking/mixins/route-perf"], function (_exports, _routePerf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    Ember.Router.reopen(_routePerf.default);
  }
  var _default = _exports.default = {
    name: 'route-perf',
    initialize: initialize
  };
});