define("sgb-object-graphs/components/sgb-group-watthours-pie-chart", ["exports", "sgb-object-graphs/components/sgb-base-chart", "sgb-object-graphs/components/sgb-device-line-chart"], function (_exports, _sgbBaseChart, _sgbDeviceLineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SortByIntegerTags = _exports.MakeACopyOfTheTsdbData = _exports.DeviceOrDeviceGroupLevelDataMerger = _exports.AssignGroup = _exports.AssignColorTintsBasedOnGroup = _exports.ApplySIUnits = void 0;
  _exports.map_in_place_dps_val = map_in_place_dps_val;
  _exports.map_in_place_list = map_in_place_list;

  var _dec, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;

  class MakeACopyOfTheTsdbData {
    process(openTsdbData) {
      return JSON.parse(JSON.stringify(openTsdbData));
    }

  }

  _exports.MakeACopyOfTheTsdbData = MakeACopyOfTheTsdbData;

  window.__CLASSIC_OWN_CLASSES__.set(MakeACopyOfTheTsdbData, false);

  class DatasetMerger {
    process(tsdb_data) {
      let keyed_tsdb_data = this.map(tsdb_data);
      let res = this.reduce(keyed_tsdb_data);
      return res;
    }

    map(tsdb_data) {
      let self = this;
      let res = {};
      tsdb_data.forEach(function (single_metric_result) {
        let key = self.to_key(single_metric_result);

        if (res[key] == undefined) {
          res[key] = [];
        }

        res[key].push(single_metric_result);
      });
      return res;
    }

    reduce(keyed_tsdb_data) {
      let keys = [];

      for (var key in keyed_tsdb_data) {
        keys.push(key);
      }

      return keys.map(key => this.merge(key, keyed_tsdb_data[key]));
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(DatasetMerger, false);

  class DeviceGroupLevelDatasetMerger extends DatasetMerger {
    constructor(objectLookup) {
      super();
      this.group_assigner = new AssignGroup(objectLookup); // TODO: make it 'require' AssignGroup to run before?
    }

    to_key(single_tsdb_metric_result) {
      return this.group_assigner.to_group(single_tsdb_metric_result).legend_name;
    }

    merge(key, tsdb_metric_lists_to_merge) {
      let single_result = tsdb_metric_lists_to_merge.reduce(function (acc, item) {
        if (acc.tags.device_id != null) {
          delete acc.tags['device_id'];
        }

        acc.dps = acc.dps.concat(item.dps);
        return acc;
      });
      single_result.sgb_label = key;
      return single_result;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(DeviceGroupLevelDatasetMerger, true);

  window.__CLASSIC_OWN_CLASSES__.set(DeviceGroupLevelDatasetMerger, false);

  class DeviceLevelDatasetMerger extends DatasetMerger {
    constructor(objectLookup) {
      super();
      this.objectLookup = objectLookup;
    }

    to_key(single_tsdb_metric_result) {
      return parseInt(single_tsdb_metric_result.tags['device_id']);
    }

    merge(key, tsdb_metric_lists_to_merge) {
      if (tsdb_metric_lists_to_merge.length != 1) {
        console.error(`Expected a single entry in tsdb_metric_lists_to_merge, got ${tsdb_metric_lists_to_merge.length}`);
        (0, _sgbBaseChart.dump_current)(tsdb_metric_lists_to_merge);
      }

      let single_result = tsdb_metric_lists_to_merge[0];
      single_result.sgb_label = this.to_label(single_result);
      return single_result;
    }

    to_label(single_device_tsdb_data) {
      let object = this.objectLookup.toDetails(single_device_tsdb_data.tags.device_id);
      return `${object.text}`; // return `${object.text} (${object.id})`;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(DeviceLevelDatasetMerger, true);

  window.__CLASSIC_OWN_CLASSES__.set(DeviceLevelDatasetMerger, false);

  class AssignColorTintsBasedOnGroup {
    process(tsdb_data) {
      let group_name_to_color_tint_index = {};
      tsdb_data.forEach(function (single_tsdb_data) {
        let group_name = single_tsdb_data.sgb_group.legend_name;

        if (group_name_to_color_tint_index[group_name] == null) {
          group_name_to_color_tint_index[group_name] = 0;
        }

        let current_tint_index = group_name_to_color_tint_index[group_name];
        let color = single_tsdb_data.sgb_group.color_tints[current_tint_index];

        if (color == null) {
          console.error(`group legend name ${group_name} needs to many tints - cannot give one for ${current_tint_index}`); // eslint-disable-line no-console
        } else {
          group_name_to_color_tint_index[group_name] += 1;
        }

        single_tsdb_data.sgb_color = color;
      });
      return tsdb_data;
    }

  }

  _exports.AssignColorTintsBasedOnGroup = AssignColorTintsBasedOnGroup;

  window.__CLASSIC_OWN_CLASSES__.set(AssignColorTintsBasedOnGroup, false);

  class SortByIntegerTags {
    constructor(tags_to_sort_by) {
      this.tags_to_sort_by = tags_to_sort_by;
    }

    cmp(a, b) {
      let property_name = null,
          cmp_res = null;

      for (var i = 0; i < this.tags_to_sort_by.length; i++) {
        property_name = this.tags_to_sort_by[i];
        cmp_res = parseInt(a.tags[property_name]) - parseInt(b.tags[property_name]);

        if (cmp_res != 0) {
          return cmp_res;
        }
      }

      return 0;
    }

    process(tsdb_data) {
      tsdb_data.sort((a, b) => this.cmp(a, b));
      return tsdb_data;
    }

  }

  _exports.SortByIntegerTags = SortByIntegerTags;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(SortByIntegerTags, true);

  window.__CLASSIC_OWN_CLASSES__.set(SortByIntegerTags, false);

  class AssignGroup {
    constructor(objectLookup) {
      this.objectLookup = objectLookup;
    }

    process(tsdb_data) {
      tsdb_data.forEach(single_tsdb_data => this.assign_group(single_tsdb_data));
      return tsdb_data;
    }

    assign_group(single_tsdb_data) {
      single_tsdb_data.sgb_group = this.to_group(single_tsdb_data);
    }

    to_group(single_tsdb_data) {
      let device_id = single_tsdb_data.tags.device_id;
      let cdt_id = single_tsdb_data.tags.controlled_type_id;
      let group = this.objectLookup.toGroup(device_id, cdt_id);
      return group;
    }

  }

  _exports.AssignGroup = AssignGroup;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(AssignGroup, true);

  window.__CLASSIC_OWN_CLASSES__.set(AssignGroup, false);

  class TsdbToPieChartJSData {
    constructor(reduce_fn, acc_init_value) {
      this.reduce_fn = reduce_fn;
      this.acc_init_value = acc_init_value;
    }

    process(tsdb_data) {
      let data = [],
          colors = [],
          labels = [];
      let self = this;
      tsdb_data.forEach(function (single_tsdb_data) {
        labels.push(single_tsdb_data.sgb_label);
        colors.push(single_tsdb_data.sgb_color);
        let val = single_tsdb_data.dps.reduce(self.reduce_fn, self.acc_init_value);
        data.push(val);
      });
      return {
        datasets: [{
          data: data,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1
        }],
        labels: labels
      };
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(TsdbToPieChartJSData, true);

  window.__CLASSIC_OWN_CLASSES__.set(TsdbToPieChartJSData, false);

  function map_in_place_dps_val(dps, map_fn) {
    for (var i = 0; i < dps.length; i++) {
      let dps_item = dps[i];
      let input = dps_item[1];
      let res = map_fn(input);
      dps_item[1] = res;

      if (res == null || Number.isNaN(res)) {
        (0, _sgbBaseChart.dump_current)({
          msg: 'something went wrong - got null/NaN',
          input: input,
          res: res
        });
      }
    }
  }

  function map_in_place_list(list, map_fn) {
    for (var i = 0; i < list.length; i++) {
      let input = list[i];
      let res = map_fn(input);
      list[i] = res;

      if (res == null || Number.isNaN(res)) {
        (0, _sgbBaseChart.dump_current)({
          msg: 'something went wrong - got null/NaN',
          input: input,
          res: res
        });
      }
    }
  }

  class ApplySIUnits {
    constructor(data_to_array_to_modify_fn, data_to_unit_fn, array_to_num_fn, map_in_place_fn) {
      this.data_to_array_to_modify_fn = data_to_array_to_modify_fn;
      this.data_to_unit_fn = data_to_unit_fn;

      this.array_to_num_fn = array_to_num_fn || function (x) {
        return x;
      };

      this.map_in_place_fn = map_in_place_fn;

      if (!this.map_in_place_fn) {
        throw new Error('map_in_place_fn is undefined');
      }
    }

    isNumber(x) {
      return x != undefined && !Number.isNaN(x);
    }

    process(data) {
      let self = this;
      let array_to_modify = this.data_to_array_to_modify_fn(data);
      let abs_values = array_to_modify.map(self.array_to_num_fn).map(Math.abs);
      let mx = Math.max(...abs_values);

      function when_error_recover(msg, debug_obj) {
        console.error(`ApplySIUnits - ${msg} (see below), not modifying things`);
        (0, _sgbBaseChart.dump_current)(debug_obj);
        data.sgb.si.unit = self.data_to_unit_fn(data);
      }

      if (data.sgb == null) {
        data.sgb = {};
      }

      if (data.sgb.si == null) {
        data.sgb.si = {
          prefix: null,
          denom: 1,
          unit: null
        };
      }

      let si = data.sgb.si;

      if (!this.isNumber(mx)) {
        when_error_recover('Could not find max value of', {
          array_to_modify: array_to_modify,
          abs_values: abs_values
        });
      } else {
        [si.prefix, si.denom] = this.get_prefix_denom_for(mx);

        if (si.prefix == undefined || !this.isNumber(si.denom)) {
          when_error_recover('Could not find prefix/denom', {
            mx: mx,
            prefix: si.prefix,
            denom: si.denom
          });
        } else {
          self.map_in_place_fn(array_to_modify, x => x / si.denom);
          si.unit = self.data_to_unit_fn(data);

          if (si.unit != undefined) {
            si.unit = `${si.prefix}${si.unit}`;
          } else {// silent this output

            /*
              when_error_recover(
                'unit is undefined for some reason',
                self.data_to_unit_fn
              );
            */
          }
        }
      }

      return data;
    }

    get_prefix_denom_for(val) {
      const sorted_prefix_denom_list = [['M', Math.pow(10, 6)], ['k', Math.pow(10, 3)]];
      let units = sorted_prefix_denom_list.filter(prefix_denom => val >= prefix_denom[1]).concat([['', 1]]);
      return units[0];
    }

  }

  _exports.ApplySIUnits = ApplySIUnits;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(ApplySIUnits, true);

  window.__CLASSIC_OWN_CLASSES__.set(ApplySIUnits, false);

  class FixDecimalPlaces {
    constructor(data_to_array_to_modify_fn, fixed_digits, map_in_place_fn) {
      this.data_to_array_to_modify_fn = data_to_array_to_modify_fn;
      this.fixed_digits = fixed_digits;
      this.map_in_place_fn = map_in_place_fn;
    }

    process(obj) {
      let array_to_modify = this.data_to_array_to_modify_fn(obj);
      this.map_in_place_fn(array_to_modify, x => x.toFixed(this.fixed_digits));
      return obj;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(FixDecimalPlaces, true);

  window.__CLASSIC_OWN_CLASSES__.set(FixDecimalPlaces, false);

  class DeviceOrDeviceGroupLevelDataMerger {
    constructor(objectLookup, aggregateLevel) {
      this.max_nr_of_pie_slices = 15; // Hyatt Regency Calgary

      this.objectLookup = objectLookup;
      this.aggregateLevel = aggregateLevel;
    }

    process(openTsdbData) {
      return this.get_processor(openTsdbData.length).process(openTsdbData);
    }

    get_processor(number_of_devices) {
      if (this.aggregateLevel != 'group' && number_of_devices <= this.max_nr_of_pie_slices) {
        return new DeviceLevelDatasetMerger(this.objectLookup);
      } else {
        return new DeviceGroupLevelDatasetMerger(this.objectLookup);
      }
    }

  }

  _exports.DeviceOrDeviceGroupLevelDataMerger = DeviceOrDeviceGroupLevelDataMerger;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(DeviceOrDeviceGroupLevelDataMerger, true);

  window.__CLASSIC_OWN_CLASSES__.set(DeviceOrDeviceGroupLevelDataMerger, false);

  let SgbGroupWatthoursPieChart = (_dec = Ember.inject.service('object-lookup-for-charts'), classic(_class = (_class2 = class SgbGroupWatthoursPieChart extends _sgbBaseChart.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "objectLookup", _descriptor, this);

      _defineProperty(this, "chartComponentName", 'sgb-ember-pie-chart');
    }

    opentsdb_to_chartjs(openTsdbData) {
      // only needed for testing dummy data
      this.objectLookup.reset?.();
      let processors = [new MakeACopyOfTheTsdbData(), new _sgbBaseChart.RemoveOnlyZeroMetrics(), new DeviceOrDeviceGroupLevelDataMerger(this.objectLookup, this.aggregateLevel), new SortByIntegerTags(['device_type_id', 'device_id']), new AssignGroup(this.objectLookup), new AssignColorTintsBasedOnGroup(), new TsdbToPieChartJSData(function (acc, _ref) {
        let [dps_date, dps_value] = _ref;
        return acc + dps_value;
      }, 0), // eslint-disable-line no-unused-vars
      new ApplySIUnits(d => d.datasets[0].data, data => 'Wh', // eslint-disable-line no-unused-vars
      x => x, map_in_place_list), // TODO: introduce  wrappers InChartJS vs InTsdb?
      new FixDecimalPlaces(d => d.datasets[0].data, 2, map_in_place_list)];
      return (0, _sgbBaseChart.applyProcessors)(openTsdbData, processors);
    } //  TODO: display the helper to convert an object(payload) to display name
    // TODO: modify server side so we get per device id and isn't aggregated up
    // TODO: modify server side to accept device_ids and/or parent_group_ids in the url/payload
    // TODO: modify has chart to behave based on this


    getOptions(data) {
      // eslint-disable-line no-unused-vars
      let options = {
        aspectRatio: 2,
        legend: {
          position: 'top' // default is top

        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.label}: ${context.formattedValue} ${data?.sgb?.si?.unit}`;
              }
            }
          }
        }
      };
      let processors = [new _sgbDeviceLineChart.OptionsApplyFontColor(this.fontColor, false)];
      return (0, _sgbBaseChart.applyProcessors)(options, processors);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "objectLookup", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = SgbGroupWatthoursPieChart;
});