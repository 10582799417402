/**
 * ### customicons plugin
 *
 * Makes each node appear block level. Making selection easier. May cause slow down for large trees in old browsers.
 */
/* globals jQuery, define, exports, require */
(function (factory) {
  "use strict";
  if (typeof define === 'function' && define.amd) {
    define('jstree.customicons', ['jquery','jstree'], factory);
  }
  else if(typeof exports === 'object') {
    factory(require('jquery'), require('jstree'));
  }
  else {
    factory(jQuery, jQuery.jstree);
  }
}(function ($, jstree, undefined) {
  "use strict";

  if($.jstree.plugins.customicons) { return; }

  /**
   * stores all defaults for the customicons plugin
   * @name $.jstree.defaults.customicons
   * @plugin dnd
   */
  $.jstree.defaults.customicons = {
    'default' : {
      'weight': 0
    }
  };

  var div = document.createElement('DIV');
  div.setAttribute('unselectable','on');
  div.setAttribute('role','presentation');
  div.className = 'jstree-customicons';
  div.innerHTML = '';

  var iconBar = document.createElement('DIV');
  iconBar.className = 'icon-bar';
  iconBar.innerHTML = '';

  div.appendChild(iconBar);

  $.jstree.plugins.customicons = function (options, parent) {
    this.init = function (el, options) {
      var i, j;
      if(options && options.customicons && options.customicons['default']) {
        for(i in options.customicons) {
          if(i !== 'default' && options.customicons.hasOwnProperty(i)) {
            for(j in options.customicons['default']) {
              if(options.customicons['default'].hasOwnProperty(j) && options.customicons[i][j] === undefined) {
                options.customicons[i][j] = options.customicons['default'][j];
              }
            }
          }
        }
      }
      parent.init.call(this, el, options);
    };

    this.bind = function () {
      parent.bind.call(this);

      this.element
        .on('model.jstree', $.proxy(function (e, data) {
            var m = this._model.data,
              dpc = data.nodes,
              t = this.settings.customicons,
              i, j, c = 'default', k,
              i2, j2, o, cis, ci, _cis, _ci;

            for(i = 0, j = dpc.length; i < j; i++) {
              if((o = m[dpc[i]].original) && (cis = m[dpc[i]].original.customicons)) {
                for(i2 = 0, j2 = cis.length; i2 < j2; i2++) {
                  _cis = [];
                  _ci = null;
                  ci = cis[i2];
                  if (typeof ci === 'string') {
                    _ci = t[ci];
                  }
                  if (typeof ci === 'object') {
                    _ci = ci;
                  }
                  if (_ci) {
                    _ci = $.extend(true, {}, options['default'], _ci);
                    _cis.push(_ci);
                  }
                }
                // sort by weight
                _cis.sort(function(obj1, obj2) {
                  return obj1.weight - obj2.weight;
                });
                m[dpc[i]].customicons = _cis;
                // console.log('model.jstree', o, cis, _cis, t, options);
              }
            }
          }, this))
        .on('ready.jstree set_state.jstree', $.proxy(function () {
            // console.log('ready.jstree set_state.jstree');
            this.hide_dots();
          }, this))
        .on("init.jstree loading.jstree ready.jstree", $.proxy(function () {
            // console.log('init.jstree loading.jstree ready.jstree');
            this.get_container_ul().addClass('jstree-customicons-ul');
          }, this))
        .on("deselect_all.jstree", $.proxy(function (e, data) {
            // console.log('deselect_all.jstree', e, data);
            this.element.find('.jstree-customicons-clicked').removeClass('jstree-customicons-clicked');
          }, this))
        .on("changed.jstree", $.proxy(function (e, data) {
            // console.log('changed.jstree', e, data);
            this.element.find('.jstree-customicons-clicked').removeClass('jstree-customicons-clicked');
            var tmp = false, i, j;
            for(i = 0, j = data.selected.length; i < j; i++) {
              tmp = this.get_node(data.selected[i], true);
              if(tmp && tmp.length) {
                tmp.children('.jstree-customicons').addClass('jstree-customicons-clicked');
              }
            }
          }, this))
        .on("open_node.jstree", $.proxy(function (e, data) {
            // console.log('open_node.jstree', e, data);
            this.get_node(data.node, true).find('.jstree-clicked').parent().children('.jstree-customicons').addClass('jstree-customicons-clicked');
          }, this))
        .on("hover_node.jstree dehover_node.jstree", $.proxy(function (e, data) {
            if(e.type === "hover_node" && this.is_disabled(data.node)) { return; }
            this.get_node(data.node, true).children('.jstree-customicons')[e.type === "hover_node"?"addClass":"removeClass"]('jstree-customicons-hovered');
          }, this))
        .on("contextmenu.jstree", ".jstree-customicons", $.proxy(function (e) {
            // console.log('contextmenu.jstree - .jstree-customicons', e);
            if (this._data.contextmenu) {
              e.preventDefault();
              var tmp = $.Event('contextmenu', { metaKey : e.metaKey, ctrlKey : e.ctrlKey, altKey : e.altKey, shiftKey : e.shiftKey, pageX : e.pageX, pageY : e.pageY });
              $(e.currentTarget).closest(".jstree-node").children(".jstree-anchor").first().trigger(tmp);
            }
          }, this))
        .on("click.jstree", ".jstree-customicons", function (e) {
            e.stopImmediatePropagation();
            var tmp = $.Event('click', { metaKey : e.metaKey, ctrlKey : e.ctrlKey, altKey : e.altKey, shiftKey : e.shiftKey });
            $(e.currentTarget).closest(".jstree-node").children(".jstree-anchor").first().trigger(tmp).focus();
          })
        .on("dblclick.jstree", ".jstree-customicons", function (e) {
            e.stopImmediatePropagation();
            var tmp = $.Event('dblclick', { metaKey : e.metaKey, ctrlKey : e.ctrlKey, altKey : e.altKey, shiftKey : e.shiftKey });
            $(e.currentTarget).closest(".jstree-node").children(".jstree-anchor").first().trigger(tmp).focus();
          })
        .on("click.jstree", ".jstree-leaf > .jstree-ocl", $.proxy(function (e) {
            e.stopImmediatePropagation();
            var tmp = $.Event('click', { metaKey : e.metaKey, ctrlKey : e.ctrlKey, altKey : e.altKey, shiftKey : e.shiftKey });
            $(e.currentTarget).closest(".jstree-node").children(".jstree-anchor").first().trigger(tmp).focus();
          }, this))
        .on("mouseover.jstree", ".jstree-customicons, .jstree-icon", $.proxy(function (e) {
            e.stopImmediatePropagation();
            if(!this.is_disabled(e.currentTarget)) {
              this.hover_node(e.currentTarget);
            }
            return false;
          }, this))
        .on("mouseleave.jstree", ".jstree-node", $.proxy(function (e) {
            this.dehover_node(e.currentTarget);
          }, this))
        ;
    };
    this.teardown = function () {
      if(this.settings.customicons) {
        this.element.find(".jstree-customicons").remove();
      }
      parent.teardown.call(this);
    };
    this.redraw_node = function(obj, deep, callback, force_render) {
      // console.log('redraw_node', obj, deep, callback, force_render);
      var _obj = obj;
      obj = parent.redraw_node.apply(this, arguments);
      if(obj) {
        this.create_iconbar(obj);
      }
      return obj;
    };
    this.create_iconbar = function(obj) {
      if (typeof obj !== 'object') {
        obj = this.get_node(obj, true);
        obj = $(obj).get(0);
      }
      if(obj) {
        var tmp = this._create_iconbar(obj)
        if (tmp) {
          if($.inArray(obj.id, this._data.core.selected) !== -1) { tmp.className += ' jstree-customicons-clicked'; }
          if(this._data.core.focused && this._data.core.focused === obj.id) { tmp.className += ' jstree-customicons-hovered'; }
          obj.insertBefore(tmp, obj.childNodes[0]);
        }
      }
      return obj;
    };
    this._create_iconbar = function(obj) {
      var tmp = div.cloneNode(true),
      i, j, cis, ci, ib;

      if(this._model.data[obj.id] && (cis = this._model.data[obj.id].customicons)) {
        ib = $(tmp).children(".icon-bar").first();
        for(i = 0, j = cis.length; i < j; i++) {
          ci = cis[i];
          ib.append('<span class="icon ' + ci.class + '" title="' + ci.title +'"><i class="' + ci.icon +'"></i></span>');
        }
        return tmp;
      }
      return false;
    };
    this.set_customicons = function(obj, customicons, origin) {
      var _obj = obj;
      obj = this.get_node(obj, true);
      obj = $(obj).get(0);

      var node = this.get_node(obj),
        i, j, cis, ci, found = false;
      var ic, jc;

      if(node) {
        if (!node.customicons) {
          node.customicons = [];
        }

        cis = node.customicons;

        // remove first by origin
        for (i = cis.length - 1; i >= 0; i--) {
          ci = cis[i];
          if (ci.origin === origin) {
            cis.splice(i, 1);
          }
        }

        for(ic = 0; ic < customicons.length; ic++) {
          found = false;
          var customicon = customicons[ic];
          for(i = 0, j = cis.length; i < j; i++) {
            ci = cis[i];
            if (typeof ci === 'object') {
              if (ci.name === customicon.name) {
                cis[i] = $.extend(true, {}, options['default'], customicon);
                found = true;
              }
            }
          }
          if (!found) {
            ci = $.extend(true, {}, options['default'], customicon);
            // add item
            cis.push(ci);
          }
        }

        // sort by weight
        cis.sort(function(obj1, obj2) {
          return obj1.weight - obj2.weight;
        });
        node.customicons = cis;
      }
      var cobj = $(obj).children(".jstree-customicons").first();
      if (cobj) {
        cobj.remove();
      }
      this.create_iconbar(_obj);
    };
    this.set_customicon = function(obj, customicon, no_draw) {
      var _obj = obj;
      obj = this.get_node(obj, true);
      obj = $(obj).get(0);

      var node = this.get_node(obj),
        i, j, cis, ci, found = false;

      if(node) {
        if (!node.customicons) {
          node.customicons = [];
        }
        cis = node.customicons;
        for(i = 0, j = cis.length; i < j; i++) {
          ci = cis[i];
          if (typeof ci === 'object') {
            if (ci.name === customicon.name) {
              cis[i] = $.extend(true, {}, options['default'], customicon);
              found = true;
            }
          }
        }
        if (!found) {
          ci = $.extend(true, {}, options['default'], customicon);
          // add item
          cis.push(ci);
        }
        // sort by weight
        cis.sort(function(obj1, obj2) {
          return obj1.weight - obj2.weight;
        });
        node.customicons = cis;
      }
      if (!no_draw) {
        var cobj = $(obj).children(".jstree-customicons").first();
        if (cobj) {
          cobj.remove();
        }
        this.create_iconbar(_obj);
      }
    };
    this.remove_customicon = function(obj, customicon, no_draw) {
      var _obj = obj;
      obj = this.get_node(obj, true);
      obj = $(obj).get(0);

      var node = this.get_node(obj),
        i, j, cis, ci, found = false;

      if(node) {
        if (!node.customicons) {
          node.customicons = [];
        }
        cis = node.customicons;
        for(i = 0, j = cis.length; i < j; i++) {
          ci = cis[i];
          if (typeof ci === 'object') {
            if (ci.name === customicon.name) {
              found = i;
            }
          }
        }
        if (found !== false) {
          // remove item
          cis.splice(found, 1);
        }
        // sort by weight
        cis.sort(function(obj1, obj2) {
          return obj1.weight - obj2.weight;
        });
        node.customicons = cis;
      }
      if (!no_draw) {
        var cobj = $(obj).children(".jstree-customicons").first();
        if (cobj) {
          cobj.remove();
        }
        this.create_iconbar(_obj);
      }
    };
  };
  // include the customicons plugin by default
  // $.jstree.defaults.plugins.push("customicons");
}));
