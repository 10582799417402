define("ember-models-table/templates/components/models-table/themes/bootstrap3/row-select-all-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Q3bQPFgn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"button\"],[15,\"onclick\",[32,0,[\"doToggleAllSelection\"]]],[15,0,[30,[36,2],[[32,0,[\"themeInstance\",\"buttonLink\"]],\" toggle-all\"],null]],[14,4,\"button\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[32,0,[\"selectedItems\",\"length\"]],[30,[36,3],[[32,0,[\"selectedItems\",\"length\"]],[32,0,[\"data\",\"length\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"emt-icons-stack\"],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"selectSomeRowsIcon\"]]],[12],[13],[2,\"\\n      \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"deselectAllRowsIcon\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n     \\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[30,[36,1],[[30,[36,0],[[32,0,[\"selectedItems\",\"length\"]],[32,0,[\"data\",\"length\"]]],null],[32,0,[\"themeInstance\",\"selectAllRowsIcon\"]],[32,0,[\"themeInstance\",\"deselectAllRowsIcon\"]]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\",\"concat\",\"not-eq\",\"and\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/bootstrap3/row-select-all-checkbox.hbs"
  });
});