define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5f3O9X4P",
    "block": "{\"symbols\":[\"@accept\",\"@capture\",\"@multiple\",\"@disabled\",\"&default\"],\"statements\":[[10,\"input\"],[15,1,[32,0,[\"for\"]]],[15,\"accept\",[32,1]],[15,\"capture\",[32,2]],[15,\"multiple\",[32,3]],[15,\"disabled\",[32,4]],[15,\"onchange\",[30,[36,0],[[32,0],\"change\"],[[\"value\"],[\"target.files\"]]]],[14,\"hidden\",\"\"],[14,4,\"file\"],[12],[13],[18,5,[[32,0,[\"queue\"]]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "moduleName": "ember-file-upload/components/file-upload/template.hbs"
  });
});