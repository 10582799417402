define("ember-models-table/templates/components/models-table/row-select-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PatsJNV5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"button\"],[15,0,[32,0,[\"themeInstance\",\"buttonLink\"]]],[15,\"onclick\",[30,[36,0],[[32,0,[\"doClickOnRow\"]],[32,0,[\"index\"]],[32,0,[\"record\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[30,[36,1],[[32,0,[\"isSelected\"]],[32,0,[\"themeInstance\",\"selectRowIcon\"]],[32,0,[\"themeInstance\",\"deselectRowIcon\"]]],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/row-select-checkbox.hbs"
  });
});