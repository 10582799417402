define("sgb-object-graphs/components/sgb-device-line-chart", ["exports", "sgb-object-graphs/components/sgb-base-chart", "sgb-object-graphs/components/sgb-group-watthours-pie-chart"], function (_exports, _sgbBaseChart, _sgbGroupWatthoursPieChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RoundDpsValuesFromSgbMetric = _exports.OptionsTimeLabelsForAxisX = _exports.OptionsApplyFontColor = _exports.ForEachTsdbSingleMetric = _exports.AssignColorsToDatasets = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  /* global DeviceTypesToMetrics */

  // import * as _ from 'lodash-es';
  class OptionsTimeLabelsForAxisX {
    constructor(dates, nrOfXAxisLabels, timeFormat) {
      this.nrOfXAxisLabels = nrOfXAxisLabels || 12;
      this.timeFormat = timeFormat || 'MM/DD H:mm'; // TODO: derive it from durationInHours

      this.dates = dates;
      this.durationInHours = this.getDurationInHours(this.dates);
    }

    getDurationInHours(dates) {
      let mx = Math.max(...dates);
      let mn = Math.min(...dates);
      let milliseconds = mx - mn;

      if (Number.isNaN(milliseconds) || milliseconds == null) {
        return null;
      }

      if (!isFinite(milliseconds) || !milliseconds) {
        return 1;
      }

      return Math.ceil(milliseconds / 1000 / 60 / 60);
    }

    process(chartjsOptions) {
      if (null == this.durationInHours) {
        console.error('Duration in hours is null, not doing anything');
        return chartjsOptions;
      }

      const xAxis = chartjsOptions?.scales?.x;

      if (!xAxis) {
        console.error('No chartjsOptions.scales.x, not doing anything');
        return chartjsOptions;
      }

      xAxis.type = 'time';
      const stepSize = this.durationInHours * 60 / this.nrOfXAxisLabels;

      if (stepSize == null || Number.isNaN(stepSize)) {
        console.error({
          msg: 'Failed computing stepSize',
          stepSize: stepSize,
          durationInHours: this.durationInHours,
          nrOfXAxisLabels: this.nrOfXAxisLabels
        });
      }

      xAxis.time = {
        tooltipFormat: 'YYYY-MM-DD HH:mm:ss',
        unit: 'minute',
        stepSize: stepSize,
        displayFormats: {
          minute: this.timeFormat
        }
      };
      return chartjsOptions;
    }

  }

  _exports.OptionsTimeLabelsForAxisX = OptionsTimeLabelsForAxisX;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(OptionsTimeLabelsForAxisX, true);

  window.__CLASSIC_OWN_CLASSES__.set(OptionsTimeLabelsForAxisX, false);

  class FahrenheitToCelsiusConverter {
    constructor(unit) {
      if (['C', 'F'].indexOf(unit) == -1) {
        throw new Error(`Unknown temperature unit: ${unit}`);
      }

      this.unit = unit;
    }

    process(tsdb_data) {
      const temperature_metric_names = ['thermostat.heat_setpoint_f', 'thermostat.cool_setpoint_f', 'temperature.avg_f', 'temperature.avg_f', 'temperature_f'];
      let self = this;

      if (self.unit == 'C') {
        tsdb_data.forEach(function (single_metric_result) {
          let is_temperature = temperature_metric_names.indexOf(single_metric_result.metric) != -1;

          if (is_temperature) {
            single_metric_result.dps.forEach(function (date_val_pair, dps_index, dps) {
              // eslint-disable-line no-unused-vars
              let in_fahrenheit = date_val_pair[1]; // https://en.wikipedia.org/wiki/Conversion_of_units_of_temperature#Celsius_.28centigrade.29

              date_val_pair[1] = (in_fahrenheit - 32) * (5 / 9);
            });
            single_metric_result.unit = `°${self.unit}`;
          }
        });
      }

      return tsdb_data;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(FahrenheitToCelsiusConverter, true);

  window.__CLASSIC_OWN_CLASSES__.set(FahrenheitToCelsiusConverter, false);

  class ForEachTsdbSingleMetric {
    constructor(processor) {
      this.processor = processor;
    }

    process(tsdb_data) {
      let self = this;
      return tsdb_data.map(function (single_tsdb_metric) {
        return self.processor.process(single_tsdb_metric);
      });
    }

  }

  _exports.ForEachTsdbSingleMetric = ForEachTsdbSingleMetric;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(ForEachTsdbSingleMetric, true);

  window.__CLASSIC_OWN_CLASSES__.set(ForEachTsdbSingleMetric, false);

  class AssignSgbMetricToTsdbSingleResult {
    constructor(metrics) {
      this.metrics = metrics;
    }

    process(tsdb_data) {
      let self = this;
      tsdb_data.forEach(function (single_tsdb_data) {
        let metric = DeviceTypesToMetrics.getMetricByTsdbMetricName(single_tsdb_data.metric, self.metrics);

        if (!metric) {
          console.error(`Couldn't find metric for ${single_tsdb_data.metric}`);
        }

        single_tsdb_data.sgb_metric = metric;
      });
      return tsdb_data;
    }

  }

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(AssignSgbMetricToTsdbSingleResult, true);

  window.__CLASSIC_OWN_CLASSES__.set(AssignSgbMetricToTsdbSingleResult, false);

  function get_decimal_places_to_round_to(metric) {
    if (!metric) {
      return null;
    }

    let decimal_places = metric.round_to_decimal_digits;
    let should_round = decimal_places != undefined;

    if (!should_round) {
      return null;
    }

    return decimal_places;
  }

  function round(value, decimal_places) {
    return value.toFixed(decimal_places);
  }

  function round_from_metric(metric, value) {
    let decimal_places = get_decimal_places_to_round_to(metric);

    if (decimal_places == null) {
      return value;
    }

    return round(value, decimal_places);
  }

  class RoundDpsValuesFromSgbMetric {
    // TODO: contrast w/ FixDecimalPlaces
    constructor(decimal_places_to_round_to) {
      this.decimal_places_to_round_to = decimal_places_to_round_to;
    }

    process(single_tsdb_data) {
      let decimal_places_to_round_to = this.decimal_places_to_round_to || get_decimal_places_to_round_to(single_tsdb_data.sgb_metric);

      if (decimal_places_to_round_to != null) {
        single_tsdb_data.dps.forEach(function (dp) {
          dp[1] = round(dp[1], decimal_places_to_round_to);
        });
      }

      return single_tsdb_data;
    }

  }

  _exports.RoundDpsValuesFromSgbMetric = RoundDpsValuesFromSgbMetric;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(RoundDpsValuesFromSgbMetric, true);

  window.__CLASSIC_OWN_CLASSES__.set(RoundDpsValuesFromSgbMetric, false);

  class SetLabelFromMetricAndUnit {
    process(single_tsdb_data) {
      let metric = single_tsdb_data.sgb_metric;
      let label = null;

      if (metric != null) {
        label = metric.label;
        let unit = single_tsdb_data.sgb.si.unit;

        if (unit) {
          label = `${metric.label} (${unit})`;
        }
      }

      single_tsdb_data.sgb_label = label;
      return single_tsdb_data;
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(SetLabelFromMetricAndUnit, false);

  class ConvertTsdbTimeEpochToLocalDateTimeObjects {
    process(single_tsdb_data) {
      single_tsdb_data.dps.forEach(function (dp) {
        dp[0] = new Date(dp[0]); // epoch seconds => should convert to local timezone correctly
      });
      return single_tsdb_data;
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(ConvertTsdbTimeEpochToLocalDateTimeObjects, false);

  function createGradient(start, stop) {
    let tmpCanvas = document.getElementById('sgb-tmp-canvas');

    if (!tmpCanvas) {
      console.log('create canvas...');
      tmpCanvas = document.createElement('canvas');
      tmpCanvas.id = 'sgb-tmp-canvas';
      tmpCanvas.className = 'd-none';
      tmpCanvas.style = 'display: none; width: 0; height: 0;';
      console.log(tmpCanvas);
      document.body.appendChild(tmpCanvas);
    } else {
      console.log('already has canvas...');
    }

    const ctx = tmpCanvas.getContext('2d');
    const gradientStroke = ctx.createLinearGradient(0, 0, 0, 1000);
    gradientStroke.addColorStop(0, stop);
    gradientStroke.addColorStop(1, start);
    return gradientStroke;
  }

  class AssignColorsToDatasets {
    constructor(property_to_alpha_pairs_list, datasetToRgbListFn) {
      this.property_to_alpha_pairs_list = property_to_alpha_pairs_list;
      this.datasetToRgbListFn = datasetToRgbListFn;
    }

    process(chartjs_data) {
      let self = this;
      chartjs_data.datasets.forEach(ds => {
        let rgb_list = this.datasetToRgbListFn(ds);

        if (rgb_list == null || rgb_list.length != 3) {
          console.error(`expected rgb_list to have 3 items, got ${rgb_list} instead. Not assigning colors`);
          return chartjs_data;
        }

        let rgb = rgb_list.join(',');
        self.property_to_alpha_pairs_list.forEach(_ref => {
          let [property, alpha] = _ref;
          let val = `rgba(${rgb},${alpha})`;

          if (false && property == 'backgroundColor') {
            console.log('create gradient for...', property);
            ds[property] = createGradient(`rgba(${rgb}, 0.15)`, `rgba(${rgb}, 0.0)`);
          } else {
            // ate gradient for...', property);
            ds[property] = val;
          }
        });
        ds.borderWidth = 1;
      });
      return chartjs_data;
    }

  }

  _exports.AssignColorsToDatasets = AssignColorsToDatasets;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(AssignColorsToDatasets, true);

  window.__CLASSIC_OWN_CLASSES__.set(AssignColorsToDatasets, false);

  class RemoveNulls {
    process(data) {
      return data.filter(d => d != null);
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(RemoveNulls, false);

  class TsdbMetricResultToChartjsLineDataset {
    process(single_tsdb_data) {
      let metric = single_tsdb_data.sgb_metric;

      if (!metric) {
        return null;
      }

      let unit = single_tsdb_data.sgb.si.unit;
      let line_data = {
        label: single_tsdb_data.sgb_label,
        data: single_tsdb_data.dps.map(x => {
          return {
            x: x[0],
            y: x[1]
          };
        }),
        // , tension: 0.1
        // , cubicInterpolationMode: 'monotone'
        lineTension: 0,
        yAxisID: unit,
        // TODO: make discrete_values stepped param come from the outside
        stepped: metric.discrete_values ? 'before' : false,
        pointRadius: 0,
        pointHitRadius: 10,
        metric: metric,
        unit: unit
      };
      return line_data;
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(TsdbMetricResultToChartjsLineDataset, false);

  class DatasetsListToChartjsDataObject {
    process(chartjs_datasets) {
      return {
        datasets: chartjs_datasets
      };
    }

  }

  window.__CLASSIC_OWN_CLASSES__.set(DatasetsListToChartjsDataObject, false);

  class OptionsApplyFontColor {
    constructor(color, createAxes) {
      this.color = color;
      this.createAxes = createAxes;

      if (color == undefined || createAxes == undefined) {
        console.error({
          color: color,
          createAxes: createAxes
        });
        throw new Error('must specify both ctor params');
      }
    }

    process(options) {
      const colors = {
        scales: {},
        title: {
          color: this.color
        },
        plugins: {
          legend: {
            labels: {
              color: this.color
            }
          }
        }
      };

      if (options.scales) {
        for (const scale of Object.keys(options.scales)) {
          colors.scales[scale] = {
            ticks: {
              color: this.color
            },
            title: {
              color: this.color
            }
          };
        }
      }

      options = _.merge(options, colors);
      return options;
    }

  }

  _exports.OptionsApplyFontColor = OptionsApplyFontColor;

  window.__CLASSIC_HAS_CONSTRUCTOR__.set(OptionsApplyFontColor, true);

  window.__CLASSIC_OWN_CLASSES__.set(OptionsApplyFontColor, false);

  let SgbDeviceLineChart = classic(_class = class SgbDeviceLineChart extends _sgbBaseChart.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "chartComponentName", 'sgb-ember-line-chart');
    }

    // TODO: need get access to the chartjs config (for debug) from its child component somehow - action: when config ready?
    // TODO: adjust pie charts too addon/templates/components/sgb-base-chart.hbs
    getOptions(data) {
      let datasets = data.datasets.filter(ds => ds.yAxisID != undefined);
      datasets = this.unique(datasets, ds => ds.yAxisID);
      this.set('has_y_axis_gridlines', false); // TODO: make this a param - single y axis gridline

      let options = {
        scales: {
          x: {
            grid: {
              display: false
            },
            distribution: 'linear'
          }
        },
        plugins: {
          tooltip: {
            position: 'nearest',
            mode: 'index',
            intersect: false
          }
        }
      }; // add Y axes

      for (const ds of datasets) {
        const axisDef = this.toYAxisDef(ds);
        options.scales[axisDef.id] = axisDef;
      }

      let timeFormat = this.timeFormat; // TODO: move up?

      let nrOfXAxisLabels = this.nrOfXAxisLabels; // TODO: move up?

      let allDates = this.getAllDates(datasets);
      let processors = [new OptionsTimeLabelsForAxisX(allDates, nrOfXAxisLabels, timeFormat), //new OptionsApplyFontColor(this.get('fontColor'))
      new OptionsApplyFontColor(this.fontColor, true)];
      return (0, _sgbBaseChart.applyProcessors)(options, processors);
    }

    opentsdb_to_chartjs(tsdb_data) {
      // TODO: generalize this example to someting less-sgb specific

      /*
       tsdb_data = [
        {
          "metric": "thermostat.cool_setpoint_f",
          "tags": {
            "top_customer_group_id": "8641",
            "device_id": "8658",
            "parent_group_id": "8664",
            "customer_id": "109",
            "device_type_id": "101"
          },
          "dps": [
            [ 1500819586000, 73.4000015258789 ],
            [ 1500823186000, 73.4000015258789 ],
            [ 1500826786000, 73.4000015258789 ]
        ]
       }
      ];
      */
      let processors = [new _sgbGroupWatthoursPieChart.MakeACopyOfTheTsdbData(), new FahrenheitToCelsiusConverter(this.temperatureUnit), new AssignSgbMetricToTsdbSingleResult(this.metrics), new ForEachTsdbSingleMetric(new _sgbGroupWatthoursPieChart.ApplySIUnits(single_tsdb_data => single_tsdb_data.dps, // TODO: introduce NullMetric later down the line
      single_tsdb_data => single_tsdb_data?.sgb_metric?.unit, dps => dps[1], _sgbGroupWatthoursPieChart.map_in_place_dps_val)), new ForEachTsdbSingleMetric(new RoundDpsValuesFromSgbMetric()), new ForEachTsdbSingleMetric(new SetLabelFromMetricAndUnit()), new ForEachTsdbSingleMetric(new ConvertTsdbTimeEpochToLocalDateTimeObjects()), new ForEachTsdbSingleMetric(new TsdbMetricResultToChartjsLineDataset()), new RemoveNulls(), new DatasetsListToChartjsDataObject(), new AssignColorsToDatasets([['borderColor', 1], ['backgroundColor', 0.1]], ds => ds.metric.rgb)];
      let res = (0, _sgbBaseChart.applyProcessors)(tsdb_data, processors);

      if (res.datasets.length == 0) {
        return null;
      }

      return res;
    }

    unique(list, to_key_fn) {
      if (!to_key_fn) {
        to_key_fn = x => x;
      }

      let keys = new Set();
      return list.reduce((acc, current) => {
        let key = to_key_fn(current);

        if (!keys.has(key)) {
          keys.add(key);
          acc.push(current);
        }

        return acc;
      }, []);
    }

    getAllDates(datasets) {
      return datasets.reduce(function (acc, ds) {
        return acc.concat(ds.data.map(dp => dp.x));
      }, []);
    }

    toYAxisDef(dataset) {
      //  TODO: document metric 'api'
      let metric = dataset.metric;
      let should_have_gridlines = !this.has_y_axis_gridlines && metric.axisPosition == 'left';

      if (should_have_gridlines) {
        this.set('has_y_axis_gridlines', true);
      }

      let scaleLabel = {
        display: true
      };
      let unit = dataset.unit || metric.unit;
      let axis_label_fn = metric.callback;

      if (!axis_label_fn) {
        scaleLabel = {};

        axis_label_fn = function (value, index, values) {
          // eslint-disable-line no-unused-vars
          value = round_from_metric(metric, value);
          return `${value} ${unit}`;
        };
      }

      if (metric.display_scale_label) {
        scaleLabel.labelString = metric.unit;
      }

      let axisDef = {
        id: dataset.yAxisID,
        type: 'linear',
        position: metric.axisPosition,
        scaleLabel: scaleLabel,
        grid: {
          color: '#5a658340',
          display: should_have_gridlines
        },
        ticks: {
          callback: axis_label_fn
        }
      };
      ['min', 'max', 'beginAtZero'].forEach(function (propName) {
        let val = metric[propName];

        if (val != null) {
          axisDef[propName] = val;
        }
      });
      return axisDef;
    }

  }) || _class;

  _exports.default = SgbDeviceLineChart;
});