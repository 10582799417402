define("sgb-object-graphs/templates/components/sgb-hours-interval-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{#if @route}}
    <LinkTo @route={{this.route}} @models={{array @start_hours_ago @end_hours_ago}} class={{this.class}}>
      {{humanize-interval @start_hours_ago @end_hours_ago }}
    </LinkTo>
  {{else}}
    <a href="#" class="{{this.class}}" {{on "click" (fn this.changeInterval @start_hours_ago @end_hours_ago)}}>
      {{humanize-interval @start_hours_ago @end_hours_ago}}
    </a>
  {{/if}}
  
  */
  {
    "id": "MtmxjhK3",
    "block": "{\"symbols\":[\"@end_hours_ago\",\"@start_hours_ago\",\"@route\"],\"statements\":[[6,[37,4],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[16,0,[32,0,[\"class\"]]]],[[\"@route\",\"@models\"],[[32,0,[\"route\"]],[30,[36,3],[[32,2],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],[[32,2],[32,1]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[[32,0,[\"class\"]]]]],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"changeInterval\"]],[32,2],[32,1]],null]],null],[12],[2,\"\\n    \"],[1,[30,[36,2],[[32,2],[32,1]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"humanize-interval\",\"array\",\"if\"]}",
    "moduleName": "sgb-object-graphs/templates/components/sgb-hours-interval-link.hbs"
  });

  _exports.default = _default;
});