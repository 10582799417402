define("ember-models-table/components/models-table/themes/ember-paper/row-select-checkbox", ["exports", "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-checkbox", "@ember-decorators/component", "ember-models-table/components/models-table/row-select-checkbox"], function (_exports, _rowSelectCheckbox, _component, _rowSelectCheckbox2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   * @class EpModelsTableRowSelectCheckbox
   * @namespace Components
   * @extends Components.ModelsTableRowSelectCheckbox
   */
  let EpModelsTableRowSelectCheckboxComponent = _exports.default = (_dec = (0, _component.layout)(_rowSelectCheckbox.default), _dec(_class = class EpModelsTableRowSelectCheckboxComponent extends _rowSelectCheckbox2.default {}) || _class);
});