define("ember-models-table/templates/components/models-table/row-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VJG6ttWE",
    "block": "{\"symbols\":[\"RowExpand\",\"&default\"],\"statements\":[[6,[37,2],[[30,[36,1],[[32,0,[\"expandedRowComponent\"]]],[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[32,0,[\"record\"]],[32,0,[\"visibleProcessedColumns\"]],[32,0,[\"index\"]],[32,0,[\"clickOnRow\"]],[32,0,[\"themeInstance\"]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[15,\"colspan\",[32,0,[\"cellColspan\"]]],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,[32,1],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"let\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/row-expand.hbs"
  });
});