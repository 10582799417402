define("ember-models-table/themes/plain-html", ["exports", "ember-models-table/themes/default"], function (_exports, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @class PlainHtmlTheme
   * @namespace Themes
   * @extends Themes.Default
   */
  class PlainHtmlTheme extends _default.default {
    constructor(...args) {
      super(...args);
      /**
       * @property componentsPath
       * @type string
       * @default 'models-table/themes/plain-html/'
       */
      _defineProperty(this, "componentsPath", 'models-table/themes/plain-html/');
      /**
       * @property columnsDropdownComponent
       * @type string
       * @default 'models-table/themes/plain-html/columns-dropdown'
       */
      /**
       * @property rowFilteringCellComponent
       * @type string
       * @default 'models-table/themes/plain-html/row-filtering-cell'
       */
      /**
       * @property summaryComponent
       * @type string
       * @default 'models-table/themes/plain-html/summary'
       */
      /**
       * @property headerWrapper
       * @type string
       * @default 'models-table-header'
       */
      _defineProperty(this, "headerWrapper", 'models-table-header');
      /**
       * @property tfooterInternalWrapper
       * @type string
       * @default 'models-table-footer'
       */
      _defineProperty(this, "tfooterInternalWrapper", 'models-table-footer');
      /**
       * @property paginationInternalWrapper
       * @type string
       * @default 'pagination-wrapper'
       */
      _defineProperty(this, "paginationInternalWrapper", 'pagination-wrapper');
      /**
       * @property currentPageSizeSelectWrapper
       * @type string
       * @default 'page-size-select-wrapper'
       */
      _defineProperty(this, "currentPageSizeSelectWrapper", 'page-size-select-wrapper');
      /**
       * @property columnsDropdownWrapper
       * @type string
       * @default 'dropdown'
       */
      _defineProperty(this, "columnsDropdownWrapper", 'columns-dropdown');
      /**
       * @property clearFilterIcon
       * @type string
       * @default 'fa fa-times form-control-feedback'
       */
      _defineProperty(this, "clearFilterIcon", 'fa fa-times form-control-feedback');
      /**
       * @property clearAllFiltersIcon
       * @type string
       * @default 'fa fa-times'
       */
      _defineProperty(this, "clearAllFiltersIcon", 'fa fa-times');
      /**
       * @property sortAscIcon
       * @type string
       * @default 'fa fa-sort-asc'
       */
      _defineProperty(this, "sortAscIcon", 'fa fa-sort-asc');
      /**
       * @property sortDescIcon
       * @type string
       * @default 'fa fa-sort-desc'
       */
      _defineProperty(this, "sortDescIcon", 'fa fa-sort-desc');
      /**
       * @property columnVisibleIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "columnVisibleIcon", 'fa fa-check-square-o');
      /**
       * @property columnHiddenIcon
       * @type string
       * @default 'fa fa-square-o'
       */
      _defineProperty(this, "columnHiddenIcon", 'fa fa-square-o');
      /**
       * @property navFirstIcon
       * @type string
       * @default 'fa fa-angle-double-left'
       */
      _defineProperty(this, "navFirstIcon", 'fa fa-angle-double-left');
      /**
       * @property navPrevIcon
       * @type string
       * @default 'fa fa-angle-left'
       */
      _defineProperty(this, "navPrevIcon", 'fa fa-angle-left');
      /**
       * @property navNextIcon
       * @type string
       * @default 'fa fa-angle-right'
       */
      _defineProperty(this, "navNextIcon", 'fa fa-angle-right');
      /**
       * @property navLastIcon
       * @type string
       * @default 'fa fa-angle-double-right'
       */
      _defineProperty(this, "navLastIcon", 'fa fa-angle-double-right');
      /**
       * @property caretIcon
       * @type string
       * @default 'caret'
       */
      _defineProperty(this, "caretIcon", 'caret');
      /**
       * @property expandRowIcon
       * @type string
       * @default 'fa fa-plus'
       */
      _defineProperty(this, "expandRowIcon", 'fa fa-plus');
      /**
       * @property expandAllRowsIcon
       * @type string
       * @default 'fa fa-plus'
       */
      _defineProperty(this, "expandAllRowsIcon", 'fa fa-plus');
      /**
       * @property collapseRowIcon
       * @type string
       * @default 'fa fa-minus'
       */
      _defineProperty(this, "collapseRowIcon", 'fa fa-minus');
      /**
       * @property collapseAllRowsIcon
       * @type string
       * @default 'fa fa-minus'
       */
      _defineProperty(this, "collapseAllRowsIcon", 'fa fa-minus');
      /**
       * @property selectAllRowsIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "selectAllRowsIcon", 'fa fa-check-square-o');
      /**
       * @property selectSomeRowsIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "selectSomeRowsIcon", 'fa fa-minus-square-o');
      /**
       * @property deselectAllRowsIcon
       * @type string
       * @default 'fa fa-square-o'
       */
      _defineProperty(this, "deselectAllRowsIcon", 'fa fa-square-o');
      /**
       * @property selectRowIcon
       * @type string
       * @default 'fa fa-check-square-o'
       */
      _defineProperty(this, "selectRowIcon", 'fa fa-check-square-o');
      /**
       * @property deselectRowIcon
       * @type string
       * @default 'fa fa-square-o'
       */
      _defineProperty(this, "deselectRowIcon", 'fa fa-square-o');
    }
  }
  _exports.default = PlainHtmlTheme;
});