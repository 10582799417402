define("ember-models-table/templates/components/models-table/row-select-all-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OpTl0jJQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"button\"],[15,\"onclick\",[32,0,[\"doToggleAllSelection\"]]],[15,0,[30,[36,0],[[32,0,[\"themeInstance\",\"buttonLink\"]],\" toggle-all\"],null]],[14,4,\"button\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[30,[36,1],[[30,[36,2],[[32,0,[\"selectedItems\",\"length\"]],[32,0,[\"data\",\"length\"]]],null],[32,0,[\"themeInstance\",\"selectAllRowsIcon\"]],[30,[36,1],[[32,0,[\"selectedItems\",\"length\"]],[32,0,[\"themeInstance\",\"selectSomeRowsIcon\"]],[32,0,[\"themeInstance\",\"deselectAllRowsIcon\"]]],null]],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"is-equal\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/row-select-all-checkbox.hbs"
  });
});