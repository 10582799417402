define("ember-toggle/components/x-toggle-label", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @show}}
    <label
      for={{@switchId}}
      class="toggle-text toggle-prefix {{this.type}}-label"
      ...attributes
      {{on 'click' this.clickLabel}}
    >
      {{#if (has-block)}}
        {{yield @label}}
      {{else}}
        {{@label}}
      {{/if}}
    </label>
  {{/if}}
  */
  {
    "id": "jol9jy+6",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@switchId\",\"&attrs\",\"@show\"],\"statements\":[[6,[37,1],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"label\"],[16,\"for\",[32,3]],[16,0,[31,[\"toggle-text toggle-prefix \",[32,0,[\"type\"]],\"-label\"]]],[17,4],[4,[38,0],[\"click\",[32,0,[\"clickLabel\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "moduleName": "ember-toggle/components/x-toggle-label.hbs"
  });
  let XToggleLabel = _exports.default = (_dec = Ember._action, (_class = class XToggleLabel extends _component.default {
    get type() {
      return this.args.value ? 'on' : 'off';
    }
    clickLabel(e) {
      e.stopPropagation();
      e.preventDefault();
      this.args.sendToggle(this.args.value);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "clickLabel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clickLabel"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XToggleLabel);
});