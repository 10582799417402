define("sgb-object-graphs/components/sgb-base-chart", ["exports", "@ember-decorators/component", "sgb-object-graphs/templates/components/sgb-base-chart"], function (_exports, _component, _sgbBaseChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RemoveOnlyZeroMetrics = void 0;
  _exports.applyProcessors = applyProcessors;
  _exports.default = void 0;
  _exports.dump_current = dump_current;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  const DEBUG = false; // TODO: make it dependent on user role?

  function applyProcessors(target, processors) {
    dump_current(target);
    processors.forEach(function (proc) {
      debug(proc);

      if (target == null) {
        debug('target is null, thus skipping processor');
        return;
      }

      target = proc.process(target);
      dump_current(target);
    });
    return target;
  } // TODO: re-disable console statements and export debug instead?
  // TODO: also, introduce unexpected data, no action needed && unexpected-notify-devs


  function debug(msg) {
    if (DEBUG) {
      console.debug(msg);
    }
  }

  class RemoveOnlyZeroMetrics {
    process(openTsdbData) {
      let metricsWithNonZeroValues = openTsdbData.filter(single_tsdb_data => single_tsdb_data.dps.filter(_ref => {
        let [ts, v] = _ref;
        return v != 0;
      } // eslint-disable-line no-unused-vars
      ).length > 0);

      if (metricsWithNonZeroValues.length == 0) {
        console.warn('tsdb data only had 0 values, hence no point showing the chart');
        return null;
      }

      return metricsWithNonZeroValues;
    }

  }

  _exports.RemoveOnlyZeroMetrics = RemoveOnlyZeroMetrics;

  window.__CLASSIC_OWN_CLASSES__.set(RemoveOnlyZeroMetrics, false);

  function dump_current(obj) {
    // so that in console log I can observer the state  of the variable when it was logged, not the state when looking at it
    debug(JSON.stringify(obj)); // eslint-disable-line no-unreachable
  }

  let SgbBaseChart = (_dec = (0, _component.layout)(_sgbBaseChart.default), _dec2 = Ember.computed('openTsdbData'), _dec3 = Ember.computed('chartjsOptions'), _dec4 = Ember.computed('openTsdbData', 'temperatureUnit', 'fontColor'), _dec5 = Ember.computed('chartjsOptions.data'), classic(_class = _dec(_class = (_class2 = class SgbBaseChart extends Ember.Component {
    get openTsdbDataJsonString() {
      return JSON.stringify(this.openTsdbData);
    }

    get chartjsOptionsJsonString() {
      return JSON.stringify(this.chartjsOptions);
    } // TODO: temperatureUnit is business logic, move  back - probably can make parent comp's metrics prop depend on it
    // TODO: these should be migrated to sgb-tsdb-ember-base-chart.js


    get chartjsOptions() {
      let data = this.getData(); // TODO: somehow there is still a flicker here when there is no data to display

      if (!data) {
        return {};
      }

      let options = this.getOptions(data);
      return {
        data: data,
        options: options
      };
    }

    get shouldShowChart() {
      return this.get('chartjsOptions.data') != null;
    }

    getData() {
      let d = this.openTsdbData;

      if (!d) {
        return null;
      }

      return this.opentsdb_to_chartjs(d);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "openTsdbDataJsonString", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "openTsdbDataJsonString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chartjsOptionsJsonString", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "chartjsOptionsJsonString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "chartjsOptions", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "chartjsOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldShowChart", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldShowChart"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = SgbBaseChart;
});