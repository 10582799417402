define("ember-basic-dropdown/templates/components/basic-dropdown-optional-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5XXUNkBP",
    "block": "{\"symbols\":[\"Element\",\"&attrs\",\"&default\",\"@htmlTag\"],\"statements\":[[6,[37,4],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,1],[[30,[36,0],[[32,4]],null]],null]],[[\"tagName\"],[[32,4]]]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[[17,2]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,3,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[17,2],[12],[2,\"\\n    \"],[18,3,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-element\",\"ensure-safe-component\",\"component\",\"let\",\"if\"]}",
    "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown-optional-tag.hbs"
  });
});