define("ember-models-table/templates/components/models-table/themes/ember-paper/cell-content-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "a4wCvnxY",
    "block": "{\"symbols\":[\"value\",\"&default\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,0,[\"record\"]],[32,0,[\"column\",\"propertyName\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"paper-input\",[],[[\"@value\",\"@onChange\"],[[32,0,[\"value\"]],[30,[36,1],[[30,[36,0],[[32,1]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"mut\",\"fn\",\"get\",\"let\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/cell-content-edit.hbs"
  });
});