define("ember-models-table/templates/components/models-table/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c8sdIhqu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[30,[36,1],[[32,0,[\"themeInstance\",\"form\"]],\" globalSearch\"],null]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[32,0,[\"themeInstance\",\"formElementWrapper\"]]],[12],[2,\"\\n      \"],[10,\"label\"],[15,\"for\",[32,0,[\"inputId\"]]],[12],[1,[32,0,[\"themeInstance\",\"searchLabelMsg\"]]],[13],[2,\" \"],[8,\"input\",[[16,1,[32,0,[\"inputId\"]]],[16,0,[30,[36,1],[[32,0,[\"themeInstance\",\"input\"]],\" filterString\"],null]],[16,\"placeholder\",[32,0,[\"themeInstance\",\"searchPlaceholderMsg\"]]],[24,4,\"text\"]],[[\"@value\",\"@enter\"],[[32,0,[\"value\"]],[32,0,[\"noop\"]]]],null],[2,\"\\n\"],[6,[37,2],[[32,0,[\"globalFilterUsed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[15,0,[31,[\"clearFilterIcon \",[32,0,[\"themeInstance\",\"clearFilterIcon\"]]]]],[14,\"role\",\"button\"],[15,\"aria-label\",[32,0,[\"themeInstance\",\"clearGlobalFilterMsg\"]]],[15,\"onclick\",[30,[36,0],[[32,0,[\"clearGlobalFilter\"]]],null]],[12],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"concat\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/global-filter.hbs"
  });
});