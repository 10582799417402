define("ember-models-table/templates/components/models-table/themes/ember-paper/page-size-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "y/Z4MYoU",
    "block": "{\"symbols\":[\"PageSizeSelect\",\"&default\"],\"statements\":[[6,[37,3],[[30,[36,2],null,[[\"Select\"],[[30,[36,1],[[32,0,[\"themeInstance\",\"selectComponent\"]]],[[\"id\",\"options\",\"value\",\"type\",\"label\",\"themeInstance\",\"class\"],[[32,0,[\"inputId\"]],[32,0,[\"pageSizeOptions\"]],[32,0,[\"pageSize\"]],[32,0,[\"type\"]],[32,0,[\"themeInstance\",\"rowsCountMsg\"]],[32,0,[\"themeInstance\"]],\"changePageSize\"]]]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,1,[\"Select\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"hash\",\"let\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/page-size-select.hbs"
  });
});