define("ember-models-table/components/models-table/columns-dropdown", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/columns-dropdown"], function (_exports, _component, _columnsDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
   * Dropdown with list of table's columns used within [models-table](Components.ModelsTable.html).
   *
   * It allows to toggle visibility for column sets or single column.
   *
   * Columns that should not be hidden must have property [mayBeHidden](Utils.ModelsTableColumn.html#property_mayBeHidden) set to `false`.
   *
   * Usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.ColumnsDropdown />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```hbs
   * <ModelsTable @data={{data}} @columns={{columns}} as |MT|>
   *   <MT.ColumnsDropdown as |CD|>
   *     {{#if MT.columnDropdownOptions.showAll}}
   *       <button {{action MT.showAllColumns}}>Show all</button>
   *     {{/if}}
   *     {{#if MT.columnDropdownOptions.hideAll}}
   *       <button {{action MT.hideAllColumns}}>Hide all</button>
   *     {{/if}}
   *     {{#if MT.columnDropdownOptions.restoreDefaults}}
   *       <button {{action MT.restoreDefaultVisibility}}>Restore default visibility</button>
   *     {{/if}}
   *     {{#each MT.columnDropdownOptions.columnSets as |columnSet|}}
   *       <button {{action MT.toggleColumnSetVisibility columnSet}}>{{columnSet.label}}</button>
   *     {{/each}}
   *     {{#each MT.processedColumns as |column|}}
   *       {{#if column.mayBeHidden}}
   *         <button {{action MT.toggleHidden column}}>
   *           <i class={{if column.toggleColumnVisibility MT.themeInstance.columnVisibleIcon MT.themeInstance.columnHiddenIcon}}></i>
   *           {{column.title}}
   *         </button>
   *       {{/if}}
   *     {{/each}}
   *   </MT.ColumnsDropdown>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * @namespace Components
   * @class ModelsTableColumnsDropdown
   * @extends Ember.Component
   */
  let ColumnsDropdownComponent = _exports.default = (_dec = (0, _component.layout)(_columnsDropdown.default), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class ColumnsDropdownComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * Bound from [ModelsTable.processedColumns](Components.ModelsTable.html#property_processedColumns)
       *
       * @property processedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "processedColumns", null);
      /**
       * Bound from [ModelsTable.columnDropdownOptions](Components.ModelsTable.html#property_columnDropdownOptions)
       *
       * @property columnDropdownOptions
       * @type ColumnDropdownOptions
       * @default null
       */
      _defineProperty(this, "columnDropdownOptions", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Closure action [ModelsTable.showAllColumns](Components.ModelsTable.html#event_showAllColumns)
       *
       * @event showAllColumns
       */
      _defineProperty(this, "showAllColumns", null);
      /**
       * Closure action [ModelsTable.hideAllColumns](Components.ModelsTable.html#event_hideAllColumns)
       *
       * @event hideAllColumns
       */
      _defineProperty(this, "hideAllColumns", null);
      /**
       * Closure action [ModelsTable.restoreDefaultVisibility](Components.ModelsTable.html#event_restoreDefaultVisibility)
       *
       * @event restoreDefaultVisibility
       */
      _defineProperty(this, "restoreDefaultVisibility", null);
      /**
       * Closure action [ModelsTable.toggleColumnSet](Components.ModelsTable.html#event_toggleColumnSet)
       *
       * @event toggleColumnSet
       */
      _defineProperty(this, "toggleColumnSet", null);
      /**
       * Closure action [ModelsTable.toggleHidden](Components.ModelsTable.html#event_toggleHidden)
       *
       * @event toggleHidden
       */
      _defineProperty(this, "toggleHidden", null);
    }
    /**
     * @event doShowAllColumns
     * @param {Event} e
     * @protected
     */
    doShowAllColumns(e) {
      this.showAllColumns();
      if (e) {
        e.stopPropagation();
      }
      return false;
    }

    /**
     * @event doHideAllColumns
     * @param {Event} e
     * @protected
     */
    doHideAllColumns(e) {
      this.hideAllColumns();
      if (e) {
        e.stopPropagation();
      }
      return false;
    }

    /**
     * @event doRestoreDefaultVisibility
     * @param {Event} e
     * @protected
     */
    doRestoreDefaultVisibility(e) {
      this.restoreDefaultVisibility();
      if (e) {
        e.stopPropagation();
      }
      return false;
    }

    /**
     * @event doToggleColumnSet
     * @param {ColumnSet} columnSet
     * @param {Event} e
     * @protected
     */
    doToggleColumnSet(columnSet, e) {
      this.toggleColumnSet(columnSet);
      if (e) {
        e.stopPropagation();
      }
      return false;
    }

    /**
     * @event doToggleHidden
     * @param {Utils.ModelsTableColumn} column
     * @param {Event} e
     * @protected
     */
    doToggleHidden(column, e) {
      this.toggleHidden(column);
      if (e) {
        e.stopPropagation();
      }
      return false;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "doShowAllColumns", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "doShowAllColumns"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doHideAllColumns", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "doHideAllColumns"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doRestoreDefaultVisibility", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "doRestoreDefaultVisibility"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doToggleColumnSet", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleColumnSet"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doToggleHidden", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "doToggleHidden"), _class2.prototype)), _class2)) || _class);
});