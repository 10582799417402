define("ember-models-table/templates/components/models-table/table-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3KTTaAd2",
    "block": "{\"symbols\":[\"TableFooter\",\"column\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,5],null,[[\"shouldAddExtraColumn\"],[[32,0,[\"shouldAddExtraColumn\"]]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"shouldAddExtraColumn\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"td\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"visibleProcessedColumns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2,[\"componentForFooterCell\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,2],[[32,2,[\"componentForFooterCell\"]]],[[\"selectedItems\",\"expandedItems\",\"data\",\"mappedSelectedItems\",\"mappedExpandedItems\",\"mappedData\"],[[32,0,[\"selectedItems\"]],[32,0,[\"expandedItems\"]],[32,0,[\"data\"]],[30,[36,1],[[32,2,[\"propertyName\"]],[30,[36,0],[[32,2,[\"propertyName\"]],[32,0,[\"selectedItems\"]]],null]],null],[30,[36,1],[[32,2,[\"propertyName\"]],[30,[36,0],[[32,2,[\"propertyName\"]],[32,0,[\"expandedItems\"]]],null]],null],[30,[36,1],[[32,2,[\"propertyName\"]],[30,[36,0],[[32,2,[\"propertyName\"]],[32,0,[\"data\"]]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"td\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"map-by\",\"if\",\"component\",\"-track-array\",\"each\",\"hash\",\"let\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/table-footer.hbs"
  });
});