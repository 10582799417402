define("sgb-object-graphs/components/sgb-ajax-singlestat-number", ["exports", "@ember-decorators/component", "sgb-object-graphs/components/sgb-ajax-base-chart", "sgb-object-graphs/templates/components/sgb-ajax-singlestat-number"], function (_exports, _component, _sgbAjaxBaseChart, _sgbAjaxSinglestatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let SgbAjaxSinglestatNumber = (_dec = (0, _component.layout)(_sgbAjaxSinglestatNumber.default), _dec2 = (0, _component.classNames)('sgb-ajax-singlestat-number'), _dec3 = Ember.computed('top_group_id', 'metric_alias', 'start_hours_ago', 'end_hours_ago'), _dec4 = Ember.computed('metric'), _dec5 = Ember.computed('metric_alias'), _dec6 = Ember.computed('value'), _dec7 = Ember.computed('openTsdbData.n'), classic(_class = _dec(_class = _dec2(_class = (_class2 = class SgbAjaxSinglestatNumber extends _sgbAjaxBaseChart.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "shouldUpdateGraphData", true);

      _defineProperty(this, "onlyShowDataLines", true);
    }

    get chartInfo() {
      let top_group_id = this.top_group_id;
      let metric_alias = this.metric_alias;
      let start_hours_ago = this.start_hours_ago;
      let end_hours_ago = this.end_hours_ago; // TODO: define this info also once & convert for both ember & node express?

      return {
        componentName: null,
        url: `groups/${top_group_id}/${start_hours_ago}-${end_hours_ago}/single-stat-number/${metric_alias}`,
        // only set refreshUrl when end_hours_ago not defined
        refreshUrl: !end_hours_ago ? `groups/${top_group_id}/1-0/single-stat-number/${metric_alias}` : null
      };
    }

    get title() {
      let metric = this.metric;

      if (!metric) {
        return 'Unknown title';
      }

      return `${metric.label}`;
    }

    get metric() {
      let metric_alias = this.metric_alias;
      return SingleStatMetrics[metric_alias];
    }

    get hasValue() {
      let value = this.value;
      return value != null && !isNaN(value);
    }

    get value() {
      return parseInt(this.get('openTsdbData.n'));
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "chartInfo", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "chartInfo"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "title", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "title"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "metric", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "metric"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hasValue", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "hasValue"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "value", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "value"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = SgbAjaxSinglestatNumber;
});