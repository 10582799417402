define("ember-notify/templates/components/ember-notify/message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0oZc9GKY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"message\"]],[30,[36,0],[[32,0],\"close\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"\"],[24,0,\"close\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"close\"],null],[12],[2,\"×\"],[13],[2,\"\\n\"],[2,\"  \"],[10,\"span\"],[14,0,\"message\"],[12],[1,[32,0,[\"message\",\"text\"]]],[2,[32,0,[\"message\",\"html\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "ember-notify/templates/components/ember-notify/message.hbs"
  });
});