define("ember-changeset/index", ["exports", "validated-changeset", "ember-changeset/utils/merge-deep", "ember-changeset/utils/is-object", "ember-changeset/utils/is-belongs-to-relationship", "ember-changeset/utils/is-leaf"], function (_exports, _validatedChangeset, _mergeDeep, _isObject, _isBelongsToRelationship, _isLeaf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Changeset = Changeset;
  _exports.EmberChangeset = void 0;
  _exports.changeset = changeset;
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CHANGES = '_changes';
  const ERRORS = '_errors';
  const CONTENT = '_content';
  const defaultValidatorFn = () => true;
  let EmberChangeset = _exports.EmberChangeset = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class EmberChangeset extends _validatedChangeset.BufferedChangeset {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, '_changes', _descriptor, this);
      _initializerDefineProperty(this, '_errors', _descriptor2, this);
      _initializerDefineProperty(this, '_content', _descriptor3, this);
      // override base class
      _defineProperty(this, "getDeep", Ember.get);
    }
    // override base class
    safeGet(obj, key) {
      return Ember.get(obj, key);
    }

    /**
     * Manually add an error to the changeset. If there is an existing
     * error or change for `key`, it will be overwritten.
     *
     * @method addError
     */
    addError(key, error) {
      super.addError(key, error);
      Ember.notifyPropertyChange(this, ERRORS);
      // Notify that `key` has changed.
      Ember.notifyPropertyChange(this, key);

      // Return passed-in `error`.
      return error;
    }

    /**
     * Manually push multiple errors to the changeset as an array.
     *
     * @method pushErrors
     */
    pushErrors(key, ...newErrors) {
      const {
        value,
        validation
      } = super.pushErrors(key, ...newErrors);
      Ember.notifyPropertyChange(this, ERRORS);
      Ember.notifyPropertyChange(this, key);
      return {
        value,
        validation
      };
    }

    /**
     * Sets property or error on the changeset.
     * Returns value or error
     */
    _setProperty({
      key,
      value,
      oldValue
    }) {
      super._setProperty({
        key,
        value,
        oldValue
      });

      // Happy path: notify that `key` was added.
      Ember.notifyPropertyChange(this, CHANGES);
      Ember.notifyPropertyChange(this, key);
    }

    /**
     * Notifies virtual properties set on the changeset of a change.
     * You can specify which keys are notified by passing in an array.
     *
     * @private
     * @param {Array} keys
     * @return {Void}
     */
    _notifyVirtualProperties(keys) {
      keys = super._notifyVirtualProperties(keys);
      (keys || []).forEach(key => Ember.notifyPropertyChange(this, key));
      return;
    }

    /**
     * Deletes a key off an object and notifies observers.
     */
    _deleteKey(objName, key = '') {
      const result = super._deleteKey(objName, key);
      Ember.notifyPropertyChange(this, `${objName}.${key}`);
      Ember.notifyPropertyChange(this, objName);
      return result;
    }

    /**
     * Executes the changeset if in a valid state.
     *
     * @method execute
     */
    execute() {
      if (this.isValid && this.isDirty) {
        let content = this[CONTENT];
        let changes = this[CHANGES];
        // we want mutation on original object
        // @tracked
        this[CONTENT] = (0, _mergeDeep.default)(content, changes, {
          safeGet: Ember.get,
          safeSet: Ember.set
        });
      }
      return this;
    }

    /**
     * Proxied getter
     * TODO: Evaluate Ember Data particularities here and see how we can use
     * base class `get`
     *
     * @method get
     */
    get(key) {
      // 'person'
      // 'person.username'
      let [baseKey, ...remaining] = key.split('.');

      // 1. lets first check CHANGES
      if (Object.prototype.hasOwnProperty.call(this[CHANGES], baseKey)) {
        let changes = this[CHANGES];
        let result;
        if (remaining.length > 0) {
          let c = changes[baseKey];
          result = this.getDeep((0, _validatedChangeset.normalizeObject)(c), remaining.join('.'));
          if (typeof result !== 'undefined') {
            return result;
          }
        } else {
          result = changes[baseKey];
        }
        if (result !== undefined && result !== null && (0, _isObject.default)(result)) {
          // 1. knock out any class Change{} instances
          result = (0, _validatedChangeset.normalizeObject)(result);

          // 2. then ensure sibling keys are merged with the "result"
          let content = this[CONTENT];

          // Merge the content with the changes to have a complete object for a nested property.
          // Given a object with nested property and multiple properties inside of it, if the
          // requested key is the top most nested property and we have changes in of the properties, we need to
          // merge the original model data with the changes to have the complete object.
          // eg. model = { user: { name: 'not changed', email: 'changed@prop.com'} }
          if (!Array.isArray(result) && (0, _isObject.default)(content[baseKey]) && !(0, _isBelongsToRelationship.isBelongsToRelationship)(content[baseKey]) && !(0, _isLeaf.isLeafInChanges)(key, changes)) {
            let netKeys = Object.keys(content[baseKey]).filter(k => !this.safeGet(result, k));
            if (netKeys.length === 0) {
              return result;
            }

            // 3. Ok merge sibling keys.  Yes, shallow clone, but users should treat `c.get` as read only.  Mods to data
            // structures should happen through `c.set(...)` or `{{changeset-set ...}}`
            const data = Object.assign(Object.create(Object.getPrototypeOf(content[baseKey])), result);
            netKeys.forEach(k => {
              data[k] = this.getDeep(content, `${baseKey}.${k}`);
            });
            return data;
          }
          return result;
        }
        if (result) {
          return result.value;
        }
      }

      // 2. return getters/setters/methods on BufferedProxy instance
      if (typeof this[key] !== 'undefined') {
        return this[key];
      } else if (this[baseKey]) {
        const v = this[baseKey];
        if ((0, _isObject.default)(v)) {
          const result = this.getDeep(v, remaining.join('.'));
          return result;
        }
      }

      // 3. Lastly return on underlying object if previous cases dont apply
      const result = this.getDeep(this[CONTENT], key);
      return result;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, '_changes', [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, '_errors', [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, '_content', [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  /**
   * Creates new changesets.
   */
  function changeset(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
    (true && !(Boolean(obj)) && Ember.assert('Underlying object for changeset is missing', Boolean(obj)));
    (true && !(!Array.isArray(obj)) && Ember.assert('Array is not a valid type to pass as the first argument to `changeset`', !Array.isArray(obj)));
    if (options.changeset) {
      return new options.changeset(obj, validateFn, validationMap, options);
    }
    const c = new EmberChangeset(obj, validateFn, validationMap, options);
    return c;
  }

  /**
   * Creates new changesets.
   * @function Changeset
   */
  function Changeset(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
    const c = changeset(obj, validateFn, validationMap, options);
    return new Proxy(c, {
      get(targetBuffer, key /*, receiver*/) {
        const res = targetBuffer.get(key.toString());
        return res;
      },
      set(targetBuffer, key, value /*, receiver*/) {
        targetBuffer.set(key.toString(), value);
        return true;
      }
    });
  }
  class ChangesetKlass {
    /**
     * Changeset factory
     * TODO: deprecate in favor of factory function
     *
     * @class ChangesetKlass
     * @constructor
     */
    constructor(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
      const c = changeset(obj, validateFn, validationMap, options);
      return new Proxy(c, {
        get(targetBuffer, key /*, receiver*/) {
          const res = targetBuffer.get(key.toString());
          return res;
        },
        set(targetBuffer, key, value /*, receiver*/) {
          targetBuffer.set(key.toString(), value);
          return true;
        }
      });
    }
  }
  _exports.default = ChangesetKlass;
});