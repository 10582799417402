define("ember-websockets/services/websockets", ["exports", "ember-websockets/helpers/websocket-proxy", "ember-websockets/helpers"], function (_exports, _websocketProxy, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let WebSocketService = _exports.default = (_dec = Ember.computed(), (_class = class WebSocketService extends Ember.Service {
    /*
      A hash of open websocket connections. This
      allows multiple components to share the same connection.
       {
        'websocket-url': WebSocket Proxy object
      }
    */
    get sockets() {
      return {};
    }

    /*
      socketFor returns a websocket proxy object. On this object there is a property `socket`
      which contains the actual websocket object. This websocket object is cached based off of the url meaning
      multiple requests for the same socket will return the same object.
    */
    socketFor(url, protocols = []) {
      /*
        Websockets allows either a string or array of strings to be passed as the second argument.
        Normalize both cases into an array of strings so we can just deal with arrays.
      */
      if (typeof protocols === 'string') {
        protocols = [protocols];
      }

      /*
        Normalize the url as native websockets add a / to the end of the url:
        http://example.com:8000 becomes: http://example.com:8000/
         Since the url will be used as a key will need to make sure that it does not
        contain '.' as it will throw ember off
      */
      const normalizedUrl = (0, _helpers.normalizeURL)(url);
      const cleanedUrl = (0, _helpers.cleanURL)(normalizedUrl);

      /*
       */
      const existingProxy = Ember.get(this, `sockets.${cleanedUrl}`);
      if (existingProxy && this.isWebSocketOpen(existingProxy.socket)) {
        return existingProxy;
      }

      /*
        we can get to this place if the websocket has been closed and we are trying to reopen
        or we are creating a proxy for the first time
      */
      const newWebSocket = this.createSocket(normalizedUrl, protocols);
      if (existingProxy) {
        /*
          If there is an existing socket in place we simply update the websocket object and not
          the whole proxy as we dont want to destroy the previous listeners.
        */
        Ember.set(existingProxy, 'socket', newWebSocket);
        return existingProxy;
      }
      const newProxy = this.createProxy(newWebSocket, protocols);
      Ember.set(this, `sockets.${cleanedUrl}`, newProxy);
      return newProxy;
    }
    closeSocketFor(url) {
      const cleanedUrl = (0, _helpers.cleanURL)((0, _helpers.normalizeURL)(url));
      const existingSocket = Ember.get(this, `sockets.${cleanedUrl}`);
      if (existingSocket) {
        existingSocket.socket.close();
      }
      delete this.sockets[cleanedUrl];
    }
    isWebSocketOpen(websocket) {
      return websocket.readyState !== WebSocket.CLOSED;
    }
    createSocket(url, options) {
      return new WebSocket(url, options);
    }
    createProxy(socket, protocols) {
      return _websocketProxy.default.create({
        content: this,
        protocols,
        socket
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "sockets", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sockets"), _class.prototype)), _class));
});