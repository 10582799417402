define("ember-models-table/helpers/option-is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(function optionIsObject([val] /*, hash*/) {
    return Ember.typeOf(val) === 'instance' || Ember.typeOf(val) === 'object';
  });
});