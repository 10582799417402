define("ember-models-table/templates/components/models-table/columns-hidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Tr+BC9JH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"td\"],[15,\"colspan\",[32,0,[\"columnsCount\"]]],[15,0,[32,0,[\"themeInstance\",\"noDataCell\"]]],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,1],null,[[\"columnsCount\",\"themeInstance\"],[[32,0,[\"columnsCount\"]],[32,0,[\"themeInstance\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"themeInstance\",\"allColumnsAreHiddenMsg\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"hash\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/columns-hidden.hbs"
  });
});