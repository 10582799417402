define("ember-models-table/templates/components/models-table/themes/plain-html/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WXLG13m1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,3],null,[[\"summary\"],[[32,0,[\"summary\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"summary\"]]],[2,\"\\n  \"],[10,\"button\"],[15,0,[30,[36,1],[\"clearFilters \",[30,[36,0],[[32,0,[\"anyFilterUsed\"]],\"emt-sr-only\"],null]],null]],[15,\"onclick\",[30,[36,2],[[32,0,[\"doClearFilters\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"clearAllFiltersIcon\"]]],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"emt-sr-only\"],[12],[1,[32,0,[\"themeInstance\",\"clearAllFiltersMsg\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unless\",\"concat\",\"fn\",\"hash\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/plain-html/summary.hbs"
  });
});