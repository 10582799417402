define("ember-models-table/templates/components/models-table/row-filtering", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "m9iWFBJl",
    "block": "{\"symbols\":[\"RowFiltering\",\"column\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],null,[[\"shouldAddExtraColumn\",\"RowFilteringCell\"],[[32,0,[\"shouldAddExtraColumn\"]],[30,[36,3],[[32,0,[\"themeInstance\",\"rowFilteringCellComponent\"]]],[[\"themeInstance\",\"data\",\"selectedItems\",\"expandedItems\",\"expandAllRows\",\"collapseAllRows\",\"toggleAllSelection\"],[[32,0,[\"themeInstance\"]],[32,0,[\"data\"]],[32,0,[\"selectedItems\"]],[32,0,[\"expandedItems\"]],[32,0,[\"expandAllRows\"]],[32,0,[\"collapseAllRows\"]],[32,0,[\"toggleAllSelection\"]]]]]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"shouldAddExtraColumn\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"th\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"shownColumns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"RowFilteringCell\"]],[],[[\"@column\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\",\"component\",\"hash\",\"let\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/row-filtering.hbs"
  });
});