define("ember-models-table/themes/ember-bootstrap-v3", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * @class EmberBootstrap3Theme
   * @namespace Themes
   * @extends Themes.Bootstrap3Theme
   */
  class EmberBootstrap3Theme extends _bootstrap.default {
    constructor(...args) {
      super(...args);
      /**
       * @property columnsDropdownComponent
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/columns-dropdown'
       */
      /**
       * @property dataGroupBySelectComponent
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/data-group-by-select'
       */
      /**
       * @property globalFilterComponent
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/global-filter'
       */
      /**
       * @property rowFilteringCellComponent
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/row-filtering-cell'
       */
      /**
       * @property summaryComponent
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/row-filtering-summary'
       */
      /**
       * @property rowSelectAllCheckboxComponent
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/row-select-all-checkbox'
       */
      /**
       * @property componentsPath
       * @type string
       * @default 'models-table/themes/ember-bootstrap-v3/'
       */
      _defineProperty(this, "componentsPath", 'models-table/themes/ember-bootstrap-v3/');
    }
  }
  _exports.default = EmberBootstrap3Theme;
});