define("ember-modal-dialog/templates/components/liquid-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jHoRh67E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[35,13]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,12],null,[[\"stack\",\"value\",\"class\"],[[35,11],[35,10],[30,[36,9],[\"liquid-dialog-container \",[35,8],\" \",[35,7]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[34,8],\" \",[34,7]]]],[12],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[34,1]],[15,\"onclick\",[30,[36,4],[[32,0],[35,2]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,12],null,[[\"stack\",\"value\",\"class\"],[[35,11],[35,10],[30,[36,9],[\"liquid-dialog-container \",[35,8],\" \",[35,7]],null]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[34,1]],[15,\"onclick\",[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n          \"],[18,1,null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"containerClassNamesString\",\"overlayClassNamesString\",\"onClickOverlay\",\"ignore-children\",\"action\",\"hasOverlay\",\"if\",\"wrapperClass\",\"wrapperClassNamesString\",\"concat\",\"value\",\"stack\",\"liquid-wormhole\",\"isOverlaySibling\"]}",
    "moduleName": "ember-modal-dialog/templates/components/liquid-dialog.hbs"
  });
});