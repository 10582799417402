define("ember-arg-types/-private/is-element-descriptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isElementDescriptor;
  //https://github.com/emberjs/ember.js/blob/d1ad76a2b22ce470639df3dfc6efb6864a70f588/packages/%40ember/-internals/metal/lib/decorator.ts
  function isElementDescriptor(...args) {
    let [maybeTarget, maybeKey, maybeDesc] = args;
    return (
      // Ensure we have the right number of args
      args.length === 3 && (
      // Make sure the target is a class or object (prototype)
      typeof maybeTarget === 'function' || typeof maybeTarget === 'object' && maybeTarget !== null) &&
      // Make sure the key is a string
      typeof maybeKey === 'string' && (
      // Make sure the descriptor is the right shape
      typeof maybeDesc === 'object' && maybeDesc !== null && 'enumerable' in maybeDesc && 'configurable' in maybeDesc ||
      // TS compatibility
      maybeDesc === undefined)
    );
  }
});