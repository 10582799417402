define("ember-models-table/components/models-table/group-summary-row", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/group-summary-row"], function (_exports, _component, _groupSummaryRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class ModelsTableGroupSummaryRow
   * @namespace Components
   * @extends Ember.Component
   */
  let GroupSummaryRowComponent = _exports.default = (_dec = (0, _component.layout)(_groupSummaryRow.default), _dec2 = (0, _component.classNames)('group-summary-row'), _dec3 = (0, _component.tagName)('tr'), _dec4 = Ember.computed.intersect('selectedItems', 'groupedItems'), _dec5 = Ember.computed.intersect('expandedItems', 'groupedItems'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class GroupSummaryRowComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'tr'
       */
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_selectedItems)
       *
       * @property selectedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "selectedItems", null);
      /**
       * Bound from [ModelsTable.selectedItems](Components.ModelsTable.html#property_expandedItems)
       *
       * @property expandedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "expandedItems", null);
      /**
       * Bound from [ModelsTable.groupedItems](Components.ModelsTable.html#property_groupedItems)
       *
       * @property groupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "groupedItems", null);
      /**
       * @property visibleGroupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "visibleGroupedItems", null);
      /**
       * @property selectedGroupedItems
       * @type object[]
       * @default null
       * @protected
       */
      _initializerDefineProperty(this, "selectedGroupedItems", _descriptor, this);
      /**
       * @property expandedGroupedItems
       * @type object[]
       * @default null
       * @protected
       */
      _initializerDefineProperty(this, "expandedGroupedItems", _descriptor2, this);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "selectedGroupedItems", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "expandedGroupedItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class) || _class);
});