define("ember-local-storage/initializers/local-storage-adapter", ["exports", "@ember-data/store", "ember-local-storage/helpers/import-export"], function (_exports, _store, _importExport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    if (!_store.default.prototype._emberLocalStoragePatched) {
      (true && !(false) && Ember.deprecate('The initializer has been deprecated and will be removed in version 3.0.0. Find more information how to remove that deprecation by visiting the url.', false, {
        for: 'ember-local-storage',
        id: 'ember-local-storage.initializers.local-storage-adapter',
        since: '2.0.5',
        until: '3.0.0',
        url: 'https://github.com/funkensturm/ember-local-storage#deprecations'
      }));
      _store.default.reopen({
        _emberLocalStoragePatched: true,
        importData: function (json, options) {
          return (0, _importExport.importData)(this, json, options);
        },
        exportData: function (types, options) {
          return (0, _importExport.exportData)(this, types, options);
        }
      });
    }
  }
  var _default = _exports.default = {
    name: 'local-storage-adapter',
    after: 'ember-data',
    initialize: initialize
  };
});