define("ember-window-resize/services/window-resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    init() {
      this._super(...arguments);
      this._onResize = event => {
        this._resizeHandler(event);
      };
      this._initialiseResizeListener();
    },
    destroy() {
      this._super(...arguments);
      this._destroyResizeListener();
    },
    _initialiseResizeListener() {
      window.addEventListener('resize', this._onResize);
    },
    _destroyResizeListener() {
      window.removeEventListener('resize', this._onResize);
    },
    _resizeHandler(event) {
      this.trigger('didResize', event);
    }
  });
});