define("ember-modal-dialog/components/liquid-tether-dialog", ["exports", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-tether-dialog"], function (_exports, _basicDialog, _liquidTetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _basicDialog.default.extend({
    layout: _liquidTetherDialog.default,
    targetAttachmentClass: Ember.computed('targetAttachment', function () {
      let targetAttachment = this.get('targetAttachment') || '';
      return `ember-modal-dialog-target-attachment-${Ember.String.dasherize(targetAttachment)}`;
    }),
    targetAttachment: null,
    attachment: null,
    didReceiveAttrs() {
      this._super(...arguments);
      if (!this.get('attachment')) {
        this.set('attachment', 'middle center');
      }
      if (!this.get('targetAttachment')) {
        this.set('targetAttachment', 'middle center');
      }
    },
    tetherClassPrefix: Ember.computed({
      get() {
        return 'liquid-tether';
      },
      set(key, val) {
        if (val) {
          return val;
        }
        return 'liquid-tether';
      }
    }),
    hasOverlay: true,
    tetherTarget: null // element, css selector, view instance, 'viewport', or 'scroll-handle'
    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in
  });
});