define("ember-models-table/templates/components/models-table/themes/ember-paper/expand-all-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ic0ehh9t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[32,0,[\"doExpandAllRows\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-icon\",[[16,0,[32,0,[\"themeInstance\",\"expandAllRowsIcon\"]]]],[[\"@icon\"],[[32,0,[\"themeInstance\",\"expandAllRowsIcon\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[8,\"paper-button\",[],[[\"@onClick\",\"@iconButton\"],[[32,0,[\"doCollapseAllRows\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"paper-icon\",[[16,0,[32,0,[\"themeInstance\",\"collapseAllRowsIcon\"]]]],[[\"@icon\"],[[32,0,[\"themeInstance\",\"collapseAllRowsIcon\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/expand-all-toggle.hbs"
  });
});