define("ember-arg-types/decorator", ["exports", "ember-arg-types/-private/is-element-descriptor", "ember-arg-types/-private/throw-console-error", "prop-types", "ember-get-config", "ember-arg-types/-private/closest-string", "@embroider/macros/runtime"], function (_exports, _isElementDescriptor, _throwConsoleError, _propTypes, _emberGetConfig, _closestString, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arg;
  _exports.forbidExtraArgs = forbidExtraArgs;
  const REGISTERED_ARGS = Symbol('args');
  const INTERCEPT_CLASS = 'ForbidExtraArgsIntercept';
  function shouldThrowErrors() {
    const throwErrors = _emberGetConfig.default['ember-arg-types']?.throwErrors;
    return Ember.isNone(throwErrors) ? true : throwErrors;
  }
  function getClassName(instance) {
    return instance.constructor.name === INTERCEPT_CLASS // if the current class is this override
    ? Object.getPrototypeOf(instance.constructor).name // get parent class name
    : instance.constructor.name; // use current class name
  }
  function createGetter(target, key, descriptor, validator) {
    if ((0, _runtime.macroCondition)(true)) {
      const registeredArgs = target[REGISTERED_ARGS] ?? new Set();
      registeredArgs.add(key);
      target[REGISTERED_ARGS] = registeredArgs;
    }
    const defaultInitializer = descriptor.initializer || descriptor.get || (() => undefined);
    return {
      get() {
        const argValue = this.args[key];
        const returnValue = argValue !== undefined ? argValue : defaultInitializer.call(this);
        if ((0, _runtime.macroCondition)(true)) {
          const shouldThrow = shouldThrowErrors();
          if (validator) {
            (0, _throwConsoleError.default)(() => {
              _propTypes.default.checkPropTypes({
                [key]: validator
              }, {
                [key]: returnValue
              }, 'prop', getClassName(this));
            }, shouldThrow);
          }
        }
        return returnValue;
      }
    };
  }
  function arg(...args) {
    if ((0, _isElementDescriptor.default)(...args)) {
      return createGetter(...args);
    }
    const [validator] = args;
    return function argument(...args) {
      return createGetter(...[...args, validator]);
    };
  }
  function forbidExtraArgs(target) {
    let returnClass = target;

    // only sublcass in debug mode
    if ((0, _runtime.macroCondition)(true)) {
      returnClass = class ForbidExtraArgsIntercept extends target {
        constructor(_owner, args) {
          super(...arguments);
          let component = getClassName(this);
          const registeredArgs = this[REGISTERED_ARGS];
          if (!registeredArgs) {
            return;
          }
          const unRegisteredArg = Object.keys(args).find(arg => !registeredArgs.has(arg));
          if (unRegisteredArg) {
            const guessedArg = (0, _closestString.closest)(unRegisteredArg, [...registeredArgs]);
            const suggestion = guessedArg ? `(did you mean \`@${guessedArg}\`?) ` : '';
            const expected = [...registeredArgs].map(arg => `'${arg}'`);
            const msg = `Failed extra args check: Invalid argument \`@${unRegisteredArg}\` ${suggestion}supplied to \`${component}\`, expected [${expected}]`;
            const shouldThrow = shouldThrowErrors();
            if (shouldThrow) {
              throw new Error(msg);
            } else {
              console.error(msg);
            }
          }
        }
      };
    }
    return returnClass;
  }
});