define("ember-models-table/components/models-table/row-grouping", ["exports", "@ember-decorators/component", "ember-models-table/templates/components/models-table/row-grouping"], function (_exports, _component, _rowGrouping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Component-row with grouped property value. Used to toggle rows group with needed property value equal to the grouping value
   *
   * You must not override this component and should use [groupingRowComponent](Components.ModelsTable.html#property_groupingRowComponent)
   *
   * By default [row-group-toggle](Components.ModelsTableRowGroupToggle.html) is used
   *
   * @namespace Components
   * @class ModelsTableRowGrouping
   * @extends Ember.Component
   */
  let RowGroupingComponent = _exports.default = (_dec = (0, _component.layout)(_rowGrouping.default), _dec2 = (0, _component.tagName)('tr'), _dec3 = Ember.computed.alias('themeInstance.groupingRow'), _dec4 = Ember.computed('displayGroupedValueAs', 'visibleProcessedColumns.length', 'additionalColspan'), _dec5 = Ember.computed.intersect('selectedItems', 'groupedItems'), _dec6 = Ember.computed.intersect('expandedItems', 'groupedItems'), _dec(_class = _dec2(_class = (_class2 = class RowGroupingComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      /**
       * @property tagName
       * @type string
       * @default 'tr'
       */
      /**
       * @property themeGroupingRowClass
       * @type string
       * @protected
       */
      _initializerDefineProperty(this, "themeGroupingRowClass", _descriptor, this);
      /**
       * @property groupedValue
       * @type *
       * @default null
       */
      _defineProperty(this, "groupedValue", null);
      /**
       * Determines if rows group is collapsed (bound from the parent component template)
       *
       * @property groupIsCollapsed
       * @type boolean
       * @default null
       */
      _defineProperty(this, "groupIsCollapsed", null);
      /**
       * Rows count in the rows group
       *
       * @property groupedLength
       * @type number
       * @default null
       */
      _defineProperty(this, "groupedLength", null);
      /**
       * Extra colspan used in the internal `td`. Useful in cases with block scope usage when some extra columns are in the table (not only `columns`)
       *
       * @property additionalColspan
       * @type number
       * @default 0
       */
      _defineProperty(this, "additionalColspan", 0);
      /**
       * @property groupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "groupedItems", null);
      /**
       * @property visibleGroupedItems
       * @type object[]
       * @default null
       */
      _defineProperty(this, "visibleGroupedItems", null);
      /**
       * @property selectedGroupedItems
       * @type object[]
       * @default []
       * @protected
       */
      _initializerDefineProperty(this, "selectedGroupedItems", _descriptor2, this);
      /**
       * @property expandedGroupedItems
       * @type object[]
       * @default []
       * @protected
       */
      _initializerDefineProperty(this, "expandedGroupedItems", _descriptor3, this);
      /**
       * Bound from [ModelsTable.currentGroupingPropertyName](Components.ModelsTable.html#property_currentGroupingPropertyName)
       *
       * @property currentGroupingPropertyName
       * @type string
       * @default null
       */
      _defineProperty(this, "currentGroupingPropertyName", null);
      /**
       * Bound from [ModelsTable.visibleProcessedColumns](Components.ModelsTable.html#property_visibleProcessedColumns)
       *
       * @property visibleProcessedColumns
       * @type Utils.ModelsTableColumn[]
       * @default null
       */
      _defineProperty(this, "visibleProcessedColumns", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRows](Components.ModelsTable.html#event_toggleGroupedRows)
       *
       * @event toggleGroupedRows
       */
      _defineProperty(this, "toggleGroupedRows", null);
      /**
       * Bound from [ModelsTable.displayGroupedValueAs](Components.ModelsTable.html#property_displayGroupedValueAs)
       *
       * @property displayGroupedValueAs
       * @type string
       * @default null
       */
      _defineProperty(this, "displayGroupedValueAs", null);
      /**
       * Bound from [ModelsTable.groupingRowComponent](Components.ModelsTable.html#property_groupingRowComponent)
       *
       * @property groupingRowComponent
       * @type string
       * @default null
       */
      _defineProperty(this, "groupingRowComponent", null);
      /**
       * Bound from [ModelsTable.themeInstance](Components.ModelsTable.html#property_themeInstance)
       *
       * @property themeInstance
       * @type object
       * @default null
       */
      _defineProperty(this, "themeInstance", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsSelection](Components.ModelsTable.html#event_toggleGroupedRowsSelection)
       *
       * @event toggleGroupedRowsSelection
       */
      _defineProperty(this, "toggleGroupedRowsSelection", null);
      /**
       * Closure action [ModelsTable.toggleGroupedRowsExpands](Components.ModelsTable.html#event_toggleGroupedRowsExpands)
       *
       * @event toggleGroupedRowsExpands
       */
      _defineProperty(this, "toggleGroupedRowsExpands", null);
    }
    /**
     * @property cellColspan
     * @type number
     * @default null
     * @protected
     */
    get cellColspan() {
      return this.visibleProcessedColumns.length + (this.displayGroupedValueAs === 'row' ? 0 : 1) + Number(this.additionalColspan);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "themeGroupingRowClass", [_component.className, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "cellColspan", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "cellColspan"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "selectedGroupedItems", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "expandedGroupedItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class) || _class);
});