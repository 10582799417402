define("ember-models-table/templates/components/models-table/row-group-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GLjyvHYz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"a\"],[14,6,\"#\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"doToggleGroupedRows\"]]],null]],[12],[1,[32,0,[\"groupedValue\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/row-group-toggle.hbs"
  });
});