'use strict';

function copy_properties(src, trg) {
   var src_val = null,
       trg_val = null;
   for(var prop_name in src) {
      src_val = src[prop_name];
      trg_val = trg[prop_name];
      if(trg_val != undefined && src_val != trg_val) {
         console.debug('overwriting property ' + prop_name + ' from existing value ' + trg_val + ' to new value ' + src_val); // eslint-disable-line no-console
      }
      trg[prop_name] = src_val;
   }
}

function get_temperature(tsdb_metric_name, options) {
   var in_fahrenheit = {
      tsdb_metric_name: tsdb_metric_name
      , aggregator_fn: 'avg'
      , unit: '°F'
      , round_to_decimal_digits: 2
      , axisPosition: 'right'
   };
   copy_properties(options, in_fahrenheit);

   var in_celsius = {};
   copy_properties(in_fahrenheit, in_celsius);

   in_celsius.unit = '°C';
   in_celsius.tsdb_metric_name = tsdb_metric_name.substring(0, tsdb_metric_name.length - 2) + '_c';

   in_fahrenheit.in_celsius = in_celsius;

   return in_fahrenheit;
}

var SgbChartColors = {
   red: [221, 80, 28], // [221, 80, 28], [255, 0, 0],
   green: [28, 221, 176], // [28, 221, 176], [28, 221, 80], [0, 255, 0],
   blue: [28, 168, 221], // [[28, 168, 221],], [0, 91, 150], [28, 73, 221], [0, 0, 255],
   orange: [255, 165, 0]
   , purple: [190, 120, 211]
   , yellow: [214, 178, 0]
   , brown: [103, 14, 14] //[183, 147, 40]
};

function get_custom_labels_function(value_to_labels_map) {
  function value2label(value, index, values) { // eslint-disable-line no-unused-vars
     return value_to_labels_map[value];
  }
  return value2label;
}

var device_types = {  // based on api/hashmap/device_type
    smartsocket: 100, // unused
    computime_zigbee_thermostat: 101,
    computime_smartsocket: 102,
    computime_optima_thermostat: 103,
    salus_smartsocket: 104, // unused
    salus_valve_controller: 105, // unused
    salus_water_sensor: 106,
    nyce_motion_sensor: 201,
    nyce_doorwindow_sensor: 202,
    nyce_garagedoor_sensor: 203, // unused
    nyce_door_hinge_sensor: 204, // unused
    cleode_zrc: 300,
    centralite_smartsocket_dimmer: 401, // unused
    centralite_smartsocket: 402, // unused
    centralite_inwall_relay: 403, // unused
    centralite_inwall_dimmer: 404, // unused
    centralite_zigbee_thermostat: 405, // unused
    centralite_water_sensor1: 406,
    smartenit_valve_zbvc1: 501, // unused
    smartenit_outlet_zbmskt1: 502,
    smartenit_remote_switch: 503, // unused
    jasco_smart_outlet: 600,
    jasco_dimmer_outlet: 601,
    jasco_smartsocket: 602, // unused 
    jasco_outlet: 603,
    jasco_outdoor_smartsocket: 604, // unused
    silverspring_meter: 700,
    sel_meter: 701,
    egauge_meter: 702,
    clippercreek_evse: 801,
    generic_onoff: 901,
    kaba_saflok_door_lock: 1001,
    halo_smartlabs_halowx: 1101, // unused
    fidure_zigbee_thermostat: 1200,
    fidure_zigbee_nameplate: 1201,
    sgb_battery_controller: 1300,
    sgb_battery_manager: 1301,
    ledvance_led_strip_dimmer: 1400,
    aduro_remote_switch: 1500
};

var DeviceTypesToMetrics = {

  device_groups: {
     thermostats: {
        device_type_ids: [
          device_types.centralite_zigbee_thermostat,
          device_types.computime_optima_thermostat,
          device_types.computime_zigbee_thermostat,
          device_types.fidure_zigbee_thermostat
        ],
        get_metrics: () => [ Metrics.watts, Metrics.cool_setpoint_f, Metrics.heat_setpoint_f, Metrics.temperature_f]
     },
     motion_sensors: {
        device_type_ids: [device_types.nyce_motion_sensor],
        get_metrics: () => {
          // Metrics.ias_zone_status - needs downsampling?
          // Metrics.battery_voltage - only interesting for longer periods
          let on_off = {};
          copy_properties(Metrics.ias_zone_status, on_off);
          on_off.min = 0;
          on_off.max = 7;
          on_off.callback = get_custom_labels_function({0: 'still', 1: 'moving'});
          on_off.display_scale_label = true;
          on_off.unit = 'movement';
          on_off.label = 'Movement Status';
          return [ on_off, Metrics.humidity, Metrics.temperature_f];
        }
     },
     water_sensors: {
        device_type_ids: [
          device_types.centralite_water_sensor1,
          device_types.salus_water_sensor
        ],
        get_metrics: () => {
          let on_off = {};
          copy_properties(Metrics.ias_zone_status, on_off);
          on_off.min = 0;
          on_off.max = 7;
          on_off.callback = get_custom_labels_function({0: 'dry', 1: 'wet'});
          on_off.display_scale_label = true;
          on_off.unit = 'water';
          on_off.label = 'Water Status';
          return [ on_off, Metrics.humidity, Metrics.temperature_f];
        }
     },
     door_window_sensors: {
       device_type_ids: [
         device_types.nyce_doorwindow_sensor,
         device_types.nyce_garagedoor_sensor,
         device_types.nyce_door_hinge_sensor,
         device_types.generic_onoff,
         device_types.kaba_saflok_door_lock
       ],
       get_metrics: () => {
         // Metrics.battery_voltage - only interesting for longer periods
         let on_off = {};
         copy_properties(Metrics.ias_zone_status, on_off);
         on_off.min = 0;
         on_off.max = 2;
         on_off.callback = get_custom_labels_function({0: 'closed', 1: 'open'});
         on_off.display_scale_label = true;
         on_off.unit = 'state';
         on_off.label = 'Door/Window Status';
         return [ on_off ];
       }
     },

     sockets_and_dimmers: {
       device_type_ids: [
         device_types.smartsocket,
         device_types.computime_smartsocket,
         device_types.centralite_smartsocket_dimmer,
         device_types.centralite_smartsocket,
         device_types.centralite_inwall_relay,
         device_types.centralite_inwall_dimmer,
         device_types.smartenit_outlet_zbmskt1,
         device_types.jasco_outlet,
         device_types.jasco_smart_outlet,
         device_types.jasco_dimmer_outlet,
         device_types.ledvance_led_strip_dimmer
       ],
       get_metrics: () => [Metrics.watts]
     },

     meters_and_evse: {
       device_type_ids: [
         device_types.silverspring_meter,
         device_types.sel_meter,
         device_types.clippercreek_evse
       ],
       get_metrics: () => [Metrics.watts]
     },
     
     egauge_meter: {
       device_type_ids: [
         device_types.egauge_meter
       ],
       get_metrics: () => [Metrics.watts_ds, Metrics.current_detail]
     },
     
     battery_controller: {
      device_type_ids: [
        device_types.sgb_battery_controller
      ],
      get_metrics: () => [Metrics.capacity, Metrics.soc_percentage, Metrics.stored_energy]
     },

     null_group: {
        device_type_ids: [],
        get_metrics: () => []
     }
  },

  get_device_group(device_type_id) {
     let parsed_device_type_id = parseInt(device_type_id);
     for(var device_group_name in this.device_groups) {
       let device_group = this.device_groups[device_group_name];
       if(device_group.device_type_ids.indexOf(parsed_device_type_id) != -1) {
         return device_group;
       }
     }
     console.warn({ msg: 'could not find device group for it', device_type_id: { raw: device_type_id, parsed: parsed_device_type_id } }); // eslint-disable-line no-console
     return this.device_groups.null_group;
  },

  metrics_for_device_type(device_type_id) {
     return this.get_device_group(device_type_id).get_metrics();
  },

  getMetricByTsdbMetricName(tsdb_metric_name, list_of_metrics) {
     let match = list_of_metrics.filter(metric => tsdb_metric_name == metric.tsdb_metric_name);
     if(match.length != 1) {
        console.warn({ msg: 'not a single match', needle: tsdb_metric_name, haystack: list_of_metrics, match: match }); // eslint-disable-line no-console
        // e.g.: this can happen when chartInfo/metrics computed properties don't change in the right orer
        return null;
     }
     return match[0];
  }
};


class Watt {
   constructor(tsdb_metric_name, options) {
      this.tsdb_metric_name = tsdb_metric_name;
      this.aggregator_fn = 'sum';
      this.discrete_values = true;
      this.beginAtZero = true;
      this.min = 0;
      this.axisPosition = 'left';
      this.unit = 'W';
      this.round_to_decimal_digits = 1;
      copy_properties(options, this);
   }
}


function get_watt(tsdb_metric_name, options) {
   return new Watt(tsdb_metric_name, options);
}

// module.exports = { Metrics: ...  }; doesn't work w/ ember - don't ask me why
var Metrics = {
      watts: get_watt('watts', {
        rgb: SgbChartColors.green
        , label: 'Watts'
      }),
      watts_max: get_watt('watts.max', {
        rgb: SgbChartColors.green
        , label: 'Watts Maximum'
        , discrete_values: false
      }),
      watts_min: get_watt('watts.min', {
        rgb: SgbChartColors.yellow
        , label: 'Watts Miniumum'
        , discrete_values: false
      }),
      ias_zone_status: {
        tsdb_metric_name: 'ias_zone_status',
        aggregator_fn: 'sum'
        , rgb: SgbChartColors.brown
        , discrete_values: true
        , beginAtZero: true
        , axisPosition: 'right'
      },
      humidity: {
        tsdb_metric_name: 'humidity',
        aggregator_fn: 'sum'
        , unit: '%'
        , round_to_decimal_digits: 2
        , rgb: SgbChartColors.purple
        , discrete_values: false
        , axisPosition: 'left'
        , label: 'Humidity'
      },
      battery_voltage: {
        tsdb_metric_name: 'battery_voltage',
        aggregator_fn: 'sum'
        , unit: 'V'
        , round_to_decimal_digits: 0
        , rgb: SgbChartColors.yellow
        , discrete_values: false
        , axisPosition: 'left'
        , label: 'Battery'
      },
      heat_setpoint_f_avg: get_temperature('thermostat.heat_setpoint_f', {
        downsample_fn: '5m-avg',
        rgb: SgbChartColors.red,
        discrete_values: true
        ,label: 'Thermostat Heat Set-point (avg)',
      }),
      heat_setpoint_f: get_temperature('thermostat.heat_setpoint_f', {
        rgb: SgbChartColors.red,
        discrete_values: true
        ,label: 'Thermostat Heat Set-point'
      }),
      cool_setpoint_f_avg: get_temperature('thermostat.cool_setpoint_f', {
        downsample_fn: '5m-avg',
        rgb: SgbChartColors.blue,
        discrete_values: true
        ,label: 'Thermostat Cool Set-point'
      }),
      cool_setpoint_f: get_temperature('thermostat.cool_setpoint_f', {
        rgb: SgbChartColors.blue,
        discrete_values: true
        ,label: 'Thermostat Cool Set-point'
      }),
      temperature_f_avg: get_temperature('temperature.avg_f', {
        rgb: SgbChartColors.orange
        ,label: 'Average Temperature'
      }),
      temperature_f: get_temperature('temperature_f', {
        rgb: SgbChartColors.orange
        ,label: 'Temperature'
      }),
      capacity: {
        tsdb_metric_name: 'capacity',
        aggregator_fn: 'sum',
        downsample_fn: '5m-avg'
        , unit: ''
        , round_to_decimal_digits: 0
        , rgb: SgbChartColors.green
        , discrete_values: true
        , axisPosition: 'left'
        , label: 'Capacity'
        , beginAtZero: true
      },
      stored_energy: {
        tsdb_metric_name: 'stored_energy',
        aggregator_fn: 'sum',
        downsample_fn: '5m-avg'
        , unit: ''
        , round_to_decimal_digits: 0
        , rgb: SgbChartColors.yellow
        , discrete_values: true
        , axisPosition: 'left'
        , label: 'Stored Energy'
        , beginAtZero: true        
      },
      soc_percentage: {
        tsdb_metric_name: 'soc_percentage',
        aggregator_fn: 'sum',
        downsample_fn: '5m-avg'
        , unit: '%'
        , round_to_decimal_digits: 0
        , rgb: SgbChartColors.purple
        , discrete_values: true
        , axisPosition: 'right'
        , label: 'SOC'
        , beginAtZero: true
        , max: 100
        , min: 0        
      },
      current_detail: {
        tsdb_metric_name: 'current.detail',
        aggregator_fn: 'sum',
        downsample_fn: '5m-avg'
        , unit: 'A'
        , round_to_decimal_digits: 0
        , rgb: SgbChartColors.purple
        , discrete_values: true
        , axisPosition: 'right'
        , label: 'Current'
      },
      watts_ds: get_watt('watts', {
        downsample_fn: '5m-avg',
        rgb: SgbChartColors.green
        , label: 'Watts'
      })           
   };


var SingleStatMetrics = {
  'rooms-with-active-ee': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'group_with_active_ee' }
     , label: 'Rooms with active EE'
     , rgb: SgbChartColors.blue
  },
  'rooms-occupied': {
     tsdb_metric_name: 'scene_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'occupied' }
     , label: 'Occupied rooms'
     , rgb: SgbChartColors.red
  },
  'rooms-tmp-vacant': { // also has 'checkout state'
     tsdb_metric_name: 'scene_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'tmp_vacant' }
     , label: 'Temp. vacant rooms'
     , rgb: SgbChartColors.blue
  },
  'rooms-vacant': {
     tsdb_metric_name: 'scene_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'vacant' }
     , label: 'Vacant rooms'
     , rgb: SgbChartColors.green
  },
  'rooms-with-active-dr': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'group_with_active_dr' }
     , label: 'Rooms with active DR'
     , rgb: SgbChartColors.blue
  },
  'devices-online': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'sum'
     , filterTag: {key: 'name', value: 'online' }
     , label: 'Online devices'
     , rgb: SgbChartColors.brown
  },
  'devices-silent': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'unexpected_silent' }
     , label: 'Silent devices'
     , rgb: SgbChartColors.orange
  },
  'devices-offline': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'sum'
     , filterTag: {key: 'name', value: 'unexpected_offline' }
     , label: 'Offline devices'
     , rgb: SgbChartColors.brown
  },
  'devices-with-active-dr': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'device_active_dr' }
     , label: 'Devices with active DR'
     , rgb: SgbChartColors.blue
  },
  'devices-with-active-ee': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'device_active_ee' }
     , label: 'Devices with active EE'
     , rgb: SgbChartColors.blue
  },
  'dirty-hvac-filter': {
     tsdb_metric_name: 'vgtag_count'
     , aggregator_fn: 'max'
     , filterTag: {key: 'name', value: 'hvac_needs_filter_change' }
     , label: 'Dirty filters'
     , rgb: SgbChartColors.red
  },
  getLastValueFromTsdbQueryResult(tsdb_query_result) {
     let dps = null;
     if ( tsdb_query_result && tsdb_query_result.length != 0 ) {
       let first_metric = tsdb_query_result[0];
       if ( first_metric && first_metric.dps && first_metric.dps.length != 0 ) {
         dps = first_metric.dps;
       }
     }
     if ( ! dps ) { return null; }
     let latest_datapoint = dps.reduce((acc, obj) => { return parseInt(acc[0]) > parseInt(obj[0]) ? acc : obj });
     return parseInt(latest_datapoint[1]);
  },
  getAllMetricAliases() {
    let aliases = [];
    for ( let name in this ) {
      let val = this[name];
      if ( ! val.apply ) {
        aliases.push(name);
      }
    }
    return aliases;
  }
};


class HashAndDirectParams {

   constructor(options) {
     this.options = options;
     this.getter = options.get;
     this.setter = options.set;
     this.obj = options.obj;
     this.to_restore = null;
     this.hadFirstFromHashCallAlready = false;
   }

   set(name, value, options) {
     let should_set =
       options == null || options.predicate == null || options.predicate(this.obj, name, value);
     if ( should_set ) {
       this.setter(this.obj, name, value);
     }
   }

   get(name) {
     return this.getter(this.obj, name);
   }

   fromHash(hash, options) {
     let undo = options && options.undo;
     let pre_set_values = null;
     if ( undo ) {
       this.undoLastFromHash();
       pre_set_values = this.getHashToRestore(hash);
     }
     for(let name in hash) {
       this.set(name, hash[name], options);
     }
     if ( undo ) {
       this.values_to_restore = pre_set_values;
     }
     this.hadFirstFromHashCallAlready = true;
   }

   getHashToRestore(hash) {
     let pre_set_values = {};
     for(let name in hash) {
       pre_set_values[name] = this.get(name);
     }
     return pre_set_values;
   }

   undoLastFromHash() {
     if ( this.values_to_restore ) {
       for(let name in this.values_to_restore) {
         let val = this.values_to_restore[name];
         this.set(name, val);
       }
       delete this.values_to_restore;
     } else if ( this.hadFirstFromHashCallAlready ) {
       console.warn('undoLastFromHash called when there was nothing to restore'); // eslint-disable-line no-console
     }
   }
}

try {
  exports.Metrics = Metrics;
  exports.DeviceTypesToMetrics = DeviceTypesToMetrics;
  exports.SingleStatMetrics = SingleStatMetrics;
  exports.HashAndDirectParams = HashAndDirectParams;
} catch(e) {
  if ( ! (e instanceof ReferenceError) ) {
    throw e;
  }
}
