define("sgb-object-graphs/initializers/chartjs-text-overlay-plugin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  /* global Chart */
  function
    /* application */
  initialize() {
    // return;
    Chart.defaults.plugins.textOverlay = {
      fullWidth: true,
      position: 'chartArea',
      weightRelaiveToTitle: 0.5 // legend is 1000, title  is 2000

    };

    class TextOverlayElement extends Chart.Element {
      constructor(args) {
        super();
        this.ctx = args.ctx;
        this.options = args.options;
        this.chart = args.chart;
      }

      isHorizontal() {
        var pos = this.options.position;
        return pos === 'top' || pos === 'bottom';
      }

      draw() {
        let me = this;

        if (!me.options || !me.options.display) {
          return;
        }

        let context = me.ctx;
        context.save();

        try {
          let pattern = /(bold )?\d+px/;
          let newSize = `bold ${me.fontSize}px`;
          context.font = context.font.replace(pattern, newSize);
          context.textAlign = 'center';
          let color = me.options.color;

          if (!color) {
            color = me.chart.options?.title?.color;
          }

          if (color) {
            context.fillStyle = color;
          }

          context.textBaseline = 'alphabetic';
          context.fillText(me.options.text, me.x, me.y);
        } finally {
          context.restore();
        }
      }

      update(maxWidth, maxHeight, margins) {
        let me = this;
        me.maxWidth = maxWidth;
        me.maxHeight = maxHeight;
        me.margins = margins;
        me.setDimensions();
        return me.minSize;
      }

      setDimensions() {
        this.x = this.maxWidth / 2;
        this.y = this.maxHeight * 3 / 4;
        this.fontSize = this.maxHeight * 2 / 3;
        let me = this; // Reset padding

        me.paddingLeft = 0;
        me.paddingTop = 0;
        me.paddingRight = 0;
        me.paddingBottom = 0; // Reset minSize

        me.minSize = {
          width: me.maxWidth,
          height: me.maxHeight
        };
      }

    }

    window.__CLASSIC_HAS_CONSTRUCTOR__.set(TextOverlayElement, true);

    window.__CLASSIC_OWN_CLASSES__.set(TextOverlayElement, false);

    ;
    Chart.TextOverlay = TextOverlayElement;

    function createNewAndAttach(chart, opts) {
      const overlay = new Chart.TextOverlay({
        ctx: chart.ctx,
        options: opts,
        chart: chart
      });
      Chart.layouts.configure(chart, overlay, opts);
      Chart.layouts.addBox(chart, overlay);
      chart.textOverlayBlock = overlay;
    }

    Chart.register({
      id: 'textOverlay',
      beforeInit: function (chart) {
        let opts = chart.options.textOverlay || {};

        if (opts) {
          createNewAndAttach(chart, opts);
        }
      },
      beforeUpdate: function (chart) {
        let opts = chart.options.textOverlay;
        let block = chart.textOverlayBlock;

        if (opts) {
          Chart.helpers.mergeIf(opts, Chart.defaults.plugins.textOverlay || {});

          if (block) {
            Chart.layouts.configure(chart, block, opts);
            block.options = opts;
          } else {
            createNewAndAttach(chart, opts);
          }
        } else if (block) {
          Chart.layouts.removeBox(chart, block);
          delete chart.textOverlayBlock;
        }
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});