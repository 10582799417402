define("ember-models-table/templates/components/models-table/themes/ember-paper/row-select-all-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IwBkFk1p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"paper-checkbox\",[],[[\"@indeterminate\",\"@value\",\"@onChange\"],[[30,[36,1],[[32,0,[\"selectedItems\",\"length\"]],[30,[36,0],[[32,0,[\"selectedItems\",\"length\"]],[32,0,[\"data\",\"length\"]]],null]],null],[30,[36,2],[[32,0,[\"selectedItems\",\"length\"]],[32,0,[\"data\",\"length\"]]],null],[32,0,[\"doToggleAllSelection\"]]]],null],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"not-eq\",\"and\",\"is-equal\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/row-select-all-checkbox.hbs"
  });
});