define("ember-models-table/templates/components/models-table/cell-content-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "h2KTb+by",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[[32,0,[\"record\"]],[32,0,[\"column\",\"propertyName\"]]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/cell-content-display.hbs"
  });
});