define("ember-changeset-validations/utils/messages", ["exports", "ember-validators/messages"], function (_exports, _messages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Messages = Ember.assign({}, _messages.default);
  var _default = _exports.default = Ember.assign(Messages, {
    // Blank and present are flipped in ember-validators. Need to flip them back here
    blank: _messages.default.present,
    present: _messages.default.blank,
    getDescriptionFor(key = '') {
      return Ember.String.capitalize(Ember.String.dasherize(key).split(/[_-]/g).join(' '));
    }
  });
});