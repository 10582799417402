define("ember-models-table/templates/components/models-table/themes/ember-paper/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "16S6/P1x",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[8,\"paper-select\",[],[[\"@label\",\"@options\",\"@selected\",\"@disabled\",\"@placeholder\",\"@onChange\"],[[32,0,[\"label\"]],[32,0,[\"options\"]],[32,0,[\"value\"]],[32,0,[\"disabled\"]],[32,0,[\"placeholder\"]],[32,0,[\"updateValue\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,\"aria-valuetext\",[32,1,[\"value\"]]],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"option-is-object\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/themes/ember-paper/select.hbs"
  });
});