define("ember-models-table/templates/components/models-table/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aW4lRAyd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"td\"],[15,\"colspan\",[32,0,[\"realColumnsCount\"]]],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"themeInstance\",\"noDataToShowMsg\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/no-data.hbs"
  });
});