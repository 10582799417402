define("ember-changeset/utils/is-belongs-to-relationship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isBelongsToRelationship = isBelongsToRelationship;
  // TODO: Get rid of this heuristic check by passing an option to the Changeset constructor
  function isBelongsToRelationship(obj) {
    if (!obj) {
      return false;
    }
    if (obj.hasOwnProperty('content') && obj.hasOwnProperty('isFulfilled') && obj.hasOwnProperty('isRejected')) {
      // Async belongsTo()
      return true;
    }
    if ('isLoading' in obj && 'isLoaded' in obj && 'isNew' in obj && 'hasDirtyAttributes' in obj) {
      // Sync belongsTo()
      return true;
    }
    return false;
  }
});