define("ember-models-table/components/models-table/themes/ember-bootstrap-v4/global-filter", ["exports", "@ember-decorators/component", "ember-models-table/components/models-table/global-filter", "ember-models-table/templates/components/models-table/themes/ember-bootstrap-v4/global-filter"], function (_exports, _component, _globalFilter, _globalFilter2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  /**
   * @class Ebs4ModelsTableGlobalFilter
   * @namespace Components
   * @extends Components.ModelsTableGlobalFilter
   */
  let Ebs4ModelsTableGlobalFilterComponent = _exports.default = (_dec = (0, _component.layout)(_globalFilter2.default), _dec(_class = class Ebs4ModelsTableGlobalFilterComponent extends _globalFilter.default {}) || _class);
});