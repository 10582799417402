define("ember-models-table/templates/components/models-table/expand-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DkW5jAjQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"isExpanded\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[15,0,[30,[36,0],[[32,0,[\"themeInstance\",\"buttonLink\"]],\" \",[32,0,[\"themeInstance\",\"collapseRow\"]]],null]],[15,\"onclick\",[30,[36,1],[[32,0,[\"doCollapseRow\"]],[32,0,[\"index\"]],[32,0,[\"record\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"collapseRowIcon\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"button\"],[15,0,[30,[36,0],[[32,0,[\"themeInstance\",\"buttonLink\"]],\" \",[32,0,[\"themeInstance\",\"expandRow\"]]],null]],[15,\"onclick\",[30,[36,1],[[32,0,[\"doExpandRow\"]],[32,0,[\"index\"]],[32,0,[\"record\"]]],null]],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[32,0,[\"themeInstance\",\"expandRowIcon\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"concat\",\"fn\",\"if\"]}",
    "moduleName": "ember-models-table/templates/components/models-table/expand-toggle.hbs"
  });
});