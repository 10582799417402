define("ember-models-table/helpers/exists-in", ["exports", "@ember-decorators/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let ExistsInEmberObject = _exports.default = (_dec = Ember.computed('needle', 'haystack.[]'), _dec2 = (0, _object.observes)('content'), (_class = class ExistsInEmberObject extends Ember.Helper {
    get content() {
      const {
        needle,
        haystack
      } = this;
      return Ember.isArray(haystack) ? haystack.includes(needle) : false;
    }
    compute([haystack, needle]) {
      Ember.set(this, 'needle', needle);
      Ember.set(this, 'haystack', haystack);
      return this.content;
    }
    contentDidChange() {
      this.recompute();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "content", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "content"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "contentDidChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "contentDidChange"), _class.prototype)), _class));
});