define("sgb-object-graphs/components/sgb-singlestat-line-chart", ["exports", "sgb-object-graphs/components/sgb-device-line-chart"], function (_exports, _sgbDeviceLineChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let SgbSinglestatLineChart = classic(_class = class SgbSinglestatLineChart extends _sgbDeviceLineChart.default {
    getOptions(data) {
      let options = super.getOptions(data);
      const overlayText = this.overlayText;

      if (overlayText == null) {
        return options;
      }

      options.textOverlay = {
        display: true,
        text: overlayText.toString()
      };
      options = _.merge(options, {
        scales: {
          y: {
            grid: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      });
      return options;
    }

  }) || _class;

  _exports.default = SgbSinglestatLineChart;
});