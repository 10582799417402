define("ember-window-resize/initializers/window-resize", ["exports", "ember-window-resize/services/window-resize"], function (_exports, _windowResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('service:windowResize', _windowResize.default);
    application.inject('route', 'windowResize', 'service:windowResize');
    application.inject('controller', 'windowResize', 'service:windowResize');
    application.inject('component', 'windowResize', 'service:windowResize');
  }
  var _default = _exports.default = {
    initialize
  };
});